import RBush from 'rbush';
import { Geom } from '../../../../interfaces';
// @ts-ignore
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';
// @ts-ignore
import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader';
// @ts-ignore
import WKTWriter from 'jsts/org/locationtech/jts/io/WKTWriter';
// @ts-ignore
import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate';
// @ts-ignore
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp';
// @ts-ignore
import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';
// @ts-ignore
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';


const geoJSONReader = new GeoJSONReader();
const wktReader = new WKTReader();
const wktWriter = new WKTWriter();
const geometryFactory = new GeometryFactory();

export function findPointGeoms(rtree: RBush<{ geom: Geom }>, realPoint: number[]) {
  return rtree
    ?.search({
      minX: realPoint[0], minY: realPoint[1], maxX: realPoint[0], maxY: realPoint[1],
    })
    .map(node => node.geom)
    // 按照面积大小顺序排序
    .sort((a, b) => {
      return geoJSONReader.read(a.geom).getArea() - geoJSONReader.read(b.geom).getArea();
    })
}

export function findPointElements(rtree: RBush<{ geom: Geom }>, realPoint: number[]) {
  return findPointGeoms(rtree, realPoint)
    .flatMap(geom => {
      try {
        const point = geometryFactory.createPoint(new Coordinate(realPoint[0], realPoint[1]));
        if(RelateOp.contains(geoJSONReader.read(geom.geom), point)) {
          return [geom.elementId];
        }
      } catch (e) {
        console.error(e);
        console.log('几何数据有问题', geom, geoJSONReader.read(geom.geom));
      }
      return [];
    });
}

export function fixGeometry(wkt: string) {
  const geom = wktReader.read(wkt);
  const newGeom = BufferOp.bufferOp(geom, 0);
  return wktWriter.write(newGeom);
}