import React, { useEffect } from 'react';

import styles from './LogViewer.module.less';
import Editor from '@monaco-editor/react';
import { useAppSelector } from '../../app/hooks';
import { selectLog } from '../tuzhi-vis/tuzhiVisSlice';

interface LogViewerProps {
  projectId: number;
}

function LogViewer(props: LogViewerProps) {
  const {projectId} = props;

  const log = useAppSelector(selectLog);

  // useEffect(() => {
  //
  // }, [log.content]);

  return (
    <div className={styles.root}>
      <Editor
        height="100%"
        theme="vs-light"
        language="log"
        options={{
          readOnly: true,
          alwaysConsumeMouseWheel: false,
        }}
        value={log.content}
        // onChange={(value, event) => {
        //
        // }}
      />
    </div>
  )
}

export default LogViewer;
