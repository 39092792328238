import React, { useEffect, useMemo, useRef, useState } from 'react';

import styles from './ModifierLayer.module.less';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectMode, selectModifyingGeomId, setMode, setModifyingGeomId } from './modifierSlice';
import { fetchElementsBySpaceId, fetchGeomsBySpaceId, selectGeomsBySpaceId } from '../../tuzhiVisSlice';
import { cloneDeep } from 'lodash';
import { screenToReal } from '../utils/transformUtils';
import { Button, Form, Input, message, Modal, Tag } from 'antd';
import { useKey } from 'react-use';
import { fixGeometry } from '../utils/geometryUtils';
import ModifierService from './Modifier.service';

interface ModifierLayerProps {
  spaceId: number;
  className?: string;
  width: number;
  height: number;
  matrix: number[]; // 缩放平移的矩阵
  realPoint: number[];
  isMouseDown: boolean;
  onRendered?: () => void;
}

function ModifierLayer(props: ModifierLayerProps) {
  const {spaceId,className, width, height, matrix, realPoint, isMouseDown} = props;

  const dispatch = useAppDispatch();

  const mode = useAppSelector(selectMode);
  const modifyingGeomId = useAppSelector(selectModifyingGeomId);
  const geoms = useAppSelector(state => selectGeomsBySpaceId(state, spaceId));

  const pathRef = useRef<SVGPathElement | null>(null);
  // const controlWrapperRef = useRef<SVGGElement | null>(null);
  const controlWrapperRef = useRef<SVGSVGElement | null>(null);

  // 存储面板的模态框
  const [modalShow, setModalShow] = useState(false);

  // 激活状态的控制点索引
  const [activeControlIndex, setActiveControlIndex] = useState<[number, number, number] | null>(null);
  // 跟踪鼠标状态的控制点索引
  const [tracingControlIndex, setTracingControlIndex] = useState<[number, number, number] | null>(null);

  // 新增包围盒时的基本信息表单
  const [addGeomForm] = Form.useForm();

  const multiPolygonData = useMemo(() => {
    if (modifyingGeomId === null) {
      if (mode === 'new') {
        // 新建空间的时候，需要初始化一个空的包围盒
        console.log('空的');
        return [];
      } else {
        return null;
      }
    }
    const g = geoms.find(g => g.geomId === modifyingGeomId);
    if (!g)
      return null;
    let multiPolygonData: number[][][][];
    switch (g.geom.type) {
      case 'Polygon':
        multiPolygonData = [g.geom.coordinates];
        break;
      case 'MultiPolygon':
        multiPolygonData = g.geom.coordinates;
        break;
      default:
        console.log('暂不支持的包围盒类型');
        return null;
    }
    return multiPolygonData.map(polygonData => {
      return polygonData.map(ringData => {
        return ringData.slice(0, -1);
      })
    });
  }, [mode, modifyingGeomId]);

  // 用于存储当前点位操作状态的state
  const [modStateData, setModStateData] = useState(multiPolygonData);

  useEffect(() => {
    setModStateData(multiPolygonData);
  }, [multiPolygonData]);

  const pathString = useMemo(() => {
    if (!modStateData)
      return null;
    return modStateData.reduce((p: string, polygonData: number[][][]) => {
      return p + polygonData.reduce((p, ringData) => {
        return p + ringData.reduce((p, point, index) => {
          return p + (index === 0 ? 'M' : 'L') + point[0] + ',' + point[1] + ' ';
        }, '') + 'Z ';
      }, '');
    }, '');
  }, [modStateData]);

  // 生成最终wkt，只在点击确认时生成即可
  const [wkt, setWkt] = useState<string | null>(null);

  // 更新wkt数据，变为整数并修复几何
  const updateWkt = () => {
    if (modStateData) {
      let wkt = 'MULTIPOLYGON(';
      wkt += modStateData.map(polygonData => {
        return '(' + polygonData.map(ringData => {
          return '(' + [...ringData, ringData[0]].map(point => {
            return point[0].toFixed() + ' ' + point[1].toFixed();
          }).join(',') + ')';
        }).join(',') + ')';
      }).join(',') + ')';
      // JSTS进行buffer修复图形
      const fixedWkt = fixGeometry(wkt);
      setWkt(fixedWkt);
    }
  };

  // 键盘操作
  useKey('d', () => {
    if (modalShow)
      return;
    if (activeControlIndex) {
      setModStateData(oldModStateData => {
        if (!oldModStateData)
          return null;
        const newModStateData = cloneDeep(oldModStateData);
        newModStateData[activeControlIndex[0]][activeControlIndex[1]] = [
          ...newModStateData[activeControlIndex[0]][activeControlIndex[1]].slice(0, activeControlIndex[2]),
          ...newModStateData[activeControlIndex[0]][activeControlIndex[1]].slice(activeControlIndex[2] + 1),
        ];
        // 如果该环已经是空的了，则直接移除
        if (newModStateData[activeControlIndex[0]][activeControlIndex[1]].length === 0) {
          newModStateData[activeControlIndex[0]].splice(activeControlIndex[1], 1);
          if (newModStateData[activeControlIndex[0]].length === 0) {
            newModStateData.splice(activeControlIndex[0], 1);
          }
        }
        message.info('已删除点', 0.7);
        return newModStateData;
      });
      // 自动选择上一个点
      setActiveControlIndex(activeControlIndex => {
        if (!activeControlIndex)
          return null;
        if (activeControlIndex[2] === 0)
          return null;
        return [activeControlIndex[0], activeControlIndex[1], activeControlIndex[2] - 1];
      });
    }
  }, {}, [activeControlIndex, modalShow]);

  useKey('m', () => {
    if (modalShow)
      return;
    // 新增移动点，相当于开始一个新的环，目前直接加在最高层即可，内部环通过修正处理包围盒获得
    if (modStateData) {
      setModStateData(oldModStateData => {
        if (!oldModStateData)
          oldModStateData = [];
        const newModStateData = cloneDeep(oldModStateData);
        newModStateData.push([[[0, 0]]]);
        message.info('新增移动点', 0.7);
        return newModStateData;
      });
      // 修改激活的点和跟踪的点
      setActiveControlIndex([modStateData.length, 0, 0]);
      setTracingControlIndex([modStateData.length, 0, 0]);
    }
  }, {}, [modStateData, modalShow]);

  useKey('l', () => {
    if (modalShow)
      return;
    // 本功能相当于在当前激活点和下一个点之间增加新点，然后将激活点切换到新点即可
    if (activeControlIndex) {
      message.info('连接指定点', 0.7);
      // 新增一个点
      setModStateData(oldModStateData => {
        if (!oldModStateData)
          return null;
        const newModStateData = cloneDeep(oldModStateData);
        newModStateData[activeControlIndex[0]][activeControlIndex[1]] = [
          ...newModStateData[activeControlIndex[0]][activeControlIndex[1]].slice(0, activeControlIndex[2] + 1),
          newModStateData[activeControlIndex[0]][activeControlIndex[1]][activeControlIndex[2]],  // 新增的点默认和上一个点重合，避免可视化突变
          ...newModStateData[activeControlIndex[0]][activeControlIndex[1]].slice(activeControlIndex[2] + 1),
        ];
        return newModStateData;
      });
      // 修改激活的点和跟踪的点
      setActiveControlIndex(activeControlIndex => {
        if (!activeControlIndex)
          return null;
        return [activeControlIndex[0], activeControlIndex[1], activeControlIndex[2] + 1];
      });
      setTracingControlIndex([activeControlIndex[0], activeControlIndex[1], activeControlIndex[2] + 1]);
    }
  }, {}, [activeControlIndex, modalShow]);

  useEffect(() => {
    setModStateData(multiPolygonData);
  }, [modifyingGeomId]);

  useEffect(() => {
    if (controlWrapperRef.current) {
      const controlMouseDownHandler = (e: MouseEvent) => {
        if (e.target instanceof SVGPathElement) {
          const controlIndex = e.target.attributes.getNamedItem('data-circle-index')?.value;
          const tracingControlIndex = controlIndex ? controlIndex.split('-').map(i => parseInt(i)) as [number, number, number] : null;
          setActiveControlIndex(tracingControlIndex);
          setTracingControlIndex(tracingControlIndex);
          e.stopPropagation();
        }
      };
      const controlMouseMoveHandler = (e: MouseEvent) => {
        if (e.target instanceof Element && e.currentTarget instanceof Element && tracingControlIndex && modStateData) {
          e.stopPropagation();
          // 通过当前鼠标坐标计算真实坐标
          const bound = e.currentTarget.getBoundingClientRect();
          const realPoint = screenToReal([e.clientX - bound.x, e.clientY - bound.y], matrix);
          const [polygonIndex, ringIndex, pointIndex] = tracingControlIndex;
          const newModStateData = cloneDeep(modStateData);
          newModStateData[polygonIndex][ringIndex][pointIndex] = [...realPoint];
          setModStateData(newModStateData);
        }
      };
      const controlMouseUpHandler = (e: MouseEvent) => {
        if (e.target instanceof Element && tracingControlIndex) {
          // 即使鼠标松开，也不取消激活状态，方便使用快捷键删除点
          // setActiveControlIndex(null);
          setTracingControlIndex(null);
          e.stopPropagation();
        }
      };

      controlWrapperRef.current.addEventListener('mousedown', controlMouseDownHandler);
      controlWrapperRef.current.addEventListener('mousemove', controlMouseMoveHandler);
      controlWrapperRef.current.addEventListener('mouseup', controlMouseUpHandler);
      return () => {
        controlWrapperRef.current?.removeEventListener('mousedown', controlMouseDownHandler);
        controlWrapperRef.current?.removeEventListener('mousemove', controlMouseMoveHandler);
        controlWrapperRef.current?.removeEventListener('mouseup', controlMouseUpHandler);
      }
    }
  }, [modifyingGeomId, controlWrapperRef, activeControlIndex, tracingControlIndex, modStateData]);

  return (
    <div className={className}>
      <svg
        width={width} height={height}
        ref={controlWrapperRef}
        style={{
          pointerEvents: mode === 'none' ? 'none' : 'auto',
          backgroundColor: mode === 'none' ? 'transparent' : 'rgba(255,255,255,0.3)',
        }}
      >
        {
          modStateData && pathString &&
          <g
            transform={`matrix(${matrix.join(' ')})`}
            ref={pathRef}
            style={{
              pointerEvents: 'auto',
              fill: 'rgba(0,0,0,0.3)',
            }}
          >
            <path
              style={{
                pointerEvents: 'none',
                fill: 'rgba(0,0,0,0.3)',
                fillRule: 'evenodd',
              }}
              // d="M50,0 L100,50 L50,100 L0,50 Z M25,25 L75,25 L75,75 L25,75 Z"
              d={pathString}
            />
            <g
              // ref={controlWrapperRef}
              width={width} height={height}
              style={{
                // pointerEvents: 'all',
              }}
            >
              {
                modStateData.map((polygonData, polygonIndex) => {
                  return polygonData.map((ringData, ringIndex) => {
                    return ringData.map((point, pointIndex) => {
                      return (
                        <path
                          key={polygonIndex + '-' + ringIndex + '-' + pointIndex}
                          data-circle-index={polygonIndex + '-' + ringIndex + '-' + pointIndex}
                          d={`M ${point[0]} ${point[1]} l 0 0`}
                          style={{
                            vectorEffect: 'non-scaling-stroke',
                            pointerEvents: 'auto',
                            stroke: activeControlIndex && activeControlIndex[0] === polygonIndex && activeControlIndex[1] === ringIndex && activeControlIndex[2] === pointIndex ? 'red' : 'lightgreen',
                            strokeWidth: 6,
                            strokeLinecap: 'square',
                          }}
                        />
                      );
                    });
                  });
                })
              }
            </g>
          </g>
        }
      </svg>
      {
        mode !== 'none' &&
        <div
          className={styles.toolbar}
        >
          <Tag>M</Tag>新增点 <Tag>L</Tag>连接点 <Tag>D</Tag> 删除点{' '}
          <Button.Group>
            <Button
              size="small"
              onClick={() => {
                console.log(modStateData);
                updateWkt();
                setModalShow(true);
              }}
            >
              完成编辑
            </Button>
            <Button
              size="small"
              onClick={() => {
                dispatch(setMode('none'));
                dispatch(setModifyingGeomId(null));
              }}
            >
              放弃
            </Button>
          </Button.Group>
          <Modal
            open={modalShow}
            okText="确认保存"
            cancelText="取消"
            onCancel={() => {
              setModalShow(false);
              dispatch(setMode('none'));
              dispatch(setModifyingGeomId(null));
            }}
            onOk={() => {
              if (mode === 'new') {
                const fields = addGeomForm.getFieldsValue();
                if (wkt) {
                  ModifierService.addGeometry(spaceId, wkt, fields['categoryName'], fields['familyName'], fields['elementName']).then(() => {
                    message.success('新增成功');
                    // 真正结束编辑状态
                    setModalShow(false);
                    dispatch(setMode('none'));
                    // 新增成功还要刷新element和geom数据
                    dispatch(fetchElementsBySpaceId(spaceId));
                    dispatch(fetchGeomsBySpaceId(spaceId));
                  }).catch(() => {
                    message.error('新增失败');
                  });
                } else {
                  message.error('暂无新增目标');
                }
              } else if (mode === 'modify') {
                if (modifyingGeomId && wkt) {
                  ModifierService.modifyGeometry(modifyingGeomId, wkt).then(() => {
                    message.success('修改成功');
                    // 真正结束编辑状态
                    setModalShow(false);
                    dispatch(setModifyingGeomId(null));
                    dispatch(setMode('none'));
                    // 修改成功还要刷新geom数据
                    dispatch(fetchGeomsBySpaceId(spaceId));
                  }).catch(() => {
                    message.error('修改失败');
                  });
                } else {
                  message.error('暂无修改目标');
                }
              }
            }}
          >
            {
              mode === 'new' &&
              <>
                <p>新增包围盒</p>
                <p>修正后几何:</p>
                <pre style={{whiteSpace: 'pre-wrap'}}>
                  {wkt}
                </pre>
                <Form form={addGeomForm} name="control-hooks">
                  <Form.Item name="elementName" label="构件名称(EN)" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="categoryName" label="构件分类(CN)" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="familyName" label="构件族类(FN)" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Form>
              </>
            }
            {
              mode === 'modify' &&
              <>
                <p>修改包围盒</p>
                <p>当前修改几何Id: {modifyingGeomId}</p>
                <p>修正后几何:</p>
                <pre style={{whiteSpace: 'pre-wrap'}}>
              {wkt}
            </pre>
              </>
            }
          </Modal>
        </div>
      }
    </div>
  );
}

export default ModifierLayer;
