import React from 'react';

import styles from './UserLogin.module.less';
import { Button, Card, Form, Input } from 'antd';
import AuthService from './Auth.service';
import { useAppDispatch } from '../../app/hooks';
import { setUser } from './authSlice';
import { useNavigate } from 'react-router-dom';

function UserLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onFinish = (values: any) => {
    console.log('Success:', values);
    // todo 开始登录
    AuthService
      .login(values.username, values.password)
      .then(res => {
        console.log('res', res);
        dispatch(setUser(res.data));
        // 跳转项目页面
        navigate('/projects');
      })
      .catch(res => {
        console.log('err', res);
        alert(res.response.data.message);
      })
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.root}>
      <Card title="图智 - 登录" className={styles.card}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default UserLogin;
