import React from 'react';
import { FieldDataNode } from 'rc-tree/lib/interface';
import { ItemType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import { FolderOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { SNLCategory, SNLLibrary, SNLRule } from '../models/snl/SNLLibrary';

export type RuleTreeNode = {
  value: string;
  title: string;
  ruleId: string | null;
  ruleNumber: string | null;
  children: RuleTreeNode[];
};

export type RuleTreeDataNode = FieldDataNode<{
  value: string;
  title: string;
  ruleNumber: string;
}>;

/**
 * 从SNL原始文件提取目录树结构
 * @param snlText SNL原始文件内容
 */
export function extractRuleTree(snlText: string): RuleTreeNode {
  const parser = new DOMParser();
  const snlDoc = parser.parseFromString(snlText, 'text/xml');
  // 先构造目录节点
  const superRootNode: RuleTreeNode = {
    value: 'root',
    title: 'root',
    ruleId: 'root',
    ruleNumber: 'root',
    children: [],
  };
  Array.from(snlDoc.querySelectorAll('class')).forEach(c => {
    const catIdList = c.getAttribute('id');
    const nameDOM = c.querySelector('class_name');
    if (catIdList && nameDOM) {
      catIdList.split(',').reduce((node, id) => {
        const childKey = `${node.value}-${id}`;
        if (!node.children)
          node.children = [];
        let child = node.children.find(n => n.value === childKey);
        if (!child) {
          child = {
            value: childKey,
            title: nameDOM.textContent || '',
            ruleId: nameDOM.textContent || '',
            ruleNumber: null,
            children: [],
          };
          node.children.push(child);
        }
        return child;
      }, superRootNode);
    }
  });
  // 再构造条款节点（叶子）
  Array.from(snlDoc.querySelectorAll('rule')).forEach(r => {
    const catIdListDOM = r.querySelector('whichclass');
    const ruleNumberDOM = r.querySelector('rule_number');
    if (
      catIdListDOM && catIdListDOM.textContent &&
      ruleNumberDOM && ruleNumberDOM.textContent
    ) {
      const catIdList = catIdListDOM.textContent;
      const ruleNumber = ruleNumberDOM.textContent;
      const parent = catIdList.split(',').reduce((node, id) => {
        const childKey = `${node.value}-${id}`;
        if (!node.children)
          throw 'SNL文件损坏,条款无对应分类';
        const child = node.children.find(n => n.value === childKey);
        if (!child)
          throw 'SNL文件损坏,条款无对应分类';
        return child;
      }, superRootNode);
      if (parent.children) {
        parent.children.push({
          // value: `${parent.value}-${ruleNumber}`,
          value: ruleNumber,
          title: ruleNumber,
          ruleId: r.getAttribute('id'),
          ruleNumber: ruleNumber,
          children: [],
        });
      }
    }
  });
  // 最后进行自然排序（按点号分隔的每一个节点按数值排序）
  (function sort(node) {
    // 先排序当前节点下所有子节点
    if (node.children) {
      node.children = node.children.sort((a, b) => {
        const aLevels = a.title.split('.').map(n => parseInt(n));
        const bLevels = b.title.split('.').map(n => parseInt(n));

        const maxLevel = Math.max(aLevels.length, bLevels.length);
        for (let i = 0; i < maxLevel; i++) {
          const aLevel = i < aLevels.length ? aLevels[i] : 0;
          const bLevel = i < bLevels.length ? bLevels[i] : 0;
          if (aLevel !== bLevel) {
            return aLevel - bLevel;
          }
        }
        return 0;
      });
    }
    // 再递归处理所有子节点
    for (const child of node.children) {
      sort(child);
    }
  })(superRootNode);

  return superRootNode;
}

function _extractAntdTree(node: RuleTreeNode): RuleTreeDataNode {
  return {
    value: node.value,
    title: node.title,
    ruleNumber: node.ruleId || '', // todo
    children: node.children.map(_extractAntdTree),
  };
}

export function extractAntdTree(snlText: string): RuleTreeDataNode[] {
  const tree = extractRuleTree(snlText);
  return _extractAntdTree(tree).children || [];
}

function _extractAntdMenu(node: SNLCategory | SNLRule): ItemType {
  if (node instanceof SNLRule) {
    return {
      label: `${node.number} (${node.tags.map(t => t.name).join(',')})`,
      key: node.number,
      icon: <FolderOutlined />,
      children: [],
    };
  } else {
    return {
      label: <span id={`toc-${node.name}`} style={{scrollMarginTop: 50}}>{node.name} {node.number}</span>,
      key: node.id,
      icon: <UnorderedListOutlined />,
      children: (node.children.length !== 0 ? node.children : node.rules).map(_extractAntdMenu),
    };
  }
}

export function extractAntdMenu(snlLibrary: SNLLibrary): ItemType[] {
  const menu = _extractAntdMenu(snlLibrary.rootCategory);
  if (menu) {
    if ('children' in menu) {
      return menu.children || [];
    }
    return [menu];
  }
  return [];
}

export function buildContentList(snlText: string) {
  // todo 排序
  const tree = extractRuleTree(snlText);
  const parser = new DOMParser();
  const snlDoc = parser.parseFromString(snlText, 'text/xml');
  // 提取标签信息
  const groups: {[key: string]: string} = {};
  const groupNodes = snlDoc.evaluate(`//group`, snlDoc, null, XPathResult.UNORDERED_NODE_ITERATOR_TYPE);
  let n = groupNodes.iterateNext();
  while (n) {
    const groupName = snlDoc.evaluate('group_name', n, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
    const id = (n as Element).getAttribute('id');
    if (id) {
      groups[id] = groupName?.textContent || '';
    }
    n = groupNodes.iterateNext();
  }

  // 按照目录顺序拼接所有内容
  const snlBlocks: any[] = [];
  (function iter(node) {
    if (node.children && node.children.length > 0) {
      for (const n of node.children) {
        iter(n);
      }
    } else {
      const ruleId = node.ruleId;
      // 首先插入主体部分
      const ruleNode = snlDoc.evaluate(`//rule[@id="${ruleId}"]`, snlDoc, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
      if (ruleNode) {
        const ruleGroup = snlDoc.evaluate('rule_group', ruleNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
        const des = snlDoc.evaluate('rule_nat_descpt', ruleNode, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
        snlBlocks.push({
          ruleId: ruleId,
          ruleNumber: node.ruleNumber,
          ruleGroups: (ruleGroup?.textContent || '').split(' ').map(id => groups[id]),
          des: des?.textContent,
          snls: [],
        });
      }
      // 再插入每一条SNL
      const nodes = snlDoc.evaluate(`//fml_descpt[ruleId[text()="${ruleId}"]]`, snlDoc, null, XPathResult.ANY_TYPE);
      // 遍历查询结果
      let n = nodes.iterateNext();
      while (n) {
        // 解析单条文数据
        const snl = snlDoc.evaluate('descpt_snl', n, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
        const splitText = snlDoc.evaluate('descpt_snl_split_text', n, null, XPathResult.FIRST_ORDERED_NODE_TYPE).singleNodeValue;
        snlBlocks[snlBlocks.length - 1].snls.push({
          snl: snl?.textContent,
          text: splitText?.textContent,
        });
        n = nodes.iterateNext();
      }
    }
  })(tree);
  return snlBlocks;
}