import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, message, Progress, Tag, Tree } from 'antd';
import { FieldDataNode } from 'rc-tree/lib/interface';
import styles from './SpaceTree.module.less';
import { LayoutContext } from '../TuzhiVis';
import UnionViewer2D from '../union-viewer/UnionViewer2D';
import { Space } from '../../../interfaces';
import { selectSpaces, setActiveSpaceId, setCheckSpaceId } from '../tuzhiVisSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import * as FlexLayout from 'flexlayout-react';
import ModelCheckerService from '../model-checker/ModelChecker.service';
import { saveAs } from 'file-saver';
import TuzhiVisService from '../TuzhiVis.service';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SpaceTreeProps {
}

type SpaceTreeDataNode = FieldDataNode<{
  key: string;
  title: string;
  spaceId: number;
}>;

function SpaceTree(props: SpaceTreeProps) {
  const spaces = useAppSelector(selectSpaces);
  const dispatch = useAppDispatch();

  const layoutContext = useContext(LayoutContext);

  const [spaceTree, setSpaceTree] = useState<SpaceTreeDataNode[]>([]);
  const [expandKeys, setExpandKeys] = useState<React.Key[]>([]);

  const [showUnknown, setShowUnknown] = useState(false);

  useEffect(() => {
    // 通过线性目录构造目录树
    const treeRoot: SpaceTreeDataNode = {
      title: '项目根目录',
      key: 'root',
      spaceId: -1,
      children: [],
    };
    const expandKeys: string[] = ['root'];
    spaces.forEach((space: Space) => {
      if (space.name.includes('未知') && !showUnknown)
        return;
      const path = [...space.treePath, `${space.name}`]; // todo name是否和tree合并
      let currentNode = treeRoot;
      let currentKey = 'root';
      for (let i = 0; i < path.length; i++) {
        currentKey += '/' + path[i];
        if (!currentNode.children) {
          currentNode.children = [];
        }
        let node = currentNode.children.find(n => n.key === currentKey);
        if (!node) {
          node = {
            title: path[i],
            key: currentKey,
            spaceId: space.spaceId,
            children: [],
          };
          currentNode.children.push(node);
          expandKeys.push(currentKey);
        }
        // @ts-ignore todo
        currentNode = node;
      }
    });
    setSpaceTree(() => [treeRoot]);
    setExpandKeys(() => expandKeys);
  }, [spaces, showUnknown]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandKeys(expandedKeysValue);
  };


  const onPreview = (spaceId: number) => {
    // 新建tab页或跳转tab页，打开当前资源
    const tabId = `space-${spaceId}`;
    dispatch(setActiveSpaceId(spaceId));
    if (layoutContext.model.getNodeById(tabId)) {
      layoutContext.model.doAction(
        FlexLayout.Actions.selectTab(tabId),
      );
    } else {
      layoutContext.model.doAction(FlexLayout.Actions.addNode(
        {
          id: tabId, type: 'tab',
          component: 'union-viewer',
          name: `[空间${spaceId}]${spaces.find(s => s.spaceId === spaceId)?.name}`,
          config: {spaceId: spaceId},
        },
        'viewer-pane', FlexLayout.DockLocation.CENTER, -1, true,
      ));
    }
  };

  const onCheck = (spaceId: number) => {
    dispatch(setCheckSpaceId(spaceId));
  };

  return (
    <div className={styles.panelWrapper}>
      {/*<div>*/}
      {/*  空间树两个根节点，一个是空间层，一个是对应原始图纸最小识别单元*/}
      {/*</div>*/}
      <Checkbox
        value={showUnknown}
        onChange={e => {
        setShowUnknown(!showUnknown);
      }}>未知</Checkbox>
      <div className={styles.treeWrapper}>
        {/*<div>搜索文件、清空按钮</div>*/}
        <Tree.DirectoryTree
          showLine
          defaultExpandAll
          onExpand={onExpand}
          expandedKeys={expandKeys}
          treeData={spaceTree}
          titleRender={(data) => {
            if (typeof data.title === 'string')
              return (
                <Dropdown menu={{
                  items: [
                    {
                      key: 'preview',
                      label: (
                        <div
                          onClick={e => {
                            e.preventDefault();
                            onPreview(data.spaceId);
                          }}
                        >
                          预览空间
                        </div>
                      ),
                    },
                    {
                      key: 'check',
                      label: (
                        <div
                          onClick={e => {
                            e.preventDefault();
                            onCheck(data.spaceId);
                          }}
                        >
                          审查空间
                        </div>
                      ),
                    },
                    {
                      key: 'bcjson',
                      label: (
                        <div
                          onClick={e => {
                            e.preventDefault();
                            TuzhiVisService.getBCJSONBySpaceId(data.spaceId)
                              .then(res => {
                                saveAs(res.data, `model-${data.spaceId}.json`);
                                message.success('BCJSON文件下载成功');
                              });
                          }}
                        >
                          导出BCJSON
                        </div>
                      ),
                    },
                    {
                      key: 'ifc',
                      label: (
                        <div
                          onClick={e => {
                            e.preventDefault();
                            TuzhiVisService.getIFCBySpaceId(data.spaceId)
                              .then(res => {
                                saveAs(res.data, `model-${data.spaceId}.ifc`);
                                message.success('IFC文件下载成功');
                              });
                          }}
                        >
                          下载IFC
                        </div>
                      ),
                    },
                    {
                      key: 'reload-ifc',
                      label: (
                        <div
                          onClick={e => {
                            e.preventDefault();
                            TuzhiVisService.refreshIFCBySpaceId(data.spaceId)
                              .then(res => {
                                message.success('刷新IFC成功');
                              });
                          }}
                        >
                          刷新IFC
                        </div>
                      ),
                    },
                  ],
                  style: {minWidth: 100, textAlign: 'center'},
                }} trigger={['contextMenu']}>
                  <div className={styles.treeItem}>
                    <div className={styles.name}>{data.spaceId && data?.children?.length === 0 ? <Tag>{data.spaceId.toString()}</Tag> : ''}{data.title}</div>
                  </div>
                </Dropdown>
              );
          }}
        />
      </div>
    </div>
  );
}

export default SpaceTree;