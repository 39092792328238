import React from 'react';
import mdxComponents from '../../components/mdxComponents';
import IndexArticle from '../../articles/index.mdx';
import Footer from '../../components/Footer';

import styles from './Portal.module.less';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../app/hooks';
import { selectVersion } from '../../appSlice';

function Portal() {
  const version = useAppSelector(selectVersion);
  return (
    <div>
      <Helmet>
        <title>图智 {version}</title>
      </Helmet>
      <div  className={styles.mdxWrapper}>
        <IndexArticle components={mdxComponents}/>
        <Footer/>
      </div>
    </div>
  );
}

export default Portal;