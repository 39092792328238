import React from 'react';

import styles from './ShapeViewer.module.less';

interface ShapeViewerProps {
  temp: string;
}

function ShapeViewer(props: ShapeViewerProps) {
  const {temp} = props;
  return (
    <div className={styles.root}>
      23333
    </div>
  )
}

export default ShapeViewer;
