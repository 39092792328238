import React from 'react';
import { Row, Col, Button, message } from 'antd';
import { useState, useEffect } from 'react';
import styles from './DiffFunction.module.less';
import ConsistencyViewerService from '../ConsistencyViewer.service';

interface DiffFunctionProps {
    projectId: number;
  }

function DiffFunction(props: DiffFunctionProps){
    const { projectId } = props;
    const [finished, setFinished] = useState(false);
    useEffect(() => {
        ConsistencyViewerService.getComparison(projectId).then((res: any) => {
            setFinished(res.data["hasResult"]);
            console.log("结果",res.data["hasResult"]);
        });
      }, []); // Empty dependency array to run this effect only once
    return (
        <div className={styles.root}>
            <Row>
                <Col span={11} className={styles.singleCol}>
                    <div>执行比对</div>
                </Col>
                <Col span={6} className={styles.singleCol} style={{color:finished?"green":"blue"}}>
                    <div>{finished?"已完成":"未完成"}</div>
                </Col>
                <Col span={7} className={styles.singleCol}>
                    <Button type="primary" onClick={()=>{
                        message.info("开始执行比对算法");
                        ConsistencyViewerService.executeComparison(projectId).then((res: any) => {
                            const status = res.data["status"];
                            if(status=="pdf_missing"){
                                message.error("图纸Bcjson尚未提交");
                            }
                            else if(status=="bim_missing"){
                                message.error("模型Bcjson尚未提交")
                            }
                            else if(status=="success"){
                                setFinished(true);
                                message.success("比对算法运行完成");
                            }
                        });
                    }}>执行</Button>
                </Col>
            </Row>
        </div>
    );
}

export default DiffFunction;
