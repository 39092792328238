import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { store } from '../../../../app/store';
import { API_BASE_URL } from '../../../../config';

class ModifierService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;

  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: headers,
    });
    return this.client;
  }

  /** 添加几何数据
   *
   * @param spaceId 空间id
   * @param wkt 几何数据
   * @param categoryName 构件类别
   * @param familyName 构件族名
   * @param elementName 构件名称
   */
  async addGeometry(spaceId: number, wkt: string, categoryName: string, familyName: string, elementName: string) {
    return this.init().post('/geometry', {
      spaceId,
      newWkt: wkt,
      categoryName,
      familyName,
      elementName,
    });
  }

  /** 修改几何数据
   *
   */
  async modifyGeometry(geomId: number, wkt: string | null) {
    return this.init().put(`/geometry/${geomId}`, {
      newWkt: wkt,
    });
  }
}

export default new ModifierService();
