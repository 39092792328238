import React, { useState } from 'react';

import styles from './UploadAssetModal.module.less';
import { message, Modal, Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { API_BASE_URL } from '../../../config';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectToken } from '../../auth/authSlice';
import { fetchAssetsByProjectId, fetchGeomsBySpaceId, setAssets } from '../tuzhiVisSlice';
import TuzhiVisService from '../TuzhiVis.service';

interface ModalProps {
  projectId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function UploadAssetModal(props: ModalProps) {
  const token = useAppSelector(selectToken);
  const { projectId, open, setOpen } = props;

  const [isUploadDisabled, setIsUploadDisabled] = useState(false);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (!isUploadDisabled) {
      setOpen(false);
    }
  };

  const uploadProps: UploadProps = {
    name: 'zip',
    action: `${API_BASE_URL}/asset/uploadZip`,
    accept: 'application/zip',
    multiple: true,
    data: {
      projectId: projectId
    },
    maxCount: 1,
    disabled: isUploadDisabled,
    headers: {
      'Authorization': `Bearer ${token}`
    },
    onChange(info) {
      console.log('kaishile');
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      } else {
        setIsUploadDisabled(true);
      }
      if (status === 'done') {
        setIsUploadDisabled(false);
        message.success(`${info.file.name} 上传成功。`);
        // 上传后自动刷新资源树
        // todo 更改写法
        TuzhiVisService
          .getAssetsByProjectId(projectId)
          .then(res => {
            dispatch(setAssets(res.data));
          });
      } else if (status === 'error') {
        setIsUploadDisabled(false);
        message.error(`${info.file.name} 上传失败。`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div className={styles.root}>
      <Modal
        title="打包上传文件"
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        okText="关闭"
        okButtonProps={{ disabled: isUploadDisabled }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>todo 组织结构提示</p>
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击上传图纸压缩包</p>
          <p className="ant-upload-hint">
            请上传按规定目录结构打包的zip文件
          </p>
        </Upload.Dragger>
      </Modal>
    </div>
  );
}

export default UploadAssetModal;
