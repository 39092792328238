import axios from 'axios';
import { API_BASE_URL } from '../../config';

class AuthService {
  constructor() {
    this.AUTH_BASE_URL = `${API_BASE_URL}/auth`;
  }

  init() {
    this.client = axios.create({
      baseURL: this.AUTH_BASE_URL,
      timeout: 10000,
    });
    return this.client;
  }

  async login(username, password) {
    return this.init().post('/login', {
      username,
      password
    });
  }

  logout() {
    // todo

  }
}

export default new AuthService();
