import React, { useCallback, useEffect, useState } from 'react';

import styles from './SNLViewer.module.less';
import { useParams } from 'react-router-dom';
import { BackTop, Col, Row } from 'antd';
import Footer from '../../components/Footer';
import LibraryTree from './LibraryTree';
import ContentList from './ContentList';
import RuleTree from './RuleTree';
import SNLViewerService from './SNLViewer.service';
import { without } from 'lodash';
import { SNLLibrary } from '../../models/snl/SNLLibrary';
import Abstract from './Abstract';

interface SNLViewerProps {
  temp: string;
}

function SNLViewer(props: SNLViewerProps) {
  const {temp} = props;

  const {'*': snlPath} = useParams();

  const [snlList, setSnlList] = useState([]);
  const [currentSnlLibrary, setCurrentSnlLibrary] = useState<SNLLibrary | null>(null);
  const [snlContent, setSnlContent] = useState<string | null>(null);
  const [activeRuleNumbers, setActiveRuleNumbers] = useState<string[]>([]);

  useEffect(() => {
    SNLViewerService.getOnlineSNLList()
      .then(res => {
        setSnlList(res.data.snls);
      });
  }, []);

  useEffect(() => {
    if (snlPath) {
      SNLViewerService.getOnlineSNLContent(snlPath + '.snl')
        .then(res => {
          setSnlContent(res.data);
          setCurrentSnlLibrary(SNLLibrary.loadFromXMLString(res.data));
        });
    }
  }, [snlPath]);

  const setRuleNumberState = useCallback(
    (ruleNumber: string, inView: boolean) => {
      if (inView) {
        setActiveRuleNumbers([...activeRuleNumbers, ruleNumber]);
      } else {
        setActiveRuleNumbers(without(activeRuleNumbers, ruleNumber));
      }
    },
    [activeRuleNumbers],
  );

  return (
    <div className={styles.root}>
      <BackTop />
      <Row gutter={4}>
        <Col span={5} style={{ position: 'sticky', top: 10, height: 'calc(100vh - 20px)', overflowY: 'auto' }}>
          <div className={styles.panel}>
            <LibraryTree snlList={snlList} />
          </div>
        </Col>
        <Col span={19}>
          <Row>
            <Abstract snlLibrary={currentSnlLibrary} />
          </Row>
          <Row gutter={4}>
            <Col span={6} style={{ position: 'sticky', top: 10, height: 'calc(100vh - 20px)', overflowY: 'auto' }}>
              <div className={styles.panel}>
                <RuleTree snlLibrary={currentSnlLibrary} activeRuleNumbers={activeRuleNumbers} />
              </div>
            </Col>
            <Col span={18}>
              <div className={styles.panel}>
                {
                  currentSnlLibrary &&
                  <ContentList snlLibrary={currentSnlLibrary} content={snlContent} activeRuleNumbers={activeRuleNumbers} setRuleNumberState={setRuleNumberState} />
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer/>
    </div>
  )
}

export default SNLViewer;
