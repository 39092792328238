import React, { useEffect } from 'react';
import './App.less';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TuzhiVis from './modules/tuzhi-vis/TuzhiVis';
import NavBar from './modules/navbar/NavBar';
import ProjectList from './modules/project-list/ProjectList';
import UserLogin from './modules/auth/UserLogin';
import AuthRoute from './modules/auth/AuthRoute';
import Portal from './modules/portal/Portal';
import SNLViewer from './modules/snl-viewer/SNLViewer';
import ConsistencyList from './modules/consistency-list/ConsistencyList';
import ConsistencyViewer from './modules/consistency-viewer/ConsistencyViewer';
import { useAppDispatch } from './app/hooks';
import { fetchVersion } from './appSlice';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVersion());
  }, []);

  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route
            path="/snl/*"
            element={(
              <AuthRoute
                type="private"
                outlet={(
                  <SNLViewer temp={'3434'}/>
                )}
              />
            )}
          />
          <Route
            path="/project/:username/:projectId/:projectName"
            element={(
              <AuthRoute
              type="private"
              outlet={(
                <TuzhiVis />
              )}
              />
            )}
          />
          <Route
            path="/"
            element={(
              <Portal />
            )}
          />
          <Route
            path="/login"
            element={(
              <UserLogin />
            )}
          />
          <Route
            path="/projects"
            element={(
              <AuthRoute
                type="private"
                outlet={(
                  <ProjectList />
                )}
              />
            )}
          />
          <Route
            path="/consistencys"
            element={(
              <ConsistencyList username="test" />
            )}
          />
          <Route
            path="/consistency/:projectName/:projectId"
            element={(
              <ConsistencyViewer username="test" projectId={0} />
            )}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
