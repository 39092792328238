import React from 'react';
import { API_BASE_URL } from '../config';

function Footer() {
  return (
    <div style={{textAlign: 'center', fontSize: 14}}>
      <div>Copyright © 清华大学软件学院 BIMChecker Group 2023.</div>
      <div>联系方式：thubimchecker@qq.com</div>
      <div>zhanghehua@tsinghua.edu.cn</div>
      <div>主页：http://zhanghehua.org/latest</div>

      <small>
        <div>当前API环境: {API_BASE_URL?.replace(/\/\/(.*):\d+/, '$1')}</div>
      </small>
    </div>
  );
}

export default Footer;