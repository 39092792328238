import React, { createContext, useEffect, useRef, useState } from 'react';

import { message } from 'antd';
import styles from './TuzhiVis.module.less';
import AssetTree from './asset-tree/AssetTree';
import ModelChecker from './model-checker/ModelChecker';
import { Navigate, useParams } from 'react-router-dom';
import SpaceTree from './space-tree/SpaceTree';
import ProgressViewer from './progress-viewer/ProgressViewer';
import TuzhiVisService from './TuzhiVis.service';
import {
  activateLog,
  appendLog,
  clearCache,
  deactivateLog,
  fetchPluginsByProjectId,
  fetchSNLs, fetchSpaceRelations,
  selectLog,
  setActiveSpaceId,
  setAssets,
  setChecks,
  setProject,
  setSpaces,
  setTasks,
} from './tuzhiVisSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { io, Socket } from 'socket.io-client';
import { API_BASE_URL } from '../../config';
import { selectToken, selectUser } from '../auth/authSlice';
import Push from 'push.js';
import _ from 'lodash';
import * as FlexLayout from 'flexlayout-react';
import AssetViewer from './asset-viewer/AssetViewer';
import UnionViewer2D from './union-viewer/UnionViewer2D';
import SpacePropertyEditorList from './space-property-editor/SpacePropertyEditorList';
import { Helmet } from 'react-helmet';
import { Task } from '../../interfaces';
import LayerController from './layer-controller/LayerController';
import Stats from 'stats.js';
import PluginDAG from './dag-vis/PluginDAG';
import ProjectGraph from './dag-vis/ProjectGraph';
import LogViewer from '../log-viewer/LogViewer';
import { store } from '../../app/store';
import SummaryViewer from '../summary-viewer/SummaryViewer';
import { ActiveElementPropertyViewer } from './property-viewer/ElementPropertyViewer';
import SpacePropertyViewer from './property-viewer/SpacePropertyViewer';
import ProjectPropertyViewer from './property-viewer/ProjectPropertyViewer';
import ShapeViewer from './shape-viewer/ShapeViewer';
import Toolbar from './toolbar/Toolbar';

const defaultLayout: FlexLayout.IJsonModel = {
  global: {
    splitterSize: 4,
    splitterExtra: 4,
  },
  borders: [],
  layout: {
    type: 'row',
    id: 'root',
    children: [
      {
        type: 'row',
        id: 'left-pane',
        weight: 1,
        children: [
          {
            type: 'tabset',
            id: 'asset-pane',
            weight: 4,
            children: [
              {
                type: 'tab',
                id: 'asset-tree',
                name: '资源树',
                component: 'asset-tree'
              }
            ],
            active: true
          },
          {
            type: 'tabset',
            id: 'log-pane',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'progress',
                name: '执行进度',
                component: 'progress',
              },
              {
                type: 'tab',
                id: 'project-dag',
                name: '项目任务图',
                component: 'project-graph',
              },
              {
                type: 'tab',
                id: 'plugin-dag',
                name: '插件DAG图',
                component: 'plugin-dag',
              },
              {
                type: 'tab',
                id: 'log',
                name: '执行日志',
                component: 'log',
              },
            ]
          },
          {
            type: 'tabset',
            id: 'space-pane',
            weight: 4,
            children: [
              {
                type: 'tab',
                id: 'space',
                name: '空间树',
                component: 'space-tree',
              }
            ]
          }
        ]
      },
      {
        type: 'row',
        id: 'center-pane',
        weight: 4,
        children: [
          {
            type: 'tabset',
            id: 'viewer-pane',
            weight: 4,
            children: [
              {
                type: 'tab',
                id: 'viewer-summary',
                name: '项目摘要',
                enableClose: false,
                component: 'project-summary',
              },
              // {
              //   type: 'tab',
              //   id: 'viewer-1',
              //   name: '项目全局属性',
              //   enableClose: false,
              //   component: 'global-properties',
              // }
            ]
          },
          {
            type: 'tabset',
            id: 'check-pane',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'check',
                name: '审查面板',
                component: 'check',
                enableFloat: true,
              },
            ]
          }
        ]
      },
      {
        type: 'row',
        id: 'right-pane',
        weight: 1,
        children: [
          {
            type: 'tabset',
            id: 'layer-pane',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'cad-layer',
                name: '图层',
                component: 'layer',
              },
              {
                type: 'tab',
                id: 'shape-layer',
                name: '包围盒数据',
                component: 'shape',
              }
            ]
          },
          {
            type: 'tabset',
            id: 'property-layer',
            weight: 1,
            children: [
              {
                type: 'tab',
                id: 'element-properties',
                name: '构件属性',
                component: 'element-properties',
                enableClose: false,
              },
              {
                type: 'tab',
                id: 'space-properties',
                name: '空间属性',
                component: 'space-properties',
                enableClose: false,
              },
              {
                type: 'tab',
                id: 'project-properties',
                name: '项目属性',
                component: 'project-properties',
                enableClose: false,
              }
            ]
          }
        ]
      }
    ]
  }
};

export const LayoutContext = createContext<{
  model: FlexLayout.Model
}>({
  model: FlexLayout.Model.fromJson(defaultLayout)
});

export default function TuzhiVis() {
  const { username, projectId, projectName } = useParams();

  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const log = useAppSelector(selectLog);

  const [layoutModel, setLayoutModel] = useState<FlexLayout.Model>(FlexLayout.Model.fromJson(defaultLayout));
  const [stats, setStats] = useState(new Stats());

  const socketRef = useRef<Socket | null>(null);
  const logTimerRef = useRef<number | null>(null);

  // 切换新的项目时，清空所有缓存数据
  useEffect(() => {
    dispatch(clearCache());
  }, []);

  useEffect(() => {
    // 请求通知API权限，用于通知项目识别状态
    Push.Permission.request(() => {
      Push.create('图智通知', {
        body: '成功开启通知权限，将在识别任务结束时通过桌面通知提醒进度',
        timeout: 10000,
        onClick: function () {
          window.focus();
        }
      });
    }, () => {
      message.error('未成功开启通知权限, 将不会通知您识别进度');
    });
    // 连接SocketIO，获取当前项目的进度与日志
    console.log('开始连接SocketIO');
    const socket = io(`${API_BASE_URL}/progress`, {
      extraHeaders: {
        'X-Tuzhi-User': user.username,
        'X-Tuzhi-JWT': token,
        'X-Tuzhi-ProjectId': `${projectId}`,
      }
    });
    socketRef.current = socket;

    const throttleProgress = _.throttle(() => {
      socket.emit('progress', {projectId: projectId});
      socket.emit('check', {projectId: projectId});
    }, 1500);

    socket.on('connect', () => {
      // setIsConnected(true);
    });

    socket.on('disconnect', () => {
      // setIsConnected(false);
    });

    socket.on('progress', ({ type, data }) => {
      // 简单解析任务数据，用于修改标题
      const percentage = data.filter((t: Task) => t.state === 'SUCCESS').length / data.length * 100;
      window.document.title = `[${percentage.toFixed(0)}%] ${projectName} - 图智`;

      // 保存任务数据
      dispatch(setTasks(data));
      // todo 临时写法，修改为主动推送后，可修改此实现更新空间数据
      if (projectId) {
        TuzhiVisService
          .getAssetsByProjectId(parseInt(projectId))
          .then(res => {
            dispatch(setAssets(res.data));
          });
        TuzhiVisService
          .getSpacesByProjectId(parseInt(projectId))
          .then(res => {
            dispatch(setSpaces(res.data));
          });
        dispatch(fetchSpaceRelations(Number(projectId)));
      }
    });
    socket.on('check', ({ type, data }) => {
      dispatch(setChecks(data));
    });
    socket.on('socket-log', ({ type, data }) => {
      console.log('SocketIO日志', data);
    });
    socket.on('log', ({ type, data }) => {
      console.log(data);
      dispatch(appendLog({
        offset: data.offset,
        content: data.content,
      }));
    });
    socket.on('project', ({ type, data }) => {
      console.log('项目通知,识别任务完成或失败');
      Push.create('图智通知', {
        body: '项目识别已完成',
        timeout: 10000,
        onClick: function () {
          window.focus();
        }
      });
    });
    socket.on('task', ({ state, message }) => {
      console.log('任务进度:', state, message);
      // todo throttle调用请求更新进度、审查与空间列表
      throttleProgress();
    });

    // 只首次查询整体识别与审查进度
    socket.emit('progress', {projectId: projectId});
    socket.emit('check', {projectId: projectId});

    // // 自动轮询进度
    // const interval = setInterval(() => {
    //   socket.emit('progress', {projectId: projectId});
    //   socket.emit('check', {projectId: projectId});
    // }, 2000);

    return () => {
      // clearInterval(interval);
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  }, []);

  useEffect(() => {
    if (log.active) {
      logTimerRef.current = window.setInterval(() => {
        if (socketRef.current)
          socketRef.current.emit('log', {projectId: projectId, offset: store.getState().vis.log.offset});
      }, 1000);
    } else if (!log.active && logTimerRef.current) {
      clearInterval(logTimerRef.current);
    }
  }, [log.active]);

  useEffect(() => {
    // todo 在项目根目录处理时加载资源树、空间树等数据，避免重复请求
    if (!projectId) {
      return;
    }
    // todo 更改写法
    TuzhiVisService
      .getProject(parseInt(projectId))
      .then(res => {
        console.log('项目信息', res.data);
        dispatch(setProject(res.data));
      });
    TuzhiVisService
      .getAssetsByProjectId(parseInt(projectId))
      .then(res => {
        dispatch(setAssets(res.data));
      });
    TuzhiVisService
      .getSpacesByProjectId(parseInt(projectId))
      .then(res => {
        dispatch(setSpaces(res.data));
      });
    dispatch(fetchSNLs());
    dispatch(fetchPluginsByProjectId(Number(projectId)));
    dispatch(fetchSpaceRelations(Number(projectId)));
  }, [projectId]);

  useEffect(() => {
    stats.showPanel(0);
    let requestId: number;
    function animate() {
      stats.update();
      requestId = requestAnimationFrame(animate);
    }
    requestId = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(requestId);
    };
  }, []);

  if (!username || !projectId) {
    return <Navigate to="/projects" />;
  }
  return (
    <LayoutContext.Provider value={{
      model: layoutModel,
    }}>
      <Helmet>
        <title>{projectName} - 图智</title>
      </Helmet>
      <div className={styles.fps}>
        <div ref={ref => ref && ref.appendChild(stats.dom)}/>
      </div>
      <div className={styles.windowWrapper}>
        <Toolbar
          username={username}
          projectId={projectId}
          projectName={projectName || ''}
        />
        <div className={styles.dockWrapper}>
          <FlexLayout.Layout
            model={layoutModel}
            font={{size: '12px'}}
            // realtimeResize
            factory={(node) => {
              switch (node.getComponent()) {
                case 'asset-tree':
                  return <AssetTree projectId={Number(projectId)} />;
                case 'asset-viewer':
                  return <AssetViewer assetId={node.getConfig().assetId} />;
                case 'space-tree':
                  return <SpaceTree />;
                case 'union-viewer':
                  return <UnionViewer2D spaceId={node.getConfig().spaceId} />;
                case 'progress':
                  return <ProgressViewer projectId={Number(projectId)} />;
                case 'check':
                  return <ModelChecker />;
                case 'element-properties':
                  return <ActiveElementPropertyViewer temp="233" />;
                case 'space-properties':
                  return <SpacePropertyViewer temp="233" />;
                case 'project-properties':
                  return <ProjectPropertyViewer temp="233" />;
                case 'global-properties':
                  return <SpacePropertyEditorList projectId={Number(projectId)} />;
                case 'layer':
                  return <LayerController />;
                case 'plugin-dag':
                  return <PluginDAG projectId={Number(projectId)} />;
                case 'project-graph':
                  return <ProjectGraph projectId={Number(projectId)} />;
                case 'log':
                  return <LogViewer projectId={Number(projectId)} />;
                case 'project-summary':
                  return <SummaryViewer projectId={Number(projectId)} />;
                case 'shape':
                  return <ShapeViewer temp="233" />;
                default:
                  return <div>默认</div>;
              }
            }}
            onContextMenu={(node, event) => {
              event.preventDefault();
              event.stopPropagation();
              if (node.getId() === 'space') {
                alert(233);
              }
            }}
            onAction={(action) => {
              console.log('layout action', action);
              if (action.type === 'FlexLayout_SelectTab') {
                // 精确的正则匹配，避免匹配到空间属性面板
                const match = action.data.tabNode.match(/space-(\d+)/);
                if (match)
                  dispatch(setActiveSpaceId(match[1]));
              }
              // 比较tricky的实现，在action已经执行完毕后再判断当前tab的可见状态进行切换
              setTimeout(() => {
                if (layoutModel.getNodeById('log')?.isVisible()) {
                  dispatch(activateLog());
                } else {
                  dispatch(deactivateLog());
                }
              }, 0);
              return action;
            }}
          />
        </div>
      </div>
    </LayoutContext.Provider>
  );
}
