import React from 'react';
import { NodeRendererProps, Tree } from 'react-arborist';
import { useResizeDetector } from 'react-resize-detector';

import styles from './TuzhiTree.module.less';

type NodeProp = {
  id: string;
  name: string;
};

function Node({ node, style }: NodeRendererProps<NodeProp>) {
  return (
    <div className={styles.node} style={style} onClick={() => node.toggle()}>
      {
        node.isLeaf ?
          '' : '🗀'
      } {node.data.name}
    </div>
  );
}

interface TuzhiTreeProp<T> {
  data: any;
}

function TuzhiTree(props: TuzhiTreeProp<NodeProp>) {
  const {data} = props;

  const { width, height, ref } = useResizeDetector({
    // refreshMode: 'throttle',
    // refreshRate: 500
  });

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <Tree
        disableDrag
        indent={16}
        data={data}
        width={width}
        height={height}
      >
        {Node}
      </Tree>
    </div>
  );
}

export default TuzhiTree;