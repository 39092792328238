import React, { useMemo, useState } from 'react';

import styles from './SpacePropertyViewer.module.less';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSpaceId, selectProject, selectSpaces } from '../tuzhiVisSlice';
import GeneralPropertyViewer from './GeneralPropertyViewer';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

interface ProjectPropertyViewerProps {
  temp: string;
}

function ProjectPropertyViewer(props: ProjectPropertyViewerProps) {
  const {temp} = props;

  // 项目信息
  const project = useAppSelector(selectProject);

  const [collapse, setCollapse] = useState(false);

  return (
    <div className={styles.root}>
      {
        project && (
          <div>
            <span
              className={styles.elementTitle}
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              {collapse ? <PlusSquareOutlined/> : <MinusSquareOutlined/>}{' '}
              <span className={styles.count}>[当前项目属性]</span>{' '}
              <span>#{project.projectId} {project.name}</span>
            </span>
            <div
              style={{display: collapse ? 'none' : ''}}
            >
              <GeneralPropertyViewer project={project}/>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ProjectPropertyViewer;
