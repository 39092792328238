import { Graphics } from 'pixi.js';

Graphics.prototype.drawDashLine = function(
  x1, y1, x2, y2,
  scale: number,
  dash = 10, gap = 5, offset = 0
) {
  this.lineStyle(5, 0xffffff, 1, 0, true);

  const current = {x: x1, y: y1};
  const totalLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));

  // todo 这里需要用atan2保留方向，不能用atan
  const theta = Math.atan2((y2 - y1) , (x2 - x1));

  const offsetIncX = offset * Math.cos(theta) / scale;
  const offsetIncY = offset * Math.sin(theta) / scale;
  const dashIncX = dash * Math.cos(theta) / scale;
  const dashIncY = dash * Math.sin(theta) / scale;
  const gapIncX = gap * Math.cos(theta) / scale;
  const gapIncY = gap * Math.sin(theta) / scale;

  current.x += offsetIncX;
  current.y += offsetIncY;

  // 按总长度计算绘制总次数（一次包含一个dash和一个gap）
  let i;
  for (i = 1; i < (totalLength - (offset / scale)) / (dash + gap) * scale; i++) {
    this.moveTo(current.x, current.y);
    this.lineTo(current.x + dashIncX, current.y + dashIncY);
    current.x = current.x + dashIncX + gapIncX;
    current.y = current.y + dashIncY + gapIncY;
  }
  // 末尾必然补充
  this.moveTo(current.x, current.y);
  this.drawCircle(current.x, current.y, 3);
  if (totalLength < offset / scale) {
    // this.lineTo(x2, y2);
  } else {
    const remain = totalLength - (offset / scale) - (i - 1) * (dash + gap) / scale;
    if (totalLength > (dash + offset) / scale && remain > dash / scale) {
      // this.lineStyle(1, 0x0);
      this.lineTo(current.x + dashIncX, current.y + dashIncY);
    } else {
      this.lineTo(x2, y2);
    }
  }

  this.drawCircle(x2, y2, 3);

  // 判断开头是否需要补充线条
  if (offset > gap) {
    this.moveTo(x1, y1);
    if (totalLength < dash / scale) {
      this.lineTo(x2, y2);
    } else {
      this.lineTo(x1 + offsetIncX - gapIncX, y1 + offsetIncY - gapIncY);
    }
  }
};