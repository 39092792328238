import React from 'react';

import styles from './Toolbar.module.less';
import { Button, message, Tabs, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectMode, setMode, startNewShape } from '../union-viewer/modifier-layer/modifierSlice';
import { selectActiveSpaceId } from '../tuzhiVisSlice';

interface ToolbarProps {
  username: string;
  projectId: string;
  projectName: string;
}

function Toolbar(props: ToolbarProps) {
  const {username, projectId, projectName} = props;

  const dispatch = useAppDispatch();
  const mode = useAppSelector(selectMode);
  const activeSpaceId = useAppSelector(selectActiveSpaceId);

  return (
    <div className={styles.toolPanel}>
      <Tabs
        defaultActiveKey="1"
        size="small"
        tabBarGutter={10}
        tabBarStyle={{
          marginLeft: 10,
        }}
        // onChange={onChange}
        items={[
          {
            label: `项目信息`,
            key: '1',
            children: (
              <>
                <Tag color="purple">当前用户 | {username}</Tag>
                <Tag color="purple">项目ID | {projectId}</Tag>
                <Tag color="purple">项目名称 | {projectName}</Tag>
              </>
            ),
          },
          {
            label: `数据修改`,
            key: '2',
            children: (
              <>
                <Button.Group>
                  <Button
                    size="small"
                    onClick={() => {
                      // 判断当前激活的面板是否是一个合法空间
                      if (activeSpaceId) {
                        dispatch(startNewShape(activeSpaceId));
                      } else {
                        message.warn('请先选择一个空间面板');
                      }
                    }}
                  >
                    新增包围盒
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      message.info('右键待修改的包围盒并点击修改即可');
                    }}
                  >
                    修改包围盒
                  </Button>
                </Button.Group>
              </>
            ),
          },
          {
            label: `布局调整`,
            key: '3',
            children: (
              <>
                <Button.Group>
                  <Button size="small">
                    默认布局1
                  </Button>
                </Button.Group>
              </>
            ),
          },
        ]}
      />
    </div>
  );
}

export default Toolbar;
