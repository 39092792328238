import React from "react";
import { Row, Col, Button, List, Select, InputNumber } from "antd";
import { useState, useEffect } from "react";
import styles from "./BimItemViewer.module.less";
import ConsistencyViewerService from "../ConsistencyViewer.service";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setSelectedElementIdsBySpaceId,
  setActiveSpaceId,
  selectActiveSpaceId,
} from "../../tuzhi-vis/tuzhiVisSlice";
import {
  setProjectId,
  setElevation,
  selectElevation,
  setBimProperties,
  selectBimProperties,
} from "../consistencyViewerSlice";


function BimItemViewer(props:any) {
  const { projectId } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const elevation = useAppSelector(selectElevation);
  const [resultType, setResultType] = useState("all");
  const [pdfId, setPdfId] = useState<number>(0);
  const [filterPdfId, setFilterPdfId] = useState<number>(0);
  const [bimId, setBimId] = useState<number>(0);
  const [filterBimId, setFilterBimId] = useState<number>(0);
  const [type, setType] = useState("all");
  const properties = useAppSelector(selectBimProperties);
 
  return (
    <div className={styles.root}>
      {properties ?
        properties.map((item: any) => {
          return (
            <Row key={Object.keys(item)[0]} className={styles.item}>
              <Col span={12} className={styles.title}>{Object.keys(item)[0]}</Col>
              <Col span={12} className={styles.content}>{Object.values(item)[0] as React.ReactNode}</Col>
            </Row>
          );
        })
        : "暂未选中模型房间"
      }
    </div>
  );
}

export default BimItemViewer;
