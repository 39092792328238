import React from "react";
import { Row, Col, Button, List, Select, InputNumber } from "antd";
import { useState, useEffect } from "react";
import styles from "./ResultList.module.less";
import ConsistencyViewerService from "../ConsistencyViewer.service";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  setSelectedElementIdsBySpaceId,
  setActiveSpaceId,
  selectActiveSpaceId,
} from "../../tuzhi-vis/tuzhiVisSlice";
import {
  setProjectId,
  setElevation,
  selectElevation,
  setBimProperties,
  setSelectedBimId,
} from "../consistencyViewerSlice";

interface ResultListProps {
  projectId: number;
}

function ResultList(props: ResultListProps) {
  const { projectId } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const elevation = useAppSelector(selectElevation);
  const [resultType, setResultType] = useState("all");
  const [pdfId, setPdfId] = useState<number>(0);
  const [filterPdfId, setFilterPdfId] = useState<number>(0);
  const [bimId, setBimId] = useState<number>(0);
  const [filterBimId, setFilterBimId] = useState<number>(0);
  const [type, setType] = useState("all");
  const fetchData = () => {
    // 这里可以编写获取数据的逻辑
    ConsistencyViewerService.getConsistencyResult(projectId).then(
      (res: any) => {
        setData(res.data);
      }
    );
  };
  useEffect(() => {
    ConsistencyViewerService.getConsistencyResult(projectId).then(
      (res: any) => {
        setData(res.data);
      }
    );
    const intervalId = setInterval(() => {
      fetchData(); // 每隔4秒请求一次数据
    }, 4000);

    return () => {
      clearInterval(intervalId); // 在组件卸载时清除定时器
    };
  }, []);

  const getResultByConsistency = (consistency: string) => {
    if (consistency == "Consistent") {
      return "空间一致";
    } else if (consistency == "HasCandidateInconsistent") {
      return "空间不匹配";
    } else {
      return "空间不匹配";
    }
  };
  const getResultColorByConsistency = (consistency: string) => {
    if (consistency == "Consistent") {
      return "green";
    } else {
      return "red";
    }
  };
  const getNameConsistency = (nameConsistency: string) => {
    if (nameConsistency == "Consistent") {
      return "功能名一致";
    } else if (nameConsistency == "SameShort") {
      return "房名差异过大";
    } else if (nameConsistency == "PdfUnknown") {
      return "图上房名未知";
    } else if (nameConsistency == "BimUnknown") {
      return "模上房名未知";
    } else {
      return "无匹配";
    }
  };
  const showResultType = (itemType: string, resultType: string) => {
    if (resultType == "all") {
      return true;
    } else {
      return itemType == resultType;
    }
  };
  const showType = (consistency: string, type: string) => {
    if (type == "all") {
      return true;
    } else if(type == "consistent"){
      return consistency == "Consistent";
    }
    else{
      return consistency != "Consistent";
    }
  };
  const showFilterPdfId = (itemPdfId: string, filterPdfId: number) => {
    if (filterPdfId == 0) {
      return true;
    } else {
      return filterPdfId.toString() == itemPdfId;
    }
  };
  const showFilterBimId = (itemBimId: string, filterBimId: number) => {
    if (filterBimId == 0) {
      return true;
    } else {
      return filterBimId.toString() == itemBimId;
    }
  };
  // 获取当前空间id
  const activeSpaceId = useAppSelector(selectActiveSpaceId);
  return (
    <div className={styles.root}>
      <Row className={styles.filterBox}>
        <Col span={5}>
          结果:
          <Select
            defaultValue="all"
            style={{ width: 100, marginLeft: "10px" }}
            onChange={(value) => {
              setResultType(value);
            }}
            options={[
              {
                value: "all",
                label: "全部",
              },
              {
                value: "roomSpace",
                label: "空间比对",
              },
              {
                value: "name",
                label: "功能名比对",
              },
            ]}
          />
        </Col>
        <Col span={5}>
          比对类型:
          <Select
            defaultValue="all"
            style={{ width: 100, marginLeft: "10px" }}
            onChange={(value) => {
              setType(value);
            }}
            options={[
              {
                value: "all",
                label: "全部",
              },
              {
                value: "inconsistent",
                label: "仅不一致",
              },
              {
                value: "consistent",
                label: "仅一致",
              },
            ]}
          />
        </Col>
        <Col span={5}>
          图上ID:
          <InputNumber
            min={0}
            max={100000000}
            size="small"
            value={pdfId}
            style={{ marginLeft: "10px", width: "80px"}}
            onChange={(value) => setPdfId(value ?? 0)}
          />
        </Col>
        <Col span={5}>
          模上ID:
          <InputNumber
            min={0}
            max={100000000}
            size="small"
            value={bimId}
            style={{ marginLeft: "10px", width: "80px" }}
            onChange={(value) => setBimId(value ?? 0)}
          />
        </Col>
        <Col span={4}>
          <Button
            size="small"
            className="storey-check-button"
            onClick={() => {
              setFilterPdfId(pdfId);
              setFilterBimId(bimId);
            }}
          >ID查找</Button>
          <Button
            size="small"
            className="storey-check-button"
            onClick={() => {
              setFilterBimId(0);
              setFilterPdfId(0);
            }}
          >清除ID筛选</Button>
        </Col>
      </Row>
      <div className={styles.listWrapper}>
        {data.filter((item: any) => item.elevation === elevation?.toString())
          .length === 0 ? (
          <div style={{ textAlign: "center" }}>{elevation}层无比对结果</div>
        ) : (
          <List
            itemLayout="horizontal"
            className={styles.storeyList}
            dataSource={data}
            renderItem={(item: any, index) =>
              item.elevation === elevation?.toString() &&
              showResultType(item.resultType, resultType) &&
              showType(item.consistency, type) &&
              showFilterPdfId(item.bimRoomId,filterBimId) &&
              showFilterPdfId(item.pdfRoomId,filterPdfId) ? (
                <List.Item className={styles.storeyItem}>
                  <div style={{ paddingLeft: "8px" }}>id:{item.resultId}</div>
                  <div
                    style={{
                      color: getResultColorByConsistency(item.consistency),
                    }}
                  >
                    {item.resultType == "roomSpace"
                      ? getResultByConsistency(item.consistency)
                      : getNameConsistency(item.consistency)}
                  </div>
                  <div>
                    {item.resultType == "roomSpace" ? "空间比对" : "功能名比对"}
                  </div>
                  <div>{item.elevation}层</div>
                  <div>pdfId:{item.pdfRoomId}</div>
                  <Button
                    size="small"
                    className="storey-check-button"
                    onClick={() => {
                      if (item.pdfRoomId === null) {
                        return;
                      }
                      const spaceId = activeSpaceId || 0; // Provide a default value for spaceId
                      dispatch(
                        setSelectedElementIdsBySpaceId({
                          spaceId: spaceId,
                          elementIds: [parseInt(item.pdfRoomId, 10)],
                        })
                      );
                    }}
                  >
                    定位
                  </Button>
                  <div>bimId:{item.bimRoomId}</div>
                  <Button size="small" className="storey-check-button" onClick={
                    () => {
                      if (item.bimRoomId === null) {
                        return;
                      }
                      const property:any = {};
                      property["element_name"] = item.bimEN;
                      // 创建一个Object，将选中的空间id存入其中
                      dispatch(setBimProperties([property]));
                      dispatch(setSelectedBimId(parseInt(item.bimRoomId, 10)));
                    }
                  }>
                    定位
                  </Button>
                  <div style={{ paddingRight: "8px" }}>
                    {"相似度:" +
                      (item.consistency !== "NoCandidate" &&
                      item.resultType == "roomSpace"
                        ? item.score
                        : "无")}
                  </div>
                </List.Item>
              ) : null
            }
          />
        )}
      </div>
    </div>
  );
}

export default ResultList;
