import React from 'react';
import hljs from 'highlight.js';
// import 'highlight.js/styles/monokai.css';
import './SNLHighlighter.module.less';

hljs.registerLanguage('snl', function (hljs) {
  return {
    keywords: {
      $pattern: /\S+/,
      keyword:
        '如果 if 那么 then 所有 all every 不存在 no 存在一个 there-is-a ' +
        '且 and 并且 而且 aand 或 or 或者 oor 不 not 只 only 是 is-a 的 its ' +
        '多于 more-than 少于 less-than 不多于 no-more-than 不少于 no-less-than ' +
        '数值属性 data-property 实体关系 object-property 满足 有 处于',
    },
    contains: [
      {
        scope: 'string',
        variants: [
          {
            begin: /(["'“‘”’])/, end: /["'”’“‘]/,
            contains: [hljs.BACKSLASH_ESCAPE],
          },
        ],
      },
      {
        scope: 'computational',
        variants: [
          {
            match: /between|\S{4}于|above|notAbove|不?开向|不?连接|不?穿越|\S?紧邻|\S*边界\S*距离\S|内部有|内部无/,
          },
        ],
      },
      {
        scope: 'compare',
        variants: [
          {
            match: /equals|notequals|contains|notcontains|regex|=|!=|>|<|>=|<=/,
          },
        ],
      },
      {
        scope: 'number',
        variants: [
          {
            begin: '\\b-?\\d+(\\.\\d+)?',
            relevance: 0,
          },
        ],
      },
      {
        scope: 'variable',
        variants: [
          {
            begin: /(?<=((如果|并且|那么|有|是|满足|不少于\s+\d+|between|\S{4}于|above|notAbove|不?开向|不?连接|不?穿越|\S?紧邻|\S*边界\S*距离\S|内部有|内部无|equals|notequals|contains|notcontains|regex)\s+|=|!=|>|<|>=|<=))/, end: /(?=\s)/,
          },
        ],
      },
      {
        scope: 'property',
        variants: [
          {
            begin: /(?<=的\s+)/, end: /(?=\s)/,
            contains: [],
          },
        ],
      },
      {
        scope: 'pronoun',
        variants: [
          {
            match: /[A-Z]/,
          },
        ],
      },
    ],
  };
});

interface SNLHighlighterProps {
  content: string;
}

export default function SNLHighlighter({content,}: SNLHighlighterProps) {
  const highlighted = hljs.highlight('snl', content.replace(/\r/g, ''));

  return (
    <pre className="hljs" style={{
      padding: 10,
      marginBottom: 0,
    }}>
      <code dangerouslySetInnerHTML={{ __html: highlighted.value }} />
    </pre>
  );
}