import axios, { AxiosInstance } from 'axios';
import { API_BASE_URL } from '../../../config';
import { store } from '../../../app/store';

class ModelCheckerService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}/check`,
      headers: headers,
    });

    this.client.interceptors.response.use((response) => {
      console.log('inter');
      const data = response.data;
      if (data.createTime) {
        data.createTime = new Date(data.date);
      }
      if (data.finishTime) {
        data.finishTime = new Date(data.date);
      }
      return response;
    }, (error) => {
      console.log('23333???');
      return Promise.reject(error);
    });

    return this.client;
  }

  /**
   * 提交普通SNL文件审查
   * @param spaceId 空间id
   * @param name 审查命名
   * @param snlFile snl文件对象
   * @param rules 选择条款编号列表
   * @param useFilter 是否使用条文关联过滤器
   */
  async submitCheck(spaceId: number, name: string, snlFile: File, rules: string[], useFilter: boolean, relateSnlStr: string | null) {
    const bodyFormData = new FormData();
    bodyFormData.append('spaceId', spaceId.toString());
    bodyFormData.append('name', name);
    bodyFormData.append('snlFile', snlFile);
    bodyFormData.append('rules', rules.join(';'));
    bodyFormData.append('useFilter', useFilter.toString());
    if(relateSnlStr != null) bodyFormData.append('relateSnlStr', relateSnlStr);
    return this.init().post('/', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  /**
   * 提交预设审查
   * @param spaceId 空间id
   * @param name 审查命名
   * @param presetName 预设审查名称
   * @param presetTagStr 预设审查标签
   * @param useFilter
   */
  async submitPresetCheck(spaceId: number, name: string, presetName: string, presetTagStr: string, useFilter: boolean, relateSnlStr: string | null) {
    if (relateSnlStr != null) {
      return this.init().post(`/presetCheck`, {
        spaceId, name, presetName, presetTags: presetTagStr, useFilter, relateSnlStr
      });
    }
    return this.init().post(`/presetCheck`, {
      spaceId, name, presetName, presetTags: presetTagStr, useFilter, relateSnlStr: "Use default config"
    });
  }

  /**
   * 获取项目审查列表
   * @param projectId
   */
  async getChecksByProjectId(projectId: number) {
    return this.init().get('/check', {
      params: {
        projectId
      },
    });
  }

  /**
   * 获取审查结果报告
   * @param checkId 审查ID
   */
  async getCheckResult(checkId: string) {
    return this.init().get(`/${checkId}`);
  }

  /**
   * 获取审查结果报告PDF文件
   * @param checkId 审查ID
   */
  async getCheckResultPDF(checkId: string) {
    return this.init().get(`/${checkId}/pdf`, {
      responseType: 'blob',
    });
  }

  /**
   * 获取审查BCJSON数据
   * @param checkId 审查ID
   */
  async getCheckBCJSON(checkId: string) {
    return this.init().get(`/${checkId}/model`);
  }

  /**
   * 获取审查结果报告相关的构件
   * @param checkId 审查ID
   */
  async getCheckElements(checkId: string) {
    return this.init().get(`/${checkId}/elements`);
  }

  /**
   * 获取审查结果报告相关的几何数据
   * @param checkId 审查ID
   */
  async getCheckGeoms(checkId: string) {
    return this.init().get(`/${checkId}/geoms`);
  }

  /**
   * 提交到清大东方平台
   * @param checkId 审查ID
   */
  async submitToQingDa(checkId: string) {
    return this.init().get(`/${checkId}/submitToQingDa`);
  }
}

export default new ModelCheckerService();
