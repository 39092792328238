import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import styles from './SummaryViewer.module.less';
import SummaryViewerService from './SummaryViewer.service';

interface SummaryViewerProps {
  projectId: number;
}

function SummaryViewer(props: SummaryViewerProps) {
  const {projectId} = props;

  const [data, setData] = useState(null);
  useEffect(() => {
    SummaryViewerService
    .getProjectDetail(projectId)
    .then(res => {
      setData(res.data);
    })
  }, []);
  const [searchInput, setSearchInput] = useState('');
  const [buildingFound, setBuildingFound] = useState(false);
  const handleSearch = () => {
    const buildingElements = document.querySelectorAll(`.${styles.bigFont}`);
    buildingElements.forEach((element, index) => {
        if (searchInput != "" && element.textContent?.includes(searchInput)) {
            setBuildingFound(true);
            element.scrollIntoView();
        }
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.inputArea}>
            <label htmlFor="searchInput">建筑名：</label>
            <input
                type="text"
                id="searchInput"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <Button onClick={handleSearch} className={styles.btnLarge}>搜索</Button>
            <div>
                {!buildingFound && searchInput && <div>未找到匹配建筑</div>}
            </div>
        </div>
        <div>
            {parseDetail(data)}
        </div>
    </div>
  );
}

function parseDetail(data: any){
    if (data == null){
        return (
            <div>
                <div className={styles.bigFont}>项目信息正在获取</div>
            </div>
        );
    }
    const project_detail = data["detail"];
    const project_elevation_detail = project_detail["project_elevation_detail"];
    const project_elevation_total_detail = project_detail["project_elevation_total_detail"];
    let building_count = 0;
    if (project_detail["project_elevation_total_detail"] != null){
        building_count = project_elevation_detail.length;
    }
    return (
    <div>
        <div className={styles.bigFont}>项目总指标</div>
        <div className={styles.smallFont} style={{ textIndent: '2em' }}>
        该项目共有{building_count}个建筑,建筑名分别为{get_building_names(project_elevation_detail)}
        </div>
        {parseBigClassesDetail(project_detail["project_elevation_total_detail"])}
        {project_elevation_detail.map((e:any)=>parseBuildingDetail(e))}
    </div>
    );
}
function parseBuildingDetail(building_detail:any){
    return (
    <div key={`building-${building_detail["building_name"]}`}>
        <div className={styles.bigFont}>建筑：{building_detail["building_name"]}</div>
        <div className={styles.middleFont}>建筑总信息</div>
        {parseBigClassesDetail(building_detail["building_elevation_total_detail"])}
        {building_detail["building_elevation_detail"].map((e:any)=>parseElevationDetail(e))}
    </div>
    );
}

function parseElevationDetail(elevation_detail:any){
    const elevation = elevation_detail["elevation"];
    return (
    <div key={`elevation-${elevation}`}>
        <div className={styles.middleFont}>{elevation}层信息</div>
        {parseBigClassesDetail(elevation_detail["elevation_detail"])}
    </div>
    );
}

function parseBigClassesDetail(item_dict: any){
    const keyValueDivs = [];

    for (const key in item_dict) {
        const value = item_dict[key];
        keyValueDivs.push(
            <div key={`bigClass-${key}`}>
                <div className={styles.middleFont}>● {key}信息</div>
                <div className={styles.smallFont} style={{ textIndent: '1em' }}>{parseBigClassDetail(key, value)}</div>
            </div>
        );
    }

    return (
        <div>
            {keyValueDivs}
        </div>
    );
}

function parseBigClassDetail(key: string, value: any){
    const key_list = ['个数', '面积', '前室短边长度', '业态'];
    if(value_in_list(key, ['前室', '楼梯间', '电梯间', '窗','门','房间'])){
        const classnames = [];
        const numbers = [];
        // 这些类别信息全部是含个数的，每6个返回一个2行6列的表格，第一行为类别名，第二行为具体个数Number
        for (const class_key in value) {
            if(class_key == '个数'){
                classnames.push(key);
                numbers.push(value[class_key].toString());
                continue;
            }
            if (value_in_list(class_key, key_list)){
                continue;
            }
            const small_class_dict = value[class_key];
            for (const new_key in small_class_dict) {
                if (new_key == '个数'){
                    classnames.push(class_key);
                    numbers.push(String(small_class_dict["个数"]));
                }
            }
        }
        return generateTables(classnames, numbers);
    }
    let result = "个数" in value?(value['个数'] + "个" + key + "。"):"";
    for (const class_key in value) {
        if (!value_in_list(class_key, key_list)){
            continue;
        }
        if (class_key == '个数'){
            continue;
        }
        if (class_key == '前室短边长度'){
            result += class_key + "分别为[" + value[class_key].join("，") + "]。";
            continue;
        }
        if (class_key == '业态'){
            result += "业态统计结果：[" + totalize_bussiness_format(value[class_key]) + "]。";
            continue;
        }
        result += parseClassDetail(class_key, value[class_key]);
    }
    for (const class_key in value) {
        if (value_in_list(class_key, key_list)){
            continue;
        }
        result += parseClassDetail(class_key, value[class_key]);
    }
    return result;
}

function generateTables(classnames: Array<string>, numbers: Array<string>) {
  const tables = [];
  for (let i = 0; i < classnames.length; i += 6) {
    const classNamesSlice = classnames.slice(i, i + 6);
    const numbersSlice = numbers.slice(i, i + 6);
    const table = (
      <table key={`table-${i}`} style={{ width:'100%', margin: 'auto', borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            {classNamesSlice.map((className, index) => (
              <td key={`class-${index}`} style={{ border: '1px solid black', padding: '5px', width: '100px', textAlign: 'center' }}>{className}</td>
            ))}
            {classNamesSlice.length < 6 && new Array(6 - classNamesSlice.length).fill(null).map((_, index) => <td key={`empty-class-${index}`} style={{ width: `${(100 / 6)}%` }}></td>)}
          </tr>
          <tr>
            {numbersSlice.map((number, index) => (
              <td key={`number-${index}`} style={{ border: '1px solid black', padding: '5px', width: '100px', textAlign: 'center' }}>{number}</td>
            ))}
            {numbersSlice.length < 6 && new Array(6 - numbersSlice.length).fill(null).map((_, index) => <td key={`empty-number-${index}`} style={{ width: `${(100 / 6)}%` }}></td>)}
          </tr>
        </tbody>
      </table>
    );
    tables.push(table);
    tables.push(<br key={`break-${i}`} />);
  }
  return <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>{tables}</div>;
}



function parseClassDetail(className: string, indicator_dict: any){
    //如果className是'个数'、'面积'则忽略
    if (className == '个数' || className == '面积'){
        return "";
    }
    let res = "个数" in indicator_dict?(indicator_dict['个数'] + "个" + className + "。"):""
    for (const [key, value] of Object.entries(indicator_dict)) {
        if (key == '个数'){
            continue;
        }
        if (Array.isArray(value)){
            res += key + "分别为[" + value.join("，") + "]。";
        }
        else{
            res += key + "为" + value + "。";
        }
    }
    return res;
}

function totalize_bussiness_format(busses: Array<string>){
    // 统计每种业态的个数，最后返回字符串，由"，"分隔，每一块为"<业态名><业态数量>个"
    const bussiness_format_dict : { [key: string]: number } = {};
    for (const buss of busses){
        if (buss in bussiness_format_dict){
            bussiness_format_dict[buss] += 1;
        }
        else{
            bussiness_format_dict[buss] = 1;
        }
    }
    let res = "";
    for (const key in bussiness_format_dict){
        res += key + bussiness_format_dict[key] + "个，";
    }
    return res.slice(0, -1);
}

function get_building_names(project_elevation_detail: any){
    //返回，分隔的字符串
    return project_elevation_detail.map((e: any) => e["building_name"]).join("，");
}

function value_in_list(value: string, list:Array<string>){
    for (const item of list){
        if (value == item){
            return true;
        }
    }
    return false;
}

export default SummaryViewer;