import React from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const menuItems = [
  {
    key: 'home',
    label: (
      <Link to="/">
        平台介绍
      </Link>
    )
  },
  {
    key: 'projects',
    label: (
      <Link to="/projects">
        项目列表
      </Link>
    )
  },
  {
    key: 'snl',
    label: (
      <Link to="/snl">
        在线SNL
      </Link>
    )
  },
];

export default function LeftMenu() {
  const { md } = useBreakpoint();
  return (
    <Menu theme="dark" mode={md ? 'horizontal' : 'inline'} items={menuItems} />
  );
}
