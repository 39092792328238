import React, { useMemo, useState } from 'react';

import styles from './SpacePropertyViewer.module.less';
import { useAppSelector } from '../../../app/hooks';
import { selectActiveSpaceId, selectElements, selectGeomsBySpaceId, selectSpaces } from '../tuzhiVisSlice';
import GeneralPropertyViewer from './GeneralPropertyViewer';
import { ElementPropertyViewer } from './ElementPropertyViewer';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

interface SpacePropertyViewerProps {
  temp: string;
}

function SpacePropertyViewer(props: SpacePropertyViewerProps) {
  const {temp} = props;

  const activeSpaceId = useAppSelector(selectActiveSpaceId);
  const spaces = useAppSelector(selectSpaces);
  const geoms = useAppSelector(state => selectGeomsBySpaceId(state, activeSpaceId));
  const elements = useAppSelector(selectElements);

  const [collapse, setCollapse] = useState(false);

  const space = useMemo(() => {
    return spaces.find(s => s.spaceId === activeSpaceId);
  }, [spaces, activeSpaceId]);

  return (
    <div className={styles.root}>
      {
        activeSpaceId && space && (
          <div>
            <span
              className={styles.elementTitle}
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              {collapse ? <PlusSquareOutlined/> : <MinusSquareOutlined/>}{' '}
              <span className={styles.count}>[当前空间属性]</span>{' '}
              <span>#{space.spaceId} {space.name}</span>
            </span>
            <div
              style={{display: collapse ? 'none' : ''}}
            >
              <GeneralPropertyViewer space={space}/>
            </div>
            {
              geoms.map(g => {
                const e = elements[g.elementId];
                if (!e) {
                  // todo 避免部分没有加载构件的包围盒
                  return null;
                }
                if (e.categoryName === '系统') {
                  return (
                    <ElementPropertyViewer key={g.elementId} space={space} element={e} prefix={'抽象构件属性'}/>
                  );
                }
                return null;
              }).filter(g => g !== null)
            }
          </div>
        )
      }
    </div>
  )
}

export default SpacePropertyViewer;
