import React, { useEffect, useLayoutEffect, useRef } from 'react';
import cytoscape, { EdgeDefinition, NodeDefinition } from 'cytoscape';
import klay from 'cytoscape-klay';

import styles from './PluginDAG.module.less';
import { useResizeDetector } from 'react-resize-detector';
import { useAppSelector } from '../../../app/hooks';
import { selectPlugins } from '../tuzhiVisSlice';

cytoscape.use(klay);

interface PluginDAGProps {
  projectId: number;
}

function PluginDAG(props: PluginDAGProps) {
  const {projectId} = props;

  const plugins = useAppSelector(selectPlugins);

  // cytoscape容器
  const { width, height, ref: containerRef } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 100
  });
  // cytoscape实例
  const cyRef = useRef<cytoscape.Core | null>(null);

  useLayoutEffect(() => {

    const typeSet = new Set(Object.values(plugins).flatMap(p => p.semanticTypes));
    const elements: cytoscape.ElementsDefinition[] = Array.from(typeSet).map((type, index) => {
      const nodes: NodeDefinition[] = [];
      let edges: EdgeDefinition[] = [];
      Object.values(plugins)
        .filter(p => p.semanticTypes.includes(type))
        .forEach(plugin => {
          nodes.push({
            data: {
              id: `plugin-${type}-${plugin.name}`, label:
              plugin.description.replace(/插件/, ''),
            },
          });
          edges = edges.concat(plugin.dependencies.map(dependency => (
            {
              data: {
                id: `edge-${type}-${dependency}-${plugin.name}`, label: '关系',
                source: `plugin-${type}-${dependency}`, target: `plugin-${type}-${plugin.name}`,
              },
            }
          )));
        });
      return {
        nodes, edges,
      }
    });

    // 过滤一遍，如果不存在source，则补充一个虚假source
    elements.forEach(e => {
      const nodeIds = e.nodes.map(n => n.data.id);
      e.edges.forEach(edge => {
        if (!nodeIds.includes(edge.data.source)) {
          e.nodes.push({
            data: {
              id: edge.data.source, label: `${edge.data.source}-(Fake Source)`,
            },
          })
        }
      })
    });

    cyRef.current = cytoscape({
      container: containerRef.current,
      elements: elements.reduce((acc, obj) => {
        acc.nodes = acc.nodes.concat(obj.nodes);
        acc.edges = acc.edges.concat(obj.edges);
        return acc;
      }, { nodes: [], edges: [] }),
      zoom: 0.5,
      layout: {
        // @ts-ignore
        name: 'klay',
        nodeDimensionsIncludeLabels: true,
        klay: {
          direction: 'RIGHT',
          spacing: 5,
          compactComponents: true,
          edgeRouting: 'SPLINES',
          layoutHierarchy: true,
          feedbackEdges: true,
        },
      },
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#2266AA',
            'label': 'data(label)',
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 3,
            'line-color': '#ccc',
            'target-arrow-color': '#bbb',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
          }
        }
      ]
    });


    function blinkNode(nodeId: string) {
      const node = cyRef.current?.getElementById(nodeId);
      if (!node)
        return;
      if (node.animated()) {
        node.stop();
        node.style('background-color', '#2266AA');
        return;
      }

      // 开始动画
      // @ts-ignore
      node.animation({
        style: {'background-color': '#2266AA'},
        duration: 400,
      }).play().promise('complete').then(() => {
        // @ts-ignore
        node.animation({
          style: {'background-color': '#66CCFF'},
          duration: 400,
        }).play().promise('complete').then(() => {
          blinkNode(nodeId);
        });
      })
      // node.flashClass('highlighted', 500);
    }

    // 在点击节点时触发闪烁效果
    cyRef.current.on('click', 'node', (event) => {
      const nodeId = event.target.id();
      blinkNode(nodeId);
    });

    return () => {
      cyRef.current?.destroy();
    };

  }, []);

  useEffect(() => {
    cyRef.current?.resize();
    cyRef.current?.fit();
  }, [width, height]);

  return (
    <div className={styles.root}>
      <div ref={containerRef} className={styles.dag}></div>
    </div>
  );
}

export default PluginDAG;
