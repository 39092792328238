import React, { useEffect, useState } from 'react';

import styles from './AssetViewer.module.less';
import { useAppSelector } from '../../../app/hooks';
import { selectAssets } from '../tuzhiVisSlice';
import UnionViewerService from '../union-viewer/UnionViewer.service';
// import ReactJson from 'react-json-view';
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';
import { encode64 } from './util';

interface AssetViewerProps {
  assetId: number;
}

function AssetViewer(props: AssetViewerProps) {
  const {assetId} = props;

  const assets = useAppSelector(selectAssets);
  const asset = assets[assetId.toString()];

  const [fileBuffer, setFileBuffer] = useState<ArrayBuffer | null>(null);

  useEffect(() => {
    UnionViewerService.getOriginFileArrayBuffer(assetId)
      .then(res => {
        setFileBuffer(res.data);
      });
  }, []);

  if (!asset)
    return (<div>资源无法找到</div>);
  if (!fileBuffer)
    return (<div>资源加载中</div>);

  let viewer;
  switch (asset.extension) {
    case '.png':
    case '.jpg':
      viewer = (
        <div style={{overflow: 'scroll', width: '100%', height: '100%'}}>
          <img src={`data:image/png;base64,${encode64(new Uint8Array(fileBuffer))}`} alt="" style={{width: '100%'}} />
        </div>
      )
      break;
    case '.pdf':
      viewer = (
        <iframe src={`data:application/pdf;base64,${encode64(new Uint8Array(fileBuffer))}`} style={{overflow: 'scroll', width: '100%', height: '100%'}} />
      )
      break;
    case '.json':
      console.log(JSON.parse(JSON.stringify(fileBuffer)));
      viewer = (
        <div
          className={styles.jsonViewer}
        >
          {/*<ReactJson*/}
          {/*  theme='monokai'*/}
          {/*  collapsed={false}*/}
          {/*  src={JSON.parse(new TextDecoder().decode(fileBuffer as ArrayBuffer))}*/}
          {/*/>*/}
          <Editor
            height="100%"
            theme="vs-dark"
            defaultLanguage="JSON"
            defaultValue={JSON.stringify(
              JSON.parse(new TextDecoder().decode(fileBuffer as ArrayBuffer)),
              null, 4
            )}
          />
        </div>
      )
      break;
    default:
      viewer = (
        <div>
          当前资源格式无法预览
        </div>
      )
  }

  return (
    <div className={styles.root}>
      {viewer}
    </div>
  );
}

export default AssetViewer;

