import React, { useEffect, useLayoutEffect, useRef } from 'react';
import cytoscape, { EdgeDefinition, NodeDefinition } from 'cytoscape';
import klay from 'cytoscape-klay';

import styles from './ProjectGraph.module.less';
import { useResizeDetector } from 'react-resize-detector';
import { useAppSelector } from '../../../app/hooks';
import { selectAssets, selectPlugins, selectTasks } from '../tuzhiVisSlice';
import { differenceBy, every, includes, isEqual } from 'lodash';
import { Asset, Task } from '../../../interfaces';

cytoscape.use(klay);

interface ProjectGraphProps {
  projectId: number;
}

function ProjectGraph(props: ProjectGraphProps) {
  const {projectId} = props;

  const plugins = useAppSelector(selectPlugins);
  const assets = useAppSelector(selectAssets);
  const tasks = useAppSelector(selectTasks);

  // cytoscape容器
  const { width, height, ref: containerRef } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 100
  });
  // cytoscape实例
  const cyRef = useRef<cytoscape.Core | null>(null);

  // 缓存上一次的资源和任务列表，用于比较后按需增加节点和边
  const prevAssets = useRef<Asset[]>([]);
  const prevTasks = useRef<Task[]>([]);

  useLayoutEffect(() => {
    cyRef.current = cytoscape({
      container: containerRef.current,
      elements: [],
      zoom: 0.8,
      layout: {
        // @ts-ignore
        name: 'klay',
        nodeDimensionsIncludeLabels: true,
        klay: {
          direction: 'RIGHT',
          spacing: 5,
          compactComponents: true,
          edgeRouting: 'SPLINES',
          layoutHierarchy: true,
          feedbackEdges: true,
        },
      },
      style: [
        {
          selector: 'node',
          style: {
            'background-color': '#aaa',
            'label': 'data(label)',
            // @ts-ignore
            'shape': 'data(shape)',
          }
        },
        {
          selector: 'edge',
          style: {
            'width': 3,
            'line-color': '#ccc',
            'target-arrow-color': '#bbb',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
          }
        }
      ]
    });

    function blinkNode(nodeId: string) {
      const node = cyRef.current?.getElementById(nodeId);
      if (!node)
        return;
      if (node.animated()) {
        node.stop();
        node.style('background-color', '#2266AA');
        return;
      }

      // 开始动画
      // @ts-ignore
      node.animation({
        style: {'background-color': '#2266AA'},
        duration: 400,
      }).play().promise('complete').then(() => {
        // @ts-ignore
        node.animation({
          style: {'background-color': '#66CCFF'},
          duration: 400,
        }).play().promise('complete').then(() => {
          blinkNode(nodeId);
        });
      })
      // node.flashClass('highlighted', 500);
    }

    // 在点击节点时触发闪烁效果
    cyRef.current.on('click', 'node', (event) => {
      const nodeId = event.target.id();
      blinkNode(nodeId);
    });

    return () => {
      cyRef.current?.destroy();
    };

  }, []);

  // 当容器大小变化时，重新布局
  useEffect(() => {
    cyRef.current?.resize();
    cyRef.current?.fit();
  }, [width, height]);

  // 当资源或任务列表变化时，加入新的节点和边
  useEffect(() => {
    // 处理新增资源情况，需考虑新增目录以及新增要素
    // 处理新增任务情况，需考虑新增信息提取和融合情况
    const newAssets = differenceBy(Object.values(assets), prevAssets.current, 'assetId');
    const newTasks = differenceBy(tasks, prevTasks.current, 'taskId');

    // 首先判断任务中的要素是否已经存在，如果不存在，则等待之后更新即可
    const valid = every(tasks, (task) => {
      return every(task.assetIds, (assetId) => {
        return assets[assetId];
      });
    });
    if (!valid)
      return;

    // 完整的图
    const newGraph: cytoscape.ElementsDefinition  = {nodes: [], edges: []};
    // 先按照原始图纸的文件存储结构，构造实际目录树
    const dirNodesMap: {[key: string]: cytoscape.NodeDefinition} = {};
    newAssets.filter(a => a.granularity === 'ORIGIN').forEach(asset => {
      for (let i = 0; i < asset.storeDir.length; i++) {
        const dir = asset.storeDir.slice(0, i + 1);
        const path = dir.join('/');
        if (!dirNodesMap[path]) {
          dirNodesMap[path] = {
            data: {
              id: `dir-node-${path}`,
              label: asset.storeDir[i],
              shape: 'rectangle',
            },
          };
        }
      }
    });
    newGraph.nodes.push(...Object.values(dirNodesMap));
    Object.values(dirNodesMap).forEach(node => {
      if (!node.data.id)
        return;
      const path = node.data.id.substring('dir-node-'.length);
      const lastIndex = path.lastIndexOf('/');
      if (lastIndex == -1)
        return;
      const parent = path.substring(0, lastIndex);
      const self = path.substring(lastIndex + 1);
      newGraph.edges.push({
        data: {
          id: `dir-edge-${parent}-${self}`,
          label: '目录树架构',
          source: `dir-node-${parent}`,
          target: `dir-node-${path}`,
        },
      });
    });

    // 将所有要素资源的图纸加入结构即可
    newAssets
      .filter(a => a.variant === 'ORIGIN')
      .forEach(asset => {
        let parentId;
        if (asset.granularity === 'ORIGIN') {
          parentId = `dir-node-${asset.storeDir.join('/')}`;
        } else {
          parentId = `asset-node-${asset.ancestorIds[asset.ancestorIds.length - 1]}`;
        }
        newGraph.nodes.push({
          data: {
            id: `asset-node-${asset.assetId}`,
            label: asset.name,
            shape: 'rectangle',
          },
        });
        newGraph.edges.push({
            data: {
              id: `asset-edge-${asset.ancestorIds[asset.ancestorIds.length - 1]}-${asset.assetId}`,
              label: 'Asset派发关系',
              source: parentId, target: `asset-node-${asset.assetId}`,
            },
          },
        )
      });
    // 然后根据Task列表构造信息提取插件DAG
    newTasks.forEach(task => {
      // 先排除要素拆分插件，因为已经体现在拆出的要素身上了
      if (task.taskType === 'SEGMENTATION')
        return;
      // 信息提取任务和要素资源一一对应，直接增加边
      newGraph.nodes.push({
        data: {
          id: `extract-node-${task.taskId}`, label: task.pluginName.replace(/Plugin/, ''),
        },
        style: {
          'background-color': task.state === 'SUCCESS' ? 'green' : 'blue',
        }
      });
      // 根据插件配置找到该插件所依赖的插件，增加边即可
      plugins[task.pluginName].dependencies.forEach(dependency => {
        // 找到任务中该插件
        const parentTask = tasks.find(t => t.pluginName === dependency && isEqual(t.assetIds, task.assetIds));
        if (parentTask) {
          newGraph.edges.push({
            data: {
              id: `extract-edge-${dependency}-${task.taskId}`, label: '信息提取插件依赖关系',
              source: `extract-node-${parentTask.taskId}`, target: `extract-node-${task.taskId}`,
            },
          });
        } else {
          console.warn('存在孤儿任务', task, dependency);
        }
      });
      // 如果没有依赖，并且作为入口，那么找到它对应的要素资源，增加边
      if (plugins[task.pluginName].dependencies.length === 0 && task.assetIds.length > 0) {
        const asset = assets[task.assetIds[0]];
        newGraph.edges.push({
          data: {
            id: `extract-edge-${asset.assetId}-${task.taskId}`, label: '信息提取插件依赖关系',
            source: `asset-node-${asset.assetId}`, target: `extract-node-${task.taskId}`,
          },
        });
      }
    });
    // todo 处理融合插件的连线

    // 最后检查一遍，如果不存在source，则补充一个虚假source
    const nodeIds = newGraph.nodes.map(n => n.data.id);
    newGraph.edges.forEach(edge => {
      if (!nodeIds.includes(edge.data.source)) {
        newGraph.nodes.push({
          data: {
            id: edge.data.source, label: `${edge.data.source}-(Fake Source)`,
          },
        });
      }
    });
    // 新增所有节点和边
    cyRef.current?.add(newGraph);
    cyRef.current?.layout({
      name: 'klay',
      // @ts-ignore
      nodeDimensionsIncludeLabels: true,
      animate: true,
      klay: {
        direction: 'RIGHT',
        spacing: 5,
        compactComponents: true,
        edgeRouting: 'SPLINES',
        layoutHierarchy: true,
        feedbackEdges: true,
      },
    }).run();

    // 节点处理完后，还需要更新节点状态，改变节点颜色等
    Object.values(assets).forEach(asset => {
      const node = cyRef.current?.getElementById(`asset-node-${asset.assetId}`);
      if (!node)
        return;
      // todo 节点暂时默认一直是绿色的，实际也只有拆图失败会报错，需额外处理
      node.style('background-color', 'green');
    });
    Object.values(tasks).forEach(task => {
      const node = cyRef.current?.getElementById(`extract-node-${task.taskId}`);
      if (!node)
        return;
      // 'PENDING' | 'PROGRESS' | 'SUCCESS' | 'FAILURE'
      switch (task.state) {
        case 'PENDING':
          node.style('background-color', 'gray');
          break;
        case 'PROGRESS':
          node.style('background-color', 'yellow');
          break;
        case 'SUCCESS':
          node.style('background-color', 'green');
          break;
        case 'FAILURE':
          node.style('background-color', 'red');
          break;
      }
    });

    // 保存之前的状态
    prevAssets.current = Object.values(assets);
    prevTasks.current = Object.values(tasks);
  }, [assets, tasks]);

  return (
    <div className={styles.root}>
      <div ref={containerRef} className={styles.dag}></div>
    </div>
  );
}

export default ProjectGraph;
