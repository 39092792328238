import { Button, Divider, Form, Input, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';

import styles from './SpacePropertyEditorList.module.less';
import { useAppSelector } from '../../../app/hooks';
import { selectSpaces } from '../tuzhiVisSlice';
import SpacePropertyEditor from './SpacePropertyEditor';

interface SpacePropertyEditorListProps {
  projectId: number;
}

function SpacePropertyEditorList(props: SpacePropertyEditorListProps) {
  const {projectId} = props;

  const spaces = useAppSelector(selectSpaces);

  return (
    <div className={styles.root}>
      {
        spaces.map(space => {
          return (
            <SpacePropertyEditor key={space.spaceId.toString()} spaceId={space.spaceId} />
          );
        })
      }
    </div>
  );
}

export default SpacePropertyEditorList;
