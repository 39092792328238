import { Asset, Space, Element, Geom } from '../../interfaces';
import { fetchElementsBySpaceId } from './tuzhiVisSlice';
import UnionViewerService from './union-viewer/UnionViewer.service';

const assetMap = new Map<number, Asset>();
const spaceMap = new Map<number, Space>();
const elementMap = new Map<number, Element>();
const geomMap = new Map<number, Geom>();

export async function fillElementsCache(spaceId: number) {
  const response = await UnionViewerService.getElementsBySpaceId(spaceId);
  response.data.forEach(e => {
    elementMap.set(e.elementId, e);
  })
}


// class TuzhiData {
//   private assets: Asset[];
//   private spaces: Space[];
//   private
//
//   public getAssetById() {
//
//   }
//
//
//
//   public
// }
//
//
// class TuzhiStore {
//
// }


export {assetMap, spaceMap, elementMap, geomMap};