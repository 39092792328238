import { Geometry, Polygon, Position } from 'geojson';
import { Graphics } from 'pixi.js';


Graphics.prototype.drawGeoJSON = function (geom: Geometry) {
  const drawPolygon = (g: Graphics, coord: Position[][]) => {
    const [outerRing, ...innerRings] = coord.map(ring => ring.flatMap(p => p));
    if (outerRing)
      g.drawPolygon(outerRing);
    for (const r of innerRings)
      g.beginHole().drawPolygon(r).endHole();
  };

  switch (geom.type) {
    case 'Polygon': {
      drawPolygon(this, geom.coordinates);
      break;
    }
    case 'MultiPolygon': {
      for (const p of geom.coordinates) {
        drawPolygon(this, p);
      }
      break;
    }
    case 'LineString': {
      break;
    }
    case 'MultiLineString': {
      break;
    }
    default: {
      console.warn('暂不支持的GeoJSON格式', geom);
    }
  }
};

Graphics.prototype.drawDashedGeoJSON = function (geom: Geometry, scale, dash, gap, offset) {
  const drawDashedLine = (g: Graphics, x1: number, y1: number, x2: number, y2: number) => {
    g.drawDashLine(x1, y1, x2, y2, scale, dash, gap, offset);
  }

  const drawDashedPolygon = (g: Graphics, coord: Position[][]) => {
    for (const r of coord) {
      for (let i = 0; i < r.length - 1; i++) {
        drawDashedLine(this, r[i][0], r[i][1], r[i+1][0], r[i+1][1]);
      }
    }
  };

  switch (geom.type) {
    case 'Polygon': {
      drawDashedPolygon(this, geom.coordinates);
      break;
    }
    case 'MultiPolygon': {
      for (const p of geom.coordinates) {
        drawDashedPolygon(this, p);
      }
      break;
    }
    case 'LineString': {
      break;
    }
    case 'MultiLineString': {
      break;
    }
    default: {
      console.warn('暂不支持的GeoJSON格式', geom);
    }
  }
};