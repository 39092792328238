import React, { useMemo } from 'react';

import styles from './LayerController.module.less';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectActiveSpaceId,
  selectAssetLayerStatesBySpaceId,
  selectAssets,
  selectAssetSpacesBySpaceId,
  selectElements,
  selectGeomsBySpaceId,
  selectSemanticLayerStatesBySpaceId,
  updateAssetLayerStates,
  updateSemanticLayerStates,
} from '../tuzhiVisSlice';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { Element } from '../../../interfaces';

const constructTreeData = (states: {[key: string]: boolean}, filteredElements: Element[]) => {
  // 预排序方案
  const defaultCategoryOrder = [];
  const defaultFamilyOrder = [];
  // 将图层状态按照FN、CN层次构造树，同时提供预排序方案
  const tree: DataNode[] = [];
  for (const [familyPath, visibility] of Object.entries(states)) {
    const path = familyPath.split('###');
    let node = tree.find(n => n.key === path[0]);
    if (!node) {
      node = {
        title: path[0],
        key: path[0],
        children: [],
      };
      tree.push(node);
    }
    if (!node.children)
      node.children = [];
    node.children.push({
      title:`${path[1]} (${filteredElements.filter(e => e.categoryName === path[0] && e.familyName === path[1]).length}个)`,
      key: familyPath,
      children: [],
    })
  }
  return tree;
};

function LayerController() {
  const dispatch = useAppDispatch();
  const activeSpaceId = useAppSelector(selectActiveSpaceId);
  const assets = useAppSelector(selectAssets);
  const elements = useAppSelector(selectElements);
  const assetSpaces = useAppSelector(state => selectAssetSpacesBySpaceId(state, activeSpaceId));
  const semanticLayerStates = useAppSelector(state => selectSemanticLayerStatesBySpaceId(state, activeSpaceId));
  const assetLayerStates = useAppSelector(state => selectAssetLayerStatesBySpaceId(state, activeSpaceId));
  const geoms = useAppSelector(state => selectGeomsBySpaceId(state, activeSpaceId));

  const filteredElements = useMemo(() => {
    return Object.values(elements).filter(e => geoms.some(geom => e.geoms?.includes(geom.geomId)));
  }, [activeSpaceId]);

  const constructAssetTreeData = (assetIds: string[]) => {
    const tree: DataNode[] = [];

    for (const assetId of assetIds) {
      if (!assetId)
        continue;
      const asset = assets[assetId];
      if (asset) {
        tree.push({
          title: asset.storeDir[2].split('.')[0],
          key: asset.assetId.toString(),
          children: [],
        });
      }
    }
    return tree;
  };

  return (
    <div className={styles.root}>
      <div>
        语义图层
      </div>
      <Tree
        checkable
        defaultExpandAll
        selectable={false}
        onCheck={(keys, e) => {
          if (activeSpaceId) {
            dispatch(updateSemanticLayerStates({spaceId: activeSpaceId, checked: (keys as string[]).filter(k => k.match(/^(.*)###(.*)$/))}));
          }
        }}
        checkedKeys={Object.entries(semanticLayerStates).filter(([k, v]) => v).map(([k, v]) => k)}
        treeData={constructTreeData(semanticLayerStates, filteredElements)}
      />
      <div>
        PDF图层,按文件分级组织树,暂无
      </div>
      <Tree
        checkable
        defaultExpandAll
        selectable={false}
        onCheck={(keys, e) => {
          if (activeSpaceId) {
            //@ts-ignored
            dispatch(updateAssetLayerStates({spaceId: activeSpaceId, assetStates: Object.fromEntries(keys.map(k => [k, true]))}));
          }
        }}
        checkedKeys={Object.entries(assetLayerStates).filter(([k, v]) => v.show).map(([k, v]) => k)}
        treeData={constructAssetTreeData(Object.keys(assetLayerStates))}
      />
    </div>
  );
}

export default LayerController;
