import React, { useEffect, useState } from 'react';

import styles from './ElementPropertyViewer.module.less';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectActiveSpaceId,
  selectElements,
  selectSelectedElementsBySpaceId,
  selectSpaces,
  setHoveredElementIds, setSelectedElementIdsBySpaceId,
} from '../tuzhiVisSlice';
import { Element, ElementRelation, Space } from '../../../interfaces';
import UnionViewerService from '../union-viewer/UnionViewer.service';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import GeneralPropertyViewer from './GeneralPropertyViewer';
import { Input } from 'antd';

type FromToRelations = {
  from: ElementRelation[],
  to: ElementRelation[],
}

type GroupedRelations = {
  [key: string]: FromToRelations;
}

function groupRelationsByPrefix(element: Element, relations: ElementRelation[]): GroupedRelations {
  const groupedRelations: GroupedRelations = {};
  for (const rel of relations) {
    if (!groupedRelations[rel.relType]) {
      groupedRelations[rel.relType] = {
        from: [],
        to: [],
      };
    }
    if (element.elementId === rel.fromId) {
      groupedRelations[rel.relType].from.push(rel);
    }
    if (element.elementId === rel.toId) {
      groupedRelations[rel.relType].to.push(rel);
    }
  }
  return groupedRelations;
}


interface RelationRootProps {
  element: Element;
  relations: ElementRelation[];
}

function RelationRoot(props: RelationRootProps) {
  const {element, relations} = props;
  const [collapse, setCollapse] = useState(false);

  return (
    <div className={styles.relationRoot}>
      <div className={styles.title}>
        <span
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {collapse ? <PlusSquareOutlined /> : <MinusSquareOutlined />}{' '}
          构件关系
        </span>
      </div>
      <div className={styles.propertySet} style={{display: collapse ? 'none' : ''}}>
        {
          Object.entries(groupRelationsByPrefix(element, relations)).map(([key, relations]) => {
            return <RelationNode key={key} relationName={key} relations={relations} />;
          })
        }
      </div>
    </div>
  );
}


interface RelationNodeProps {
  relationName: string;
  relations: FromToRelations;
}

function RelationNode(props: RelationNodeProps) {
  const {relationName, relations} = props;

  const dispatch = useAppDispatch();
  const elements = useAppSelector(selectElements);

  const [collapse, setCollapse] = useState(false);

  return (
    <div className={styles.relationNode}>
      <div className={styles.title}>
        <span
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {collapse ? <PlusSquareOutlined/> : <MinusSquareOutlined/>}
          <span>{relationName}</span>
          <span className={styles.count}>{relations.from.length+relations.to.length}个</span>
        </span>
      </div>
      <div className={styles.fromToRelations} style={{display: collapse ? 'none' : ''}}>
        <div>正向关系 {relations.from.length > 0 ? `${relations.from.length}个`: '无'}</div>
        {
          relations.from.map((er) => {
            return (
              <div
                key={er.relId} className={styles.relationElement}
                onMouseEnter={() => {
                  dispatch(setHoveredElementIds([er.toId]));
                }}
                onMouseOut={() => {
                  // dispatch(setHoveredElementIds([]));
                }}
              >
                <div className={styles.id}>{er.toId} {elements?.[er.toId]?.familyName}</div>
                <div className={styles.name}>{elements?.[er.toId]?.elementName || '<unknown>'}</div>
              </div>
            );
          })
        }
        <div>反向关系 {relations.to.length > 0 ? `${relations.to.length}个`: '无'}</div>
        {
          relations.to.map((er) => {
            return (
              <div
                key={er.relId} className={styles.relationElement}
                onMouseEnter={() => {
                  dispatch(setHoveredElementIds([er.fromId]));
                }}
                onMouseOut={() => {
                  // dispatch(setHoveredElementIds([]));
                }}
              >
                <div className={styles.id}>{er.fromId} {elements?.[er.fromId]?.familyName}</div>
                <div className={styles.name}>{elements?.[er.fromId]?.elementName || '<unknown>'}</div>
              </div>
            );
          })
        }

      </div>
    </div>
  );
}

interface ElementPropertyViewerProps {
  space: Space;
  element: Element;
  prefix?: string;
}

function ElementPropertyViewer(props: ElementPropertyViewerProps) {
  const {space, element, prefix} = props;
  const [collapse, setCollapse] = useState(false);

  const [elementRelations, setElementRelations] = useState<ElementRelation[]>([]);

  // 更新构件关系数据
  useEffect(() => {
    UnionViewerService.getElementRelations(element.elementId)
      .then(res => {
        setElementRelations(res.data);
      });
  }, [element]);

  return (
    <div key={element.elementId}>
      <span
        className={styles.elementTitle}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        {collapse ? <PlusSquareOutlined/> : <MinusSquareOutlined/>}{' '}
        <span className={styles.count}>{prefix ? `[${prefix}] `: ''}#{element.elementId}</span>{' '}
        <span>{element.elementName}</span>
      </span>
      <div
        style={{display: collapse ? 'none' : ''}}
      >
        <GeneralPropertyViewer element={element} space={space}/>
        {
          <RelationRoot element={element} relations={elementRelations}/>
        }
      </div>
    </div>
  );
}


interface ActiveElementPropertyViewerProps {
  temp: string;
}

function ActiveElementPropertyViewer(props: ActiveElementPropertyViewerProps) {
  const {temp} = props;

  const dispatch = useAppDispatch();
  const elements = useAppSelector(selectElements);
  const spaces = useAppSelector(selectSpaces);
  const activeSpaceId = useAppSelector(selectActiveSpaceId);
  const selectedElementIds = useAppSelector(state => selectSelectedElementsBySpaceId(state, activeSpaceId));

  const space = spaces.find(s => s.spaceId === activeSpaceId);

  return (
    <div className={styles.root}>
      {
        activeSpaceId &&
        <Input.Search
          placeholder="搜索"
          onSearch={(v) => {dispatch(setSelectedElementIdsBySpaceId({spaceId: activeSpaceId, elementIds: [Number(v)]}))}}
          defaultValue={selectedElementIds[0]}
          style={{ width: '100%' }}
        />
      }
      {
        activeSpaceId && space && selectedElementIds.map((elementId, i) => {
          return (
            <ElementPropertyViewer key={i} space={space} element={elements[elementId]} />
          );
        })
      }
    </div>
  );
}

export {
  ElementPropertyViewer,
  ActiveElementPropertyViewer,
};
