import React, { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import styles from './UnionViewer2D.module.less';
import SemanticLayer from './semantic-layer/SemanticLayer';
import PDFLayer from './pdf-layer/PDFLayer';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  fetchElementsBySpaceId,
  fetchGeomsBySpaceId, selectAssetLayerStatesBySpaceId,
  selectAssetSpacesBySpaceId,
  selectViewportBySpaceId,
  setAssetSpacesBySpaceId, updateAssetLayerStates,
} from '../tuzhiVisSlice';
import UnionViewerService from './UnionViewer.service';
import { SpaceRelation } from '../../../interfaces';
import { Point } from 'geojson';
import { Radio } from 'antd';
import { screenToReal } from './utils/transformUtils';
import IFCViewer from './ifc-viewer/IFCViewer';
import { fillElementsCache } from '../tuzhiVisCache';
import { useIsFirstRender } from '../../../utils/useHooks';
import ModifierLayer from './modifier-layer/ModifierLayer';

interface UnionViewer2DProps {
  spaceId: number;
  minZoomLevel?: number;
  maxZoomLevel?: number;
  delta?: number;
}

function UnionViewer2D(props: UnionViewer2DProps) {
  // 一个绘制层、一个渲染交互层、一个pdf层、一个cad层、一个png层
  const {spaceId, minZoomLevel = 1e-10, maxZoomLevel = 1e10, delta = 0.02} = props; // todo 缩放参数控制

  const dispatch = useAppDispatch();
  const initViewport = useAppSelector(state => selectViewportBySpaceId(state, spaceId));
  const assetSpaces = useAppSelector(state => selectAssetSpacesBySpaceId(state, spaceId));
  const assetLayerStates = useAppSelector(state => selectAssetLayerStatesBySpaceId(state, spaceId));

  const isFirstRender = useIsFirstRender();

  const { width, height, ref: wrapperRef } = useResizeDetector<HTMLDivElement>({
    refreshMode: 'throttle',
    refreshRate: 500
  });

  // 当前融合空间涉及的资源空间列表等数据
  const [spaceRelationList, setSpaceRelationList] = useState<SpaceRelation[]>([]);
  const [offsetList, setOffsetList] = useState<Point[]>([]);
  // 当前光标位置，可用于判断选择、渲染高亮等
  const [cursorLocation, setCursorLocation] = useState([0, 0]);
  // 变换矩阵是现实毫米坐标到屏幕坐标的变换
  const [matrix, setMatrix] = useState<number[]>([1, 0, 0, -1, 0, 500]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  // 子组件加载渲染总数，以及是否完全加载渲染完毕指示
  const [subRenderCount, setSubRenderCount] = useState(0);
  const [subRendered, setSubRendered] = useState(false);
  // 当前23D选项
  const [dimension, setDimension] = useState('2D');

  // 获取当前可视化空间基本数据，包含几何、构件等数据，todo 底图
  useEffect(() => {
    dispatch(fetchGeomsBySpaceId(spaceId));
    dispatch(fetchElementsBySpaceId(spaceId));
    fillElementsCache(spaceId);
  }, []);

  useEffect(() => {
    // todo
    setTimeout(() => {
      setMatrix([...matrix.slice(0, 6), height || 0]);
    }, 160);
  }, []);

  useEffect(() => {
    console.log('视口变化', spaceId, width, height);
    // todo
    setSubRenderCount(0);
  }, [width, height]);

  // 完全渲染完毕
  useEffect(() => {
    // 当前浏览器包括数个PDF图层加一个语义图层，未来按需更新
    if (subRenderCount === assetSpaces.length + 1) {
      setSubRendered(true);
    }
  }, [subRenderCount]);

  const mouseDownListener = () => {
    setIsMouseDown(true);
  };

  const mouseUpListener = () => {
    setIsMouseDown(false);
  };

  const mouseMoveListener = (e: MouseEvent) => {
    if (isMouseDown) {
      setMatrix((prev) => {
        return [prev[0], 0, 0, prev[3], prev[4] + e.movementX, prev[5] + e.movementY]
      });
    }
    if (e.currentTarget && e.currentTarget instanceof Element) {
      const bound = e.currentTarget.getBoundingClientRect();
      setCursorLocation([e.clientX - bound.x, e.clientY - bound.y]);
    }
  };

  const mouseWheelListener = (e: WheelEvent) => {
    e.preventDefault();
    const ratio =  Math.sign(e.deltaY) > 0 ? 0.8 : 1.2;
    if (e.currentTarget && e.currentTarget instanceof Element) {
      const {top, left} = e.currentTarget.getBoundingClientRect();
      setMatrix((prev) => {
        const originX = e.pageX - left;
        const originY = e.pageY - top;
        const newScaleX = prev[0] * ratio;
        const newScaleY = prev[3] * ratio;
        return [newScaleX, 0, 0, newScaleY, originX - (originX - prev[4]) * ratio, originY - (originY - prev[5]) * ratio]
      });
    }
  }

  useEffect(() => {
    // 只托管2D的坐标系数据，3D的放行
    if (wrapperRef.current && dimension === '2D') {
      wrapperRef.current.addEventListener('mousedown', mouseDownListener, false);
      wrapperRef.current.addEventListener('mouseup', mouseUpListener, false);
      wrapperRef.current.addEventListener('mousemove', mouseMoveListener, false);
      wrapperRef.current.addEventListener('wheel', mouseWheelListener, false);
      return () => {
        console.log('注销鼠标事件');
        if (wrapperRef.current) {
          wrapperRef.current.removeEventListener('mousedown', mouseDownListener, false);
          wrapperRef.current.removeEventListener('mouseup', mouseUpListener, false);
          wrapperRef.current.removeEventListener('mousemove', mouseMoveListener, false);
          wrapperRef.current.removeEventListener('wheel', mouseWheelListener, false);
        }
      };
    }
  }, [isMouseDown, wrapperRef, dimension]);

  useEffect(() => {
    // 获取空间ID对应的全部Asset，以及，原点、比例，用于PDF底图可视化
    UnionViewerService.getAssetsBySpaceId(spaceId)
      .then(res => {
        setSpaceRelationList(res.data.map(r => r.spaceRelation));
        setOffsetList(res.data.map(r => r.offset));
        const assetSpaces = res.data.map(r => r.assetSpace);
        dispatch(setAssetSpacesBySpaceId({spaceId, assetSpaces}));
        // todo 临时初始化PDF图层可见性
        let assetStates;
        if (assetSpaces.length === 1) {
          assetStates = {
            [assetSpaces[0].asset.assetId]: true,
          };
        } else {
          assetStates = Object.fromEntries(assetSpaces.map(a => [a.asset.assetId, !!a.asset.storeDir.join('').match(/(?:.*建筑)(?:.*平面)/)]))
        }
        dispatch(updateAssetLayerStates({spaceId, assetStates}));
      });
  }, [spaceId]);

  // 通过鼠标坐标与变换矩阵计算真实坐标
  const realPoint = screenToReal(cursorLocation, matrix);

  function handleChildRendered() {
    setSubRenderCount(subRenderCount + 1);
    console.log('子组件渲染完毕', subRenderCount + 1);
    // todo 当前逻辑有部分问题，临时在这里修改matrix，快速支持当前需求
    if (isFirstRender && initViewport)
    // if (initViewport)
      setMatrix(initViewport);
  }

  return (
    <div
      ref={wrapperRef}
      className={styles.viewerWrapper}
    >
      {
        (!width || !height) ?
          <div>加载中</div> :
          <>
            {/*<div*/}
            {/*  className={styles.loadingLayer}*/}
            {/*  style={{display: subRendered ? 'none' : ''}}*/}
            {/*>*/}
            {/*  加载中todo*/}
            {/*</div>*/}
            <div className={styles.backgroundLayer}/>
            {
              dimension === '2D' ? (
                <>
                  {
                    assetSpaces
                      .map((assetSpace, originalIndex) => {
                        return { assetSpace, originalIndex};
                      })
                      .filter(({assetSpace, originalIndex}) => {
                        if (assetLayerStates[assetSpace.asset.assetId].show)
                          return true;
                        return false;
                      })
                      .map(({assetSpace, originalIndex}, i) => {
                        return (
                          <PDFLayer
                            key={i}
                            assetSpace={assetSpace}
                            // todo !!!!
                            offset={spaceRelationList?.[originalIndex]?.origin || {coordinates: [0, 0]}}
                            globalOffset={offsetList[originalIndex] || {coordinates: [0, 0]}}
                            spaceId={spaceId}
                            matrix={matrix}
                            scale={6.35} // todo 未使用
                            width={width}
                            height={height}
                            setMatrix={setMatrix}
                            onRendered={handleChildRendered}
                          />
                        );
                      })
                  }
                  <SemanticLayer
                    className={styles.semanticLayer}
                    spaceId={spaceId}
                    width={width} height={height} matrix={matrix}
                    realPoint={realPoint}
                    isMouseDown={isMouseDown}
                    onRendered={handleChildRendered}
                  />
                  <ModifierLayer
                    className={styles.modifierLayer}
                    spaceId={spaceId}
                    width={width} height={height} matrix={matrix}
                    realPoint={realPoint}
                    isMouseDown={isMouseDown}
                    // onRendered={handleChildRendered}
                  />
                </>
              ) : (
                <IFCViewer spaceId={spaceId} />
              )
            }
            <div className={styles.thumbnail}>
              <Radio.Group options={[
                {label: '2D', value: '2D'},
                {label: '3D', value: '3D'},
              ]} onChange={({target: {value}}) => {
                setDimension(value);
              }} value={dimension} optionType="button" size="small" buttonStyle="solid"/>{' '}
              实际坐标: ({realPoint.map(n => n.toFixed()).join(',')})
              画布大小: ({width.toFixed()},{height.toFixed()})
              鼠标坐标: ({cursorLocation[0].toFixed()},{cursorLocation[1].toFixed()})
              变换矩阵: [{matrix.map(d => d.toFixed(2)).join(',')}]
            </div>
            <div style={{
              background: 'transparent',
              transformOrigin: '0 0',
              transform: `matrix(${matrix.join(',')})`,
              // transform: `matrix(1,0,0,1,100,100)`,
              zIndex: 100,
              position: 'absolute',
              // border: '1px solid #00f',
            }}>
              <div
                style={{
                  width: 200, height: 200,
                  transform: 'translate(-50px, -50px) scaleY(-1)',
                }}
              >
                <svg width="200" height="200">
                  <defs>
                    <marker id="arrow" viewBox="0 0 10 10" refX="5" refY="5"
                            markerWidth="6" markerHeight="6" orient="auto-start-reverse">
                      <path d="M 0 0 L 10 5 L 0 10 z" />
                    </marker>
                  </defs>
                  <line x1="50" y1="150" x2="150" y2="150" stroke="black" strokeWidth="3" markerEnd="url(#arrow)" />
                  <line x1="50" y1="150" x2="50" y2="50" stroke="black" strokeWidth="3" markerEnd="url(#arrow)" />
                  <text x="160" y="155" fontSize="14" fontWeight="bold">X</text>
                  <text x="45" y="35" fontSize="14" fontWeight="bold">Y</text>
                </svg>
              </div>
            </div>
          </>
      }
    </div>
  );
}

export default UnionViewer2D;