import React from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/es/menu/hooks/useItems';

const menuItems: ItemType[] = [
  // {
  //   key: 'changelog',
  //   label: (
  //     <Link to="/changelog">
  //       更新日志
  //     </Link>
  //   ),
  // },
];

export default function RightMenu() {
  const { md } = useBreakpoint();
  return (
    <Menu theme="dark" mode={md ? 'horizontal' : 'inline'} items={menuItems} />
  );
}
