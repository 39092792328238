import { Button, Divider, Form, Input, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';

import styles from './SpacePropertyEditor.module.less';
import { useAppSelector } from '../../../app/hooks';
import { selectSpaces } from '../tuzhiVisSlice';

interface SpacePropertyEditorProps {
  spaceId: number;
}

function SpacePropertyEditor(props: SpacePropertyEditorProps) {
  const {spaceId} = props;

  const spaces = useAppSelector(selectSpaces);
  const space = spaces.find(s => s.spaceId === spaceId);
  if (!space)
    return <div>空间错误</div>;

  console.log(space.properties);

  const initProperties = {
    properties: (space.properties === null ? [] : space.properties).map(p => ({
      key: p.key,
      value: p.value,
    })),
  };

  const onFinish = (values: any) => {
    console.log('Received values of form:', values);
  };

  return (
    <div className={styles.root}>
      {/*<Typography.Title>*/}
      {/*  空间属性: {spaceId.toString()}*/}
      {/*</Typography.Title>*/}
      <Divider orientation="left" dashed>
        空间属性 - [ID:{spaceId.toString()}] {space.name}
      </Divider>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initProperties}
      >
        <Form.List name="properties">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'key']}
                    rules={[{ required: true, message: '请填写属性键' }]}
                  >
                    <Input placeholder="属性键" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: false, message: '请填写属性值' }]}
                  >
                    <Input placeholder="属性值" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  增加属性
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            保存当前空间属性
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SpacePropertyEditor;
