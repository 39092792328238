import { FileOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './LibraryTree.module.less';

interface LibraryTreeProps {
  snlList: string[];
}

function LibraryTree(props: LibraryTreeProps) {
  const {snlList} = props;

  const buildMenu = (snlList: string[]) => {
    return snlList.map(snlName => {
      const name = snlName.replace('.snl', '');
      return ({
        key: name,
        label: <Link to={`/snl/${name}`}>{name}</Link>,
        icon: <FileOutlined/>,
        // children: [],
        // type: 'group',
      });
    });
  };

  return (
    <div className={styles.root}>
      <Menu
        mode="inline"
        items={buildMenu(snlList)}
      />
    </div>
  )
}

export default LibraryTree;
