import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  Asset,
  AssetSpace,
  Check,
  Element,
  Geom,
  OnlineSNLs,
  PluginMeta,
  Project,
  Space,
  SpaceRelation,
  Task,
} from '../../interfaces';
import UnionViewerService from './union-viewer/UnionViewer.service';
import TuzhiVisService from './TuzhiVis.service';
import ModelCheckerService from './model-checker/ModelChecker.service';
import { fromPairs, isEqual, merge, uniqWith } from 'lodash';

export interface VisState {
  // 操作相关数据
  activeSpaceId: number | null;

  // 存储相关数据
  project: Project | null; // 本项目信息
  assets: { [key: string]: Asset }; // 本项目所有资源
  spaces: Space[]; // 本项目所有空间列表
  spaceRelations: SpaceRelation[];  // 本项目所有空间空间关系
  plugins: {[key: string]: PluginMeta}; // 本项目所有插件信息
  assetSpacesBySpaceId: { [key: number]: AssetSpace[] }; // 每个空间对应的所有子空间的资源空间，用于如PDF地图

  selectedElementIdsBySpaceId: {[key: number]: number[]};  // 不同空间中选择的构件，用于显示构件属性
  highlightedElementIdsBySpaceId: {[key: number]: number[]};  // 不同空间中高亮的构件
  hoveredElementIds: number[];  // 鼠标悬停选中的构件，由于只有一个鼠标，一个列表即可

  tempElementProperties: any; // 选中的构件属性，临时用
  elements: { [key: string]: Element };
  geoms: { [key: string]: Geom };
  spaceGeoms: {
    [key: string]: Geom[]
  }; // 存储空间对应包围盒列表的数据
  tasks: Task[]; // 任务进度列表
  checkSpaceId: number | null; // 待审查的空间ID
  checks: Check[];
  onlineSNLs: OnlineSNLs;
  // 语义图层相关数据
  semanticLayerStates: {
    [key: number]: { // spaceId
      [key: string]: boolean;
    };
  };
  // 资源(PDF等)图层可见性
  assetLayerStates: {
    [key: number]: { // spaceId
      [key: number]: { // 该空间下的所有资源的assetId
        show: boolean; // 当前资源是否可见，因为不止图层内容
        layers: { // 该资源（PDF）下的子图层
          [key: string]: boolean;
        };
      };
    };
  };
  // 可视化界面视口数据Matrix映射
  spaceViewportMap: { [key: string]: number[] },

  // 日志相关数据
  log: {
    active: boolean; // 是否动态更新日志
    offset: number; // 下一次请求的开始偏移量
    content: string; // 当前全部日志内容
  };
}

const initialState: VisState = {
  activeSpaceId: null,

  project: null,
  assets: {},
  spaces: [],
  spaceRelations: [],
  plugins: {},
  assetSpacesBySpaceId: {},
  spaceGeoms: {},

  selectedElementIdsBySpaceId: {},
  highlightedElementIdsBySpaceId: {},
  hoveredElementIds: [],

  tempElementProperties: [],
  elements: {},
  geoms: {},
  tasks: [],
  checkSpaceId: null,
  checks: [],
  onlineSNLs: { presets: [], snls: []},
  semanticLayerStates: {},
  assetLayerStates: {},
  spaceViewportMap: {},

  log: {
    active: false,
    offset: 0,
    content: '',
  },
};

export const fetchAssetsByProjectId = createAsyncThunk<
  {
    projectId: number,
    assets: Asset[]
  },
  number
>(
  'vis/fetchAssets',
  async (projectId: number) => {
    const response = await TuzhiVisService.getAssetsByProjectId(projectId);
    return {
      projectId: projectId,
      assets: response.data
    };
  }
);

export const fetchGeomsBySpaceId = createAsyncThunk<
  {
    spaceId: number,
    geoms: Geom[]
  },
  number
>(
  'vis/fetchGeoms',
  async (spaceId: number) => {
    const response = await UnionViewerService.getGeomsBySpaceId(spaceId);
    return {
      spaceId: spaceId,
      geoms: response.data
    };
  }
);

export const fetchElementsBySpaceId = createAsyncThunk<
  {
    spaceId: number,
    elements: Element[]
  },
  number
>(
  'vis/fetchElements',
  async (spaceId: number) => {
    const response = await UnionViewerService.getElementsBySpaceId(spaceId);
    return {
      spaceId: spaceId,
      elements: response.data
    };
  }
);

export const fetchChecksByProjectId = createAsyncThunk<
  {
    projectId: number,
    elements: Check[]
  },
  number
>(
  'vis/fetchChecks',
  async (projectId: number) => {
    const response = await ModelCheckerService.getChecksByProjectId(projectId);
    return {
      projectId: projectId,
      elements: response.data
    };
  }
);

export const fetchSNLs = createAsyncThunk<OnlineSNLs>(
  'vis/fetchSNLs',
  async () => {
    const response = await UnionViewerService.getSNLs();
    return response.data;
  }
);

export const fetchPluginsByProjectId = createAsyncThunk<
  {
    projectId: number,
    plugins: PluginMeta[];
  },
  number
>(
  'vis/fetchPlugins',
  async (projectId: number) => {
    const response = await TuzhiVisService.getPlugins(projectId);
    return {
      projectId: projectId,
      plugins: response.data,
    };
  }
);

export const fetchSpaceRelations = createAsyncThunk<{ spaceRelations: SpaceRelation[]}, number>(
  'vis/fetchSpaceRelations',
  async (projectId: number) => {
    const response = await TuzhiVisService.getSpaceRelationsByProjectId(projectId);
    return {
      projectId: projectId,
      spaceRelations: response.data,
    };
  },
);

export const visSlice = createSlice({
  name: 'vis',
  initialState,
  reducers: {
    setActiveSpaceId: (state, action) => {
      state.activeSpaceId = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setAssets: (state, action: PayloadAction<Asset[]>) => {
      const assets: {[key: string]: Asset} = {};
      for (const asset of action.payload) {
        assets[asset.assetId.toString()] = asset;
      }
      state.assets = assets;
    },
    setSpaces: (state, action) => {
      state.spaces = action.payload;
    },
    setAssetSpacesBySpaceId: (state, action: PayloadAction<{spaceId: number, assetSpaces: AssetSpace[]}>) => {
      state.assetSpacesBySpaceId[action.payload.spaceId] = action.payload.assetSpaces;
    },
    setCheckSpaceId: (state, action) => {
      state.checkSpaceId = action.payload;
    },
    setSpaceGeoms: (state, action) => {
      state.spaceGeoms[action.payload.spaceId] = action.payload.geoms;
    },
    setTempElementProperties: (state, action) => {
      state.tempElementProperties = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setChecks: (state, action) => {
      state.checks = action.payload;
    },
    addElements: (state, action: PayloadAction<Element[]>) => {
      action.payload.forEach(e => {
        state.elements[e.elementId.toString()] = e;
      });
    },
    addGeoms: (state, action: PayloadAction<Geom[]>) => {
      action.payload.forEach(g => {
        state.geoms[g.geomId.toString()] = g;
      });
    },
    setSpaceViewport: (state, action: PayloadAction<{spaceId: number, viewport: number[]}>) => {
      state.spaceViewportMap[action.payload.spaceId.toString()] = action.payload.viewport;
    },
    setSelectedElementIdsBySpaceId: (state, action: PayloadAction<{spaceId: number, elementIds: number[]}>) => {
      state.selectedElementIdsBySpaceId[action.payload.spaceId] = action.payload.elementIds;
    },
    updateSemanticLayerStates: (state, action: PayloadAction<{spaceId: number, checked: string[]}>) => {
      for (const s in state.semanticLayerStates[action.payload.spaceId]) {
        state.semanticLayerStates[action.payload.spaceId][s] = false;
      }
      for (const s of action.payload.checked) {
        state.semanticLayerStates[action.payload.spaceId][s] = true;
      }
    },
    setHoveredElementIds: (state, action) => {
      state.hoveredElementIds = action.payload;
    },
    updateAssetLayerStates: (state, action: PayloadAction<{spaceId: number, assetStates: {[key: number]: boolean}}>) => {
      const spaceId = action.payload.spaceId;
      if (!state.assetLayerStates[spaceId])
        state.assetLayerStates[spaceId] = {};
      for (const s in state.assetLayerStates[spaceId]) {
        state.assetLayerStates[spaceId][s].show = false;
      }
      for (const assetId in action.payload.assetStates) {
        if (!state.assetLayerStates[spaceId][assetId])
          state.assetLayerStates[spaceId][assetId] = {show: false, layers: {}};
        state.assetLayerStates[spaceId][assetId].show = action.payload.assetStates[assetId];
      }
    },
    activateLog: (state) => {
      state.log.active = true;
    },
    deactivateLog: (state) => {
      state.log.active = false;
    },
    clearLog: (state, action) => {
      state.log.offset = 0;
      state.log.content = '';
    },
    appendLog: (state, action: PayloadAction<{content: string, offset: number}>) => {
      state.log.offset = action.payload.offset;
      state.log.content += action.payload.content;
    },
    clearCache: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAssetsByProjectId.fulfilled, (state, {payload}) => {
        state.assets = Object.fromEntries(payload.assets.map(a => [a.assetId.toString(), a]));
      })
      .addCase(fetchGeomsBySpaceId.fulfilled, (state, {payload}) => {
        state.spaceGeoms[payload.spaceId.toString()] = payload.geoms;
      })
      .addCase(fetchElementsBySpaceId.fulfilled, (state, {payload}) => {
        // 更新全量构件Map
        for (const e of payload.elements) {
          state.elements[e.elementId] = e;
        }
        // 预选择方案，反向筛选，默认都显示，列表中的默认不显示
        const familyBlacklist = ['标签', '楼板', '面积', '轴网元素'];
        // 更新语义图层信息
        state.semanticLayerStates[payload.spaceId] = fromPairs(
          uniqWith(
            payload.elements
              .map(e => [e.categoryName + '###' + e.familyName, !familyBlacklist.includes(e.familyName)]),
            isEqual,
          ),
        );
      })
      .addCase(fetchSNLs.fulfilled, (state, {payload}) => {
        state.onlineSNLs = payload;
      })
      .addCase(fetchPluginsByProjectId.fulfilled, (state, {payload}) => {
        state.plugins = Object.fromEntries(payload.plugins.map(p => [p.name, p]));
      })
      .addCase(fetchSpaceRelations.fulfilled, (state, {payload}) => {
        state.spaceRelations = payload.spaceRelations;
      });
  }
});

export const {
  setActiveSpaceId,
  setProject,
  setAssets,
  setSpaces,
  setAssetSpacesBySpaceId,
  setSpaceGeoms,
  setCheckSpaceId,
  setTempElementProperties,
  setTasks,
  setChecks,
  addElements,
  addGeoms,
  setSpaceViewport,
  setSelectedElementIdsBySpaceId,
  updateSemanticLayerStates,
  setHoveredElementIds,
  updateAssetLayerStates,
  activateLog,
  deactivateLog,
  clearLog,
  appendLog,
  clearCache,
} = visSlice.actions;

export const selectActiveSpaceId = (state: RootState) => state.vis.activeSpaceId;
export const selectProject = (state: RootState) => state.vis.project;
export const selectAssets = (state: RootState) => state.vis.assets;
export const selectSpaces = (state: RootState) => state.vis.spaces;
export const selectSpaceRelations = (state: RootState) => state.vis.spaceRelations;

export const selectAssetSpacesBySpaceId = createSelector(
  [
    (state: RootState) => state.vis.assetSpacesBySpaceId,
    (state, spaceId: number | null) => spaceId
  ],
  (assetSpaces, spaceId) => {
    if (spaceId)
      return assetSpaces?.[spaceId] || [];
    return [];
  }
);
export const selectGeomsBySpaceId = createSelector(
  [
    (state: RootState) => state.vis.spaceGeoms,
    (state, spaceId: number | null) => spaceId
  ],
  (spaceGeoms, spaceId) => {
    if (spaceId)
      return spaceGeoms?.[spaceId] || [];
    return [];
  }
);

export const selectElements = (state: RootState) => state.vis.elements;
export const selectGeoms = (state: RootState) => state.vis.geoms;
export const selectCheckSpaceId = (state: RootState) => state.vis.checkSpaceId;
export const selectTempElementProperties = (state: RootState) => state.vis.tempElementProperties;
export const selectTasks = (state: RootState) => state.vis.tasks;
export const selectChecks = (state: RootState) => state.vis.checks;
export const selectSNLs = (state: RootState) => state.vis.onlineSNLs;
export const selectHoveredElementIds = (state: RootState) => state.vis.hoveredElementIds;
export const selectPlugins = (state: RootState) => state.vis.plugins;
export const selectLog = (state: RootState) => state.vis.log;

export const selectViewportBySpaceId = createSelector(
  [
    (state: RootState) => state.vis.spaceViewportMap,
    (state, spaceId: number) => spaceId
  ],
  (spaceViewportMap, spaceId) => {
    return spaceViewportMap?.[spaceId.toString()] || null;
  }
);

export const selectSelectedElementsBySpaceId = createSelector(
  [
    (state: RootState) => state.vis.selectedElementIdsBySpaceId,
    (state, spaceId: number | null) => spaceId
  ],
  (elementIds, spaceId) => {
    if (spaceId)
      return elementIds[spaceId] || [];
    return [];
  }
);

export const selectSemanticLayerStatesBySpaceId = createSelector(
  [
    (state: RootState) => state.vis.semanticLayerStates,
    (state, spaceId: number | null) => spaceId
  ],
  (semanticLayerStates, spaceId) => {
    if (spaceId)
      return semanticLayerStates[spaceId] || {};
    return {};
  }
);

export const selectAssetLayerStatesBySpaceId = createSelector(
  [
    (state: RootState) => state.vis.assetLayerStates,
    (state, spaceId: number | null) => spaceId
  ],
  (assetLayerStates, spaceId) => {
    if (spaceId)
      return assetLayerStates[spaceId] || {};
    return {};
  }
);

export default visSlice.reducer;
