import { inv, matrix as mathMatrix, multiply } from 'mathjs';

export function screenToReal(cursorLocation: number[], matrix: number[]) {
  // 通过鼠标坐标与变换矩阵计算真实坐标
  const point = multiply(
    inv(mathMatrix([
      [matrix[0], matrix[1], matrix[4]],
      [matrix[2], matrix[3], matrix[5]],
      [0, 0, 1],
    ])),
    mathMatrix([
      [cursorLocation[0]], [cursorLocation[1]], [1],
    ])
  );
  return [point.get([0, 0]), point.get([1, 0])];
}