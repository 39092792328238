import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AuthState {
  username: string;
  role: string;
  token: string;
}

const initialState: AuthState = {
  username: '',
  role: 'guest',
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return {...action.payload};
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: () => {
      return {...initialState};
    },
  }
});

export const { setUser, setUsername, setRole, setToken, logout } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth;
export const selectUsername = (state: RootState) => state.auth.username;
export const selectRole = (state: RootState) => state.auth.role;
export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
