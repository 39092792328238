import React from 'react';

import styles from './ProgressViewer.module.less';
import { useAppSelector } from '../../../app/hooks';
import { selectTasks } from '../tuzhiVisSlice';

function datetimeSplit(datetime: Date) {
  // 日期部分
  // const datePart = `${datetime.getFullYear()%100}-${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')}`;
  const datePart = `${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')}`;
  // 时间部分
  const timePart = `${datetime.getHours().toString().padStart(2, '0')}:${datetime.getMinutes().toString().padStart(2, '0')}:${datetime.getSeconds().toString().padStart(2, '0')}`;
  return [datePart, timePart];
}

function pluginNameAbbr(pluginName: string): string {
  // 使用gpt生成的缩写列表，有理解问题时，再修改
  const wordMappings: { [key: string]: string } = {
    Building: 'Bld',
    Plugin: '',
    Derived: 'Drv',
    Recognition: 'Rec',
    Detection: 'Det',
    Extraction: 'Ext',
    Information: 'Info',
    Management: 'Mgmt',
    Visualization: 'Vis',
    Generator: 'Gen',
    Calculation: 'Calc',
    Control: 'Ctrl',
    Integration: 'Integ',
    Analysis: 'Anal',
    Specification: 'Spec',
    Emergency: 'Emgcy',
    Evacuation: 'Evac',
    Fire: 'Fir',
    Compartment: 'Cptmt',
    Water: 'Wat',
    Text: 'Txt',
    Room: 'Rm',
    Space: 'Sp',
    Layout: 'Lyt',
    Length: 'Len',
    Width: 'Wid',
    Property: 'Prop',
    Global: 'Glob',
    Corridor: 'Corr',
    Door: 'Dr',
    Window: 'Win',
    Stair: 'Str',
    Floor: 'Flr',
    Plan: 'Pln',
    Merge: 'Mer',
    Extract: 'Ext',
    Align: 'Algn',
    Virtual: 'Virt',
    End: 'End',
    Design: 'Des',
    Post: 'Post',
    Area: 'Area',
    Type: 'Type',
    Containing: 'Contain',
    Relationship: 'Rel',
    Bitmap: 'Bmp',
    Axis: 'Ax',
    Table: 'Tbl',
    Smooth: 'Smt',
    Luminance: 'Lum',
    Illumination: 'Illu',
    Smoke: 'Smk',
    Firefighting: 'FF',
    Extinguisher: 'Ext',
  };

  const words = pluginName.replace(/([A-Z])/g, ' $1').trim().split(' ');
  return words.map(word => {
    if (wordMappings[word] !== undefined) {
      return wordMappings[word];
    }
    return word;
  }).join('');
}

interface ProgressViewerProps {
  projectId: number
}

function ProgressViewer(props: ProgressViewerProps) {
  const {projectId} = props;

  const tasks = useAppSelector(selectTasks);

  // 计算当前项目总耗时，即从最早时间的插件，到最终结束的插件
  let earliestTime = Number.MAX_VALUE;
  let latestTime = 0;
  tasks.forEach(t => {
    if (t.createTime) {
      const createTime = new Date(t.createTime).getTime();
      if (createTime < earliestTime) {
        earliestTime = createTime;
      }
    }
    if (t.finishTime) {
      const finishTime = new Date(t.finishTime).getTime();
      if (finishTime > latestTime) {
        latestTime = finishTime;
      }
    }
  })

  return (
    <div className={styles.root}>
      <div className={styles.summary}>
        总任务: {tasks.length},
        已完成: {tasks.filter(t => t.state === 'SUCCESS').length},
        执行中: {tasks.filter(t => t.state === 'PROGRESS').length},
        等待中: {tasks.filter(t => t.state === 'PENDING').length}
        <br/>
        {
          latestTime - earliestTime > 0 &&
          <span>项目总耗时：
            {Math.floor((latestTime - earliestTime) / 1000 / 60)}分
            {Math.floor((latestTime - earliestTime) / 1000) % 60}秒
          </span>
        }
      </div>
      {
        tasks
          .slice()
          .sort((a, b) => {
            if (a.state === b.state) {
              const aCreate = new Date(a.createTime).getTime();
              const bCreate = new Date(b.createTime).getTime();
              const aStart = a.startTime ? new Date(a.startTime).getTime() : Number.MAX_VALUE;
              const bStart = b.startTime ? new Date(b.startTime).getTime() : Number.MAX_VALUE;
              if (aCreate === bCreate) {
                if (aStart === bStart) {
                  return b.pluginName > a.pluginName ? 1 : 0;
                }
                return bStart - aStart;
              }
              return bCreate - aCreate;
            }
            const stateOrder = { 'PROGRESS': 1, 'FAILURE': 2, 'PENDING': 3, 'SUCCESS': 4 };
            return stateOrder[a.state] - stateOrder[b.state];
          })
          .map(t => {
            let percent = 0;
            let status = undefined;
            switch (t.state) {
              case 'PENDING':
                percent = 0;
                break;
              case 'PROGRESS':
                percent = 50;
                break;
              case 'SUCCESS':
                percent = 100;
                break;
              case 'FAILURE':
                status = 'exception' as const;
                break;
            }

            const createTimeSplit = datetimeSplit(new Date(t.createTime));
            let finishTimeSplit = null;
            if (t.finishTime) {
              finishTimeSplit = datetimeSplit(new Date(t.finishTime));
            }
            return (
              <div key={t.taskId} className={styles.row}>
                {/*<Progress percent={percent} size="small" style={{width: 75}} status={status} />{' '}*/}
                {/*<span><b>{t.state}</b></span>{' '}*/}
                {/*<span>{t.taskType.slice(0, 3)}</span>{' '}*/}
                {/*<span>提交:{timeBefore(new Date(t.createTime))}</span>{' '}*/}
                {/*<span>开始:{t.startTime ? timeBefore(new Date(t.startTime)) : '未开始'}</span>{' '}*/}
                {/*<span>结束:{t.finishTime ? timeBefore(new Date(t.finishTime)) : '未完成'}</span>{' '}*/}
                {/*<span>{t.pluginName}</span>{' '}*/}
                <div
                  className={styles.progressBackground}
                  style={{
                    width: `${percent}%`,
                    backgroundColor: {
                      'PENDING': '#e8e8e8',
                      'PROGRESS': '#d6e4f5',
                      'SUCCESS': '#d9ead3',
                      'FAILURE': '#f9d4d4',
                    }[t.state],
                  }}
                />
                <div className={styles.rowContent}>
                  <div className={styles.status}>{t.state}</div>{' '}
                  <div>{t.taskType.slice(0, 3)}</div>{' '}
                  <div className={styles.timeBlock}>
                    <div>
                      {/*<div className={styles.timeCell}>*/}
                      {/*  <div>提交{createTimeSplit[0]}</div>*/}
                      {/*  <div>{createTimeSplit[1]}</div>*/}
                      {/*</div>*/}
                      <div className={styles.timeCell}>
                        <div>开始{createTimeSplit[0]}</div>
                        <div>{createTimeSplit[1]}</div>
                      </div>
                      {
                        finishTimeSplit &&
                        <div className={styles.timeCell}>
                          <div>结束{finishTimeSplit[0]}</div>
                          <div>{finishTimeSplit[1]}</div>
                        </div>
                      }
                    </div>
                  </div>
                  <div title={t.pluginName}>{pluginNameAbbr(t.pluginName)}</div>
                </div>
              </div>
            );
          })
      }
    </div>
  )
}

export default ProgressViewer;
