import React, { MouseEventHandler, useState } from 'react';
import { Button, Drawer, Menu } from 'antd';

import LeftMenu from './LeftMenu';
import RightMenu from './RightMenu';

import styles from './NavBar.module.less';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser, setUser } from '../auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { selectVersion } from '../../appSlice';
import { API_NAME } from '../../config';

function UserNavBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const handleLogout = () => {
    dispatch(setUser({
      username: '',
      token: '',
      role: 'guest',
    }));
    navigate('/');
  };

  if (user.username) {
    return (
      <div className={styles.loggedIn}>
        <UserOutlined />
        <span>
          {user.username}
        </span>{' '}
        <span onClick={handleLogout}>
          注销
        </span>
      </div>
    );
  } else {
    return (
      <div className={styles.loggingIn}>
        <Link to="/login">
          用户登录
        </Link>
      </div>
    );
  }
}

export default function NavBar() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  
  const version = useAppSelector(selectVersion);
  
  const onDrawerShow: MouseEventHandler = (e) => {
    setDrawerVisible(true);
  };

  // todo
  const onDrawerClose = (e: any) => {
    setDrawerVisible(false);
  };

  return (
    <nav className={styles.menuBar}>
      <div className={styles.logo}>
        <img src="/tuzhi-logo-reverse-trans.png" alt="图智" />
        {/*<a href="/">图智</a>*/}
        <a href="https://tuzhi.ai">{API_NAME} {version}</a>
      </div>
      <div className={styles.menuMain}>
        <div className={styles.leftMenu}>
          <LeftMenu />
        </div>
        <div className={styles.rightMenu}>
          <RightMenu />
        </div>
        <Button className={styles.barsMenu} type="primary" onClick={onDrawerShow}>
          <span className={styles.barsBtn} />
        </Button>
        <Drawer
          title="图智菜单"
          placement="right"
          closable={false}
          onClose={onDrawerClose}
          open={drawerVisible}
        >
          <LeftMenu />
          <RightMenu />
        </Drawer>
      </div>
      <div className={styles.loginWrapper}>
        <UserNavBar />
      </div>
    </nav>
  );
}
