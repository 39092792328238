import { Element, Project, Property, Space } from '../../../interfaces';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import React, { useContext, useState } from 'react';
import { Button, Form, Input, message, Modal, Popover } from 'antd';
import {
  fetchElementsBySpaceId, selectActiveSpaceId,
  selectElements,
  selectGeoms, selectSpaceRelations,
  selectSpaces, setActiveSpaceId,
  setProject, setSelectedElementIdsBySpaceId,
  setSpaces, setSpaceViewport,
} from '../tuzhiVisSlice';
import TuzhiVisService from '../TuzhiVis.service';
import styles from './ElementPropertyViewer.module.less';
import { CopyOutlined, EditOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { element } from 'three/examples/jsm/nodes/shadernode/ShaderNode';
import * as FlexLayout from 'flexlayout-react';
import { bbox } from '@turf/turf';
import { LayoutContext } from '../TuzhiVis';

type ActiveProperty = Property & {
  active: boolean,
};

type GroupedProperties = {
  [key: string]: ActiveProperty[],
};

function groupPropertiesByPrefix(properties: Property[]): GroupedProperties {
  const groupedProperties: GroupedProperties = {};
  const maxOrders: {[key: string]: number} = {};
  for (const prop of properties) {
    // 计算属性order
    if (!maxOrders[prop.key]) {
      maxOrders[prop.key] = 0;
    }
    if (prop.order > maxOrders[prop.key]) {
      maxOrders[prop.key] = prop.order;
    }
    if (!prop.key.includes('.')) {
      let autoKey = '其他';
      if (['element_name', 'family_name', 'category_name'].includes(prop.key)) {
        autoKey = '基本信息';
      }
      if (!groupedProperties[autoKey]) {
        groupedProperties[autoKey] = [];
      }
      groupedProperties[autoKey].push({
        ...prop,
        active: false,
      });
      continue;
    }

    const keyParts: string[] = prop.key.split('.');
    const groupKey: string = keyParts[0];

    if (!groupedProperties[groupKey]) {
      groupedProperties[groupKey] = [];
    }
    groupedProperties[groupKey].push({
      ...prop,
      active: false,
    });
  }
  // 按照key、order排序
  for (const key in groupedProperties) {
    groupedProperties[key].sort((a, b) => {
      const fixedOrder = [
        'element_name', 'family_name', 'category_name',
      ];
      if (fixedOrder.includes(a.key) && fixedOrder.includes(b.key)) {
        return fixedOrder.indexOf(a.key) - fixedOrder.indexOf(b.key);
      }
      if (a.key === b.key) {
        return b.order - a.order;
      }
      return a.key.localeCompare(b.key);
    });
  }
  // 按照是否是最大order，更新active状态
  for (const key in groupedProperties) {
    for (const p of groupedProperties[key]) {
      p.active = p.order === maxOrders[p.key];
    }
  }
  console.log(23333);
  console.log(groupedProperties);
  return groupedProperties;
}

interface PropertyRootProps {
  project?: Project;
  space?: Space;
  element?: Element;
}

function GeneralPropertyViewer(props: PropertyRootProps) {
  const {project, element, space} = props;

  const dispatch = useAppDispatch();

  const [collapse, setCollapse] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [editForm] = Form.useForm();

  // 没有element参数时，默认展示space属性
  const type = project ? 'project' : (element ? 'element' : 'space');

  const showModal = () => {
    setIsEditModalOpen(true);
  };

  const handleOk = () => {
    editForm.validateFields().then((values) => {
      let endpoint;
      if (element)
        endpoint = TuzhiVisService.updateElementProperty(element.elementId, values.key, values.value);
      else if (space)
        endpoint = TuzhiVisService.updateSpaceProperty(space.spaceId, values.key, values.value);
      else if (project)
        endpoint = TuzhiVisService.updateProjectProperty(project.projectId, values.key, values.value);
      else
        throw new Error('未知的对象类型');

      endpoint
        .then(res => {
          message.success('成功增改对象属性');
          editForm.resetFields();
          setIsEditModalOpen(false);
          // todo 更高效的数据获取
          if (project) {
            TuzhiVisService
              .getProject(project.projectId)
              .then(res => {
                dispatch(setProject(res.data));
              });
          }
          if (space) {
            dispatch(fetchElementsBySpaceId(space.spaceId));
            TuzhiVisService
              .getSpacesByProjectId(space.projectId)
              .then(res => {
                dispatch(setSpaces(res.data));
              });
          }
        })
        .catch(err => {
          message.error('增改对象属性失败');
          console.error(err);
          setIsEditModalOpen(false);
        });
    });
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  if (!project && !element && !space) {
    return null;
  }

  return (
    <>
      <div className={styles.propertySetRoot}>
        <div className={styles.title}>
        <span
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {collapse ? <PlusSquareOutlined /> : <MinusSquareOutlined />}{' '}
          <span>属性列表</span>
          <Button
            size="small"
            type="text"
            onClick={e => {
              e.stopPropagation();
              showModal();
            }}
          >
            增改属性
          </Button>
        </span>
        </div>
        <div className={styles.propertySet} style={{display: collapse ? 'none' : ''}}>
          {
            Object.entries(groupPropertiesByPrefix(((element ? element : (space ? space : project)) || {properties: []}).properties)).map(([key, properties]) => {
              return <PropertyNode key={key} propertySet={key} properties={properties} />;
            })
          }
        </div>
      </div>
      <Modal title="增改属性" open={isEditModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="key"
            label="属性名"
            rules={[{ required: true, message: '请输入属性名' }]}
          >
            <Input placeholder="完整属性名，如：BC.面积" />
          </Form.Item>
          <Form.Item
            name="value"
            label="属性值"
            rules={[{ required: true, message: '请输入属性值' }]}
          >
            <Input placeholder="属性值" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

interface PropertyNodeProps {
  propertySet: string;
  properties: ActiveProperty[];
}

function PropertyNode(props: PropertyNodeProps) {
  const {propertySet, properties} = props;
  const [collapse, setCollapse] = useState(false);

  const dispatch = useAppDispatch();
  const elements = useAppSelector(selectElements);
  const geoms = useAppSelector(selectGeoms);
  const spaces = useAppSelector(selectSpaces);
  const layoutContext = useContext(LayoutContext);
  const spaceRelations = useAppSelector(selectSpaceRelations);
  const activeSpaceId = useAppSelector(selectActiveSpaceId);

  const onPreview = (spaceId: number, elementId: number) => {
    // 新建tab页或跳转tab页，打开当前资源
    const tabId = `space-${spaceId}`;
    if (layoutContext.model.getNodeById(tabId)) {
      layoutContext.model.doAction(
        FlexLayout.Actions.selectTab(tabId),
      );
    } else {
      layoutContext.model.doAction(FlexLayout.Actions.addNode(
        {
          id: tabId, type: 'tab',
          component: 'union-viewer',
          name: `[空间${spaceId}]${spaces.find(s => s.spaceId === spaceId)?.name}`,
          config: {spaceId: spaceId},
        },
        'viewer-pane', FlexLayout.DockLocation.CENTER, -1, true,
      ));
    }
    // 更新当前激活的空间
    dispatch(setActiveSpaceId(spaceId));
    dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: [elementId]}));
    setTimeout(() => {
      dispatch(setSelectedElementIdsBySpaceId({spaceId, elementIds: [elementId]}));
    }, 500);
  }

  return (
    <div className={styles.propertySet}>
      <div className={styles.title}>
        <span
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {collapse ? <PlusSquareOutlined /> : <MinusSquareOutlined />}{' '}
          {propertySet}
        </span>
      </div>
      <div className={styles.property} style={{display: collapse ? 'none' : ''}}>
        {
          properties
            .map((p, i) => {
              return (
                <div key={i} className={clsx(styles.entry, !p.active && styles.inactive)}>
                  <div className={styles.nameWrapper}>
                    <div className={styles.name}>{p.key}</div>
                    <div className={styles.plugin}>
                      {p.source.replace(/plugin/i, '')}{' '}
                      [#{p.order}]
                    </div>
                  </div>
                  {
                    p.key.endsWith('_原始构件ID') ?
                      <Popover key={p.key} trigger={['click']} content={<div>
                        <Button.Group>
                          {
                            (Array.isArray(p.value) ? p.value : [p.value]).map((v) => {
                              return elements[v] && elements[v].geoms?.map(gId => {
                                return (
                                  <div key={gId.toString()}>
                                    <Button
                                      onClick={() => {
                                        console.log(v);
                                        TuzhiVisService.getElementGeometries(Number(v)).then(res => {
                                          onPreview(res.data[0].spaceId, Number(v));
                                          // 同时切到构件属性面板
                                          layoutContext.model.doAction(
                                            FlexLayout.Actions.selectTab('element-properties'),
                                          );
                                        });
                                      }}
                                    >
                                      定位
                                    </Button>
                                  </div>
                                );
                              })
                            })
                          }
                        </Button.Group>
                      </div>}>
                        <span>定位: {p.value}</span>
                      </Popover> :
                      <span>
                        {p.value}
                      </span>
                  }
                  <span className={styles.functions}>
                    <CopyOutlined
                      onClick={() => {
                        navigator.clipboard.writeText(p.value + '');
                        message.success('已复制属性值到剪贴板');
                      }}
                    />{' '}
                    {/*<EditOutlined />*/}
                  </span>
                </div>
              );
          })
        }
      </div>
    </div>
  );
}

export default GeneralPropertyViewer;
