import React, { useEffect, useRef, useState } from "react";
import styles from "./ConsistencyIFCViewer.module.less";
import * as THREE from "three";
import * as OBC from "openbim-components";
import * as WEBIFC from "web-ifc";
import Loading from "../../../components/Loading";
import ConsistencyViewerService from "../ConsistencyViewer.service";
import { Button, message } from "antd";
import { useResizeDetector } from "react-resize-detector";
import { IfcPropertiesUtils } from "openbim-components/src/ifc/IfcPropertiesUtils";
import { selectUploadIfc, setUploadIfc } from "../consistencyViewerSlice";
import { useAppSelector } from "../../../app/hooks";
import { exp } from "mathjs";
import { selectElevation } from "../consistencyViewerSlice";
import { FragmentsGroup, IfcProperties } from "bim-fragment";
import { selectSelectedBimId } from "../consistencyViewerSlice";
import { set } from "lodash";

interface ConsistencyIFCViewerProps {
  projectId: number;
}

interface BimIdToExpressId {
  [bimId: string]: string;
}

interface BimIdToPosition {
  [bimId: string]: THREE.Box3;
}

function ConsistencyIFCViewer(props: ConsistencyIFCViewerProps) {
  // 下列新加入
  const { projectId } = props;
  const uploadIfc = useAppSelector(selectUploadIfc);
  // 上述新加入
  const ifcRef = useRef<HTMLDivElement>(null);

  const [components, setComponents] = useState<OBC.Components | null>(null);
  const [fragments, setFragments] = useState<OBC.FragmentManager | null>(null);
  const [model, setModel] = useState<FragmentsGroup | null>(null);
  const [selectedFragmentId, setSelectedFragmentId] = useState([]);
  const elevation = useAppSelector(selectElevation);
  const [hider, setHider] = useState<OBC.FragmentHider | null>(null);
  const [classifier, setClassifier] = useState<OBC.FragmentClassifier | null>(
    null
  );
  const [fragmentIfcLoader, setFragmentIfcLoader] =
    useState<OBC.FragmentIfcLoader | null>(null);
  const [propsProcessor, setPropsProcessor] =
    useState<OBC.IfcPropertiesProcessor | null>(null);
  const [hasUploaded, setHasUploaded] = useState(true);

  const [loaded, setLoaded] = useState(false);
  const [highlighter, setHighlighter] =
    useState<OBC.FragmentHighlighter | null>(null);
  const selectedBimId = useAppSelector(selectSelectedBimId);
  const [bimIdToExpressIds, setBimIdToExpressIds] = useState<BimIdToExpressId>(
    {}
  );
  const [BimIdToPositions, setBimIdToPositions] = useState<BimIdToPosition>({});
  const [lastSelection, setLastSelection] = useState<OBC.FragmentIdMap>({});
  const [camera, setCamera] = useState<OBC.SimpleCamera | null>(null);

  const {
    width,
    height,
    ref: resizeRef,
  } = useResizeDetector({
    // refreshMode: 'throttle',
    // refreshRate: 500
  });

  const getBoundingBoxCenter = (boundingBox: THREE.Box3) => {
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);
    return center;
  }

  const equalElevation = (storeyName: string, elevation: string | number) => {
    if (typeof elevation === "number") {
      elevation = elevation.toString();
    }
    // 定义正则表达式匹配数字
    const regex = /-?\d+/; // 匹配一个或多个数字

    // 提取 storeyName 中的数字部分
    const numbersInStorey = storeyName.match(regex);

    if (numbersInStorey) {
      // 如果成功匹配到数字
      if (numbersInStorey.length === 1) {
        return numbersInStorey[0] === elevation;
      } else {
        const newNumbersInStorey = numbersInStorey.filter((item: any) =>
          item.includes("地上")
        );
        if (newNumbersInStorey.length === 1) {
          return newNumbersInStorey[0] === elevation;
        } else {
          return true;
        }
      }
    } else {
      // 如果未匹配到数字，根据实际需求处理逻辑
      return true;
    }
  };

  useEffect(() => {
    let fragments: OBC.FragmentManager;

    // 异步的IFCViewer加载
    const initIFCViewer = async () => {
      if (!ifcRef.current) return;
      const components = new OBC.Components();
      setComponents(components);
      // 初始化场景、渲染器、相机、射线投射
      const scene = new OBC.SimpleScene(components);
      components.scene = scene;
      const renderer = new OBC.PostproductionRenderer(
        components,
        ifcRef.current,
        {
          antialias: true,
          alpha: true,
          preserveDrawingBuffer: true,
        }
      );
      components.renderer = renderer;
      const camera = new OBC.SimpleCamera(components);
      components.camera = camera;
      const raycaster = new OBC.SimpleRaycaster(components);
      components.raycaster = raycaster;
      await components.init();

      renderer.postproduction.enabled = true;

      const threeScene = components.scene.get();

      await camera.controls.setLookAt(12, 6, 8, 0, 0, -10);
      setCamera(camera);

      await scene.setup();

      const grid = new OBC.SimpleGrid(components, new THREE.Color(0x666666));
      const customEffects = renderer.postproduction.customEffects;
      customEffects.excludedMeshes.push(grid.get());
      customEffects.outlineEnabled = true;

      // 转换IFC到Fragment
      fragments = new OBC.FragmentManager(components);
      setFragments(fragments);
      const fragmentIfcLoader = new OBC.FragmentIfcLoader(components);

      // wasm配置
      fragmentIfcLoader.settings.wasm = {
        path: "https://unpkg.com/web-ifc@0.0.46/",
        absolute: true,
      };

      // 排除分类
      const excludedCats = [
        WEBIFC.IFCTENDONANCHOR,
        WEBIFC.IFCREINFORCINGBAR,
        // WEBIFC.IFCREINFORCINGELEMENT,
      ];

      for (const cat of excludedCats) {
        fragmentIfcLoader.settings.excludedCategories.add(cat);
      }
      fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
      fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;
      setFragmentIfcLoader(fragmentIfcLoader);

      // todo 简单注册点击事件
      // @ts-ignore
      const highlighter = new OBC.FragmentHighlighter(components, fragments);
      renderer.postproduction.customEffects.outlineEnabled = true;
      // @ts-ignore
      highlighter.outlinesEnabled = true;
      const highlightMaterial = new THREE.MeshBasicMaterial({
        color: "#BCF124",
        depthTest: false,
        opacity: 0.8,
        transparent: true,
      });
      await highlighter.add("default", [highlightMaterial]);
      highlighter.outlineMaterial.color.set(0xf0ff7a);
      setHighlighter(highlighter);

      // 请求加载模型文件
      message.info("加载模型文件中");
      try {
        const res = await ConsistencyViewerService.getConsistencyIFC(projectId);
        const ifcFile = new File([res.data], "tuzhi.ifc");
        const data = await ifcFile.arrayBuffer();
        const buffer = new Uint8Array(data);
        const model = await fragmentIfcLoader.load(buffer, "tuzhi-ifc");
        setModel(model);
        threeScene.add(model);
        const properties = model.properties;
        const newBimIdToExpressIds: BimIdToExpressId = {};
        const newBimIdToPositions: BimIdToPosition = {};
        if (properties) {
          const ifcSpaces = Object.values(properties).filter(
            (property) => property.type === 3856911033
          );
          for (const ifcSpace of ifcSpaces) {
            const entityName = IfcPropertiesUtils.getEntityName(
              properties,
              ifcSpace.expressID
            );
            // console.log(entityName.name);
            const bimId = entityName.name;
            const expressId = ifcSpace.expressID;
            const newSelection: OBC.FragmentIdMap = model.getFragmentMap([
              expressId
            ]);
            const fragmentID = Object.keys(newSelection)[0];
            const fragment = model.items.find((item) => item.id === fragmentID)?.group;
            if (bimId) {
              newBimIdToExpressIds[bimId] = expressId;
              // @ts-ignore
              newBimIdToPositions[bimId] = fragment?.boundingBox;
            }
          }
          setBimIdToExpressIds(newBimIdToExpressIds);
          setBimIdToPositions(newBimIdToPositions);
        }
        setLoaded(true);
        setHasUploaded(true);
      } catch (error) {
        // 处理错误的逻辑
        setHasUploaded(false);
        console.error("Error occurred while fetching consistency IFC:", error);
      }
      // 初始化隐藏器
      const hider = new OBC.FragmentHider(components);
      await hider.loadCached();
      setHider(hider);
      // 初始化分类器
      const classifier = new OBC.FragmentClassifier(components);
      setClassifier(classifier);

      const propsProcessor = new OBC.IfcPropertiesProcessor(components);
      const propsManager = new OBC.IfcPropertiesManager(components);
      propsProcessor.propertiesManager = propsManager;
      setPropsProcessor(propsProcessor);

      // 基点球体
      const earthGeometry = new THREE.SphereGeometry(1, 16, 16);
      const earthMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
      const earthMesh = new THREE.Mesh(earthGeometry, earthMaterial);
      earthMesh.scale.set(-1, 1, 1);
      threeScene.add(earthMesh);

      await highlighter.update();

      // 高亮工具的点击事件
      highlighter.events.default.onHighlight.add((selection) => {
        console.log("add event selection", selection);
      });
      // console.log("add event selection",selection);
      // const fragmentID = Object.keys(selection)[0];
      // // @ts-ignore
      // const expressID = [...selection[fragmentID]][0];
      // let model;
      // for (const group of fragments.groups) {
      //   const fragmentFound = Object.values(group.keyFragments).find(
      //     (id) => id === fragmentID
      //   );
      //   if (fragmentFound) model = group;
      // }
      // if (model && propsProcessor) {
      //   const p = propsProcessor.getProperties(
      //     model,
      //     Object.keys(selection)[0]
      //   );
      //   propsProcessor.renderProperties(model, expressID);
      //   if (model.properties) {
      //     const entityName = IfcPropertiesUtils.getEntityName(
      //       model.properties,
      //       expressID
      //     );
      //     // console.log(entityName);
      //     const pset = IfcPropertiesUtils.getPsetProps(
      //       model.properties,
      //       expressID
      //     );
      //     // console.log(pset);
      //     propsProcessor.process(model);
      //     propsProcessor.renderProperties(model, expressID);
      //   }
      // }
      // });

      let lastSelection;
      async function highlightOnClick(event: any) {
        const result = await highlighter.highlight("default", true, false);
        console.log("result", result);
        if (result) {
          lastSelection = {};
          for (const fragment of result.fragments) {
            // console.log(fragment.id);
            const fragmentID = fragment.id;
            // @ts-ignore
            lastSelection[fragmentID] = [result.id];
          }
          setLastSelection(lastSelection);
        }
      }
      ifcRef.current.addEventListener("click", (event) =>
        highlightOnClick(event)
      );

      // 拿到所有ifc属性
      // console.log("现在的fragments:", fragments.groups[0]);
      // console.log("现在的properties", model?.items);
      // console.log("现在的model.keyfragments:",model?.keyFragments);
      // console.log("现在的model.data:",model?.data);
      // if (!fragments.groups.length) return;
      // const group = fragments.groups[0];
      // 所有的ifc数据
      // 筛选其中ifcspace对象，并拿到名称，在对应位置增加CSS2D标签
      // @ts-ignore
      // const ifcSpace = Object.values(properties).filter(property => property.type === 3856911033);
      // console.log("ifcspace",ifcSpace);
      // if (ifcSpace.length) {
      //   const ifcSpaceName = ifcSpace[0].name;
      //   const ifcSpacePosition = ifcSpace[0].position;
      //   const labelDiv = document.createElement('div');
      //   labelDiv.className = styles.ifcSpaceLabel;
      //   labelDiv.textContent = ifcSpaceName;
      //   const label = new CSS2DObject(labelDiv);
      //   // label.position.set(ifcSpacePosition.x, ifcSpacePosition.y, ifcSpacePosition.z);
      //   threeScene.add(label);
      // }
    };

    if (ifcRef.current) {
      // 初始化viewer
      initIFCViewer().finally(() => {
        return () => {
          if (fragments) fragments.dispose();
        };
      });
    }
  }, []);

  useEffect(() => {
    if (components && width && height) {
      const threeRenderer = components.renderer.get();
      const threeCamera = components.camera.get();
      if (threeCamera instanceof THREE.PerspectiveCamera) {
        threeRenderer.setSize(width, height);
        threeCamera.aspect = width / height;
        threeCamera.updateProjectionMatrix();
      }
    }
  }, [width, height, uploadIfc]);

  // 上传新的ifc后重新加载
  useEffect(() => {
    if (uploadIfc && fragmentIfcLoader) {
      message.info("加载刚才上传的模型文件中");
      const fetchData = async () => {
        const res = await ConsistencyViewerService.getConsistencyIFC(projectId);
        const ifcFile = new File([res.data], "tuzhi.ifc");
        const data = await ifcFile.arrayBuffer();
        const buffer = new Uint8Array(data);
        const model = await fragmentIfcLoader.load(buffer, "tuzhi-ifc");
        setModel(model);
        setLoaded(true);
        components?.scene.get().add(model);
      };
      fetchData();
    }
  }, [uploadIfc]);

  useEffect(() => {
    console.log("elevation:", elevation);
    console.log(components);
    if (components && hider && classifier && model && elevation !== null) {
      // 初始化过滤器
      classifier.byStorey(model);
      classifier.byEntity(model);
      const classifications = classifier.get();
      const storeys: any = {};
      const storeyNames = Object.keys(classifications.storeys);
      for (const name of storeyNames) {
        storeys[name] = true;
      }
      const visibleStoreys = storeyNames.filter((name) =>
        equalElevation(name, elevation!)
      );
      const unvisibles = classifier.find({ storeys: storeyNames });
      hider.set(false, unvisibles);
      const visibles = classifier.find({ storeys: visibleStoreys });
      hider.set(true, visibles);
    }
  }, [elevation]);

  useEffect(() => {
    if (!highlighter) return;
    if (model && model.properties && selectedBimId !== null) {
      // fragmentMap为fragmentID对应的所有expressID列表
      const newSelection: OBC.FragmentIdMap = model.getFragmentMap([
        bimIdToExpressIds[selectedBimId],
      ]);
      // 必须重新创建一个对象，因为默认getFragmentMap里Set<string>里面存的是number
      const lightSelection: { [key: string]: string[] } = {};
      for (const key in newSelection) {
        lightSelection[key] = Array.from(newSelection[key]).map((num) =>
          num.toString()
        );
      }
      // @ts-ignore
      highlighter.highlightByID("default", lightSelection, true, false);
      const center = BimIdToPositions[selectedBimId];
      // camera?.controls.setLookAt(center.x,center.y + 30,center.z,center.x,center.y,center.z);
      camera?.controls.fitToBox(center, false);
      camera?.controls.rotateTo(0,0,true);
    }
  }, [selectedBimId]);

  return (
    <div className={styles.root} ref={resizeRef}>
      {!loaded && (
        <Loading message={hasUploaded ? "Loading" : "暂未提交IFC文件"} />
      )}
      <div
        ref={ifcRef}
        className={styles.ifcViewer}
        style={{
          width: width,
          height: height,
        }}
      />
    </div>
  );
}

export default ConsistencyIFCViewer;

// azimuthAngle: 这个属性表示相机在水平方向上的方位角，以弧度为单位。每旋转360度，该值会累加。
// 通过调整这个值，可以实现相机围绕目标点水平旋转的效果。
// polarAngle: 这个属性表示相机在垂直方向上的极角，以弧度为单位。通过改变这个值，可以实现相机在垂直方向上的俯仰或仰角变化。
