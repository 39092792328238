import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config';
import { store } from '../../app/store';
import { Asset } from '../../interfaces';

class SNLViewerService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: headers,
    });
    return this.client;
  }

  /**
   * 获取在线SNL列表
   * @param projectId
   */
  async getOnlineSNLList() {
    return this.init().get('/snl');
  }

  /**
   * 获取SNL文件内容
   * @param path 文件路径
   */
  async getOnlineSNLContent(path: string) {
    return this.init().get(`/snl/${path}`, {
      responseType: 'text'
    });
  }
}

export default new SNLViewerService();
