import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './app/store';
import AppService from './App.service';

export interface VisState {
  version: string;
}

const initialState: VisState = {
  version: '',
};

export const fetchVersion = createAsyncThunk<{version: string}>(
  'tuzhi.fetchVersion',
  async () => {
    const response = await AppService.getBackendVersion();
    return response.data;
  }
);

export const appSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(fetchVersion.fulfilled, (state, {payload}) => {
        state.version = payload.version;
      });
  }
});

// export const {
// } = appSlice.actions;

export const selectVersion = (state: RootState) => state.meta.version;

export default appSlice.reducer;
