import { CheckReport, ReportInfo } from '../interfaces';

export function reportToCsv(reportJson: CheckReport): string {
  console.log(reportJson);
  let csv = '\uFEFF规范,编号,原文,条文状态,SNL状态,审查类型,SNL类型,SNL,原因,构件ID列表\n';
  csv += Object.entries(reportJson.ItemResults).flatMap(([rule, item]) => {
    const ruleHead = [...rule.split('.spl'), item.NaturalLanguage, item.PassStatus];
    return item.CheckResults.map(c => {
      const checkHead = [c.Pass, c.ErrorType, c.TaskType, c.SNL, c.Reason, `[${c.IdSet.join(';')}]`];
      return [...ruleHead, ...checkHead].map(c => `"${(c + '').replace(/"/, '""')}"`);
    }).map(c => (c + '').replace(/(\r\n|\n|\r)/gm, ''));
  }).join('\n');
  return csv;
}