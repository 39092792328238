import React, { useState } from 'react';

import styles from './ContentList.module.less';
import { buildContentList } from '../../utils/snlUtils';
import { InView } from 'react-intersection-observer';
import SNLHighlighter from '../../components/SNLHighlighter';
import { SNLLibrary } from '../../models/snl/SNLLibrary';

interface ContentListProps {
  snlLibrary: SNLLibrary;
  content: string | null;
  activeRuleNumbers: string[];
  setRuleNumberState: (number: string, inView: boolean) => void;
}

function ContentList(props: ContentListProps) {
  const {snlLibrary, content, activeRuleNumbers, setRuleNumberState} = props;

  const [hoverText, setHoverText] = useState('');
  const [hoverPosition, setHoverPosition] = useState<number[] | null>(null);

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target instanceof HTMLSpanElement) {
      const mapping = snlLibrary.configs[(event.target.textContent || '').trim()]?.mapping;
      if (mapping) {
        setHoverText(mapping);
        setHoverPosition([event.clientX, event.clientY]);
      }
    }
  };

  const handleMouseOut = () => {
    setHoverPosition(null);
  };

  const snlListElements = React.useMemo(() => {
     if (content) {
       const snlData = buildContentList(content);
       return snlData && snlData.map((rule) => {
         return (
           <InView key={rule.ruleId} as="div" className={styles.resultTable} onChange={(inView, entry) => {
             // console.log(inView, rule.ruleNumber);
             setRuleNumberState(rule.ruleNumber, inView);
           }}>
             <div className={styles.rule}>
               <div id={`content-${rule.ruleNumber}`}></div>
               <div className={styles.head}>
                 {rule.ruleNumber} {rule.ruleGroups}
               </div>
               <div className={styles.body}>
                 <div
                   className={styles.des}
                   dangerouslySetInnerHTML={{__html: (rule.des || '').trim().replace(/\n+/g, '<br>')}}
                 />
                 {
                   rule.snls.map((snl: any, i: number) => {
                     return (
                       <div key={i}>
                         <div className={styles.splitText}>
                           <b>#{i+1}</b>{' '}
                           {snl.text}
                         </div>
                         <div
                           className={styles.snl}
                           onMouseMove={handleMouseMove}
                           onMouseOut={handleMouseOut}
                         >
                           <SNLHighlighter content={snl.snl || ''} />
                         </div>
                       </div>
                     );
                   })
                 }
               </div>
             </div>
           </InView>
         );
       })

     }
  }, [content]);

  return (
    <div className={styles.root}>
      基本信息 + 颗粒度控制 + 作者信息 + 修改时间 + 实体映射hover提示 + 联动目录 + 外部链接互转
      {snlListElements}
      {
        hoverText && <div
          style={{
            position: 'fixed',
            zIndex: 999,
            display: hoverPosition ? 'block' : 'none',
            top: hoverPosition ? hoverPosition[1] + 10 : 0,
            left: hoverPosition ? hoverPosition[0] + 10 : 0,
            overflowX: 'visible',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              background: '#fff',
              border: '1px solid #ccc',
              display: 'inline-block',
              padding: '2px 10px',
              color: '#000',
            }}
          >
            映射: {hoverText}
          </div>
        </div>
      }
    </div>
  );
}

export default ContentList;
