import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from './config';
import { store } from './app/store';

class AppService {
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: ''
    };

    this.client = axios.create({
      baseURL: `${API_BASE_URL}`,
      headers: headers,
    });
    return this.client;
  }

  /**
   * 获取图智后端版本号
   */
  async getBackendVersion(): Promise<AxiosResponse<{version: string}>> {
    return this.init().get('/meta/version');
  }

}

export default new AppService();
