import React, { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { Avatar, Button, Empty, Input, List, notification, Pagination, PaginationProps, Popconfirm, Tag } from 'antd';

import styles from './ProjectList.module.less';
import { Link } from 'react-router-dom';
import ProjectListService from './ProjectList.service';
import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../auth/authSlice';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import { Project } from '../../interfaces';

interface PaginationStatus {
  currentPage: number;
  pageSize: number;
}

interface PaginationResult {
  total: number;
  total_pages: number;
  first_page: number;
  last_page: number;
  page: number;
  next_page: number;
}

function ProjectList() {
  const user = useAppSelector(selectUser);
  // 新建项目名称
  const [newProjectName, setNewProjectName] = useState('');
  // 分页配置状态
  const [pageStatus, setPageStatus] = useState<PaginationStatus>({
    currentPage: 1,
    pageSize: 10
  });
  // 当前页面项目列表
  const [projectList, setProjectList] = useState<Project[]>([]);
  // 分页结果
  const [pagination, setPagination] = useState<PaginationResult>({
    first_page: 0,
    last_page: 0,
    next_page: 0,
    page: 0,
    total: 0,
    total_pages: 0
  });

  const fetchProjectData = useCallback(() => {
    ProjectListService
      .getProjectList(pageStatus.currentPage, pageStatus.pageSize)
      .then(res => {
        const pageParams = JSON.parse(res.headers['x-pagination'] || '{}');
        setProjectList(res.data);
        setPagination(pageParams);
      });
  }, [pageStatus]);

  useEffect(() => {
    fetchProjectData();
  }, [pageStatus]);

  const onChangePage: PaginationProps['onChange'] = page => {
    setPageStatus({
      ...pageStatus,
      currentPage: page
    })
  };

  const onNewNameChange: ChangeEventHandler<HTMLInputElement> = event => {
    setNewProjectName(event.target.value);
  };

  const onCreateProject: MouseEventHandler = () => {
    if (newProjectName === '') {
      return;
    }
    ProjectListService
      .createProject(newProjectName)
      .then(res => {
        notification['success']({
          message: '新建项目成功',
          description:
            `项目 “${name}” 已成功创建。`,
        });
        setNewProjectName('');
        fetchProjectData();
      })
      .catch(res => {
        notification['error']({
          message: '新建项目失败',
          description:
            `新建项目 “${name}” 失败，请检查网络。`,
        });
      });
  };

  const onDeleteProject = (projectId: string) => {
    ProjectListService
      .deleteProject(projectId)
      .then(res => {
        notification['success']({
          message: '删除成功',
          description:
            `项目 #${projectId} 已删除成功。`,
        });
        fetchProjectData();
      })
      .catch(res => {
        notification['error']({
          message: '删除失败',
          description:
            `项目 #${projectId} 删除失败，请检查网络。`,
        });
      });
  };

  return (
    <div className={styles.root}>
      <Helmet>
        <title>图智项目列表</title>
      </Helmet>
      <div className={styles.listWrapper}>
        <h1><img src="/tuzhi-logo.svg" alt="图智"/> “图智” TUZHI.AI 项目列表</h1>
        <Input.Group compact className={styles.projectNameInputGroup}>
          <Input
            className={styles.projectNameInput} placeholder="创建项目名称"
            value={newProjectName}
            onChange={onNewNameChange}
          />
          <Button
            type="primary"
            onClick={onCreateProject}
          >
            创建项目
          </Button>
        </Input.Group>
        {/*todo 加载中界面优化，如使用骨架屏*/}
        <List
          // itemLayout="vertical"
          size="large"
          locale={{emptyText: <Empty description="暂无项目"/>}}
          dataSource={projectList}
          renderItem={item => {
            const source = item.properties.find(p => p.key === '数据源类型')?.value;
            return (
              <List.Item
                key={item.projectId}
                actions={[
                  <Button key="item-rename">
                    <Link to={`/project/${user.username}/${item.projectId}/${item.name}`}>
                      进入项目
                    </Link>
                  </Button>,
                  <Button key="item-rename" onClick={() => alert('todo')}>重命名</Button>,
                  <Popconfirm
                    key="item-delete" placement="top" title="确认删除"
                    okText="删除" cancelText="取消"
                    onConfirm={() => onDeleteProject(item.projectId.toString())}
                  >
                    <Button>删除</Button>
                  </Popconfirm>,
                ]}
              >
                <div>
                  <Avatar size="large" className={styles.avatar}>{item.name.slice(0, 2)}</Avatar>
                </div>
                <div>
                  <div>
                    <Link to={`/project/${user.username}/${item.projectId}/${item.name}`}>
                      {item.name} #{item.projectId}
                    </Link>
                  </div>
                  <div>
                    {new Date(item.createTime).toLocaleString()}{' '}
                    {source ? <Tag color="green">全自动项目 - {source}</Tag> : null}
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
        <Pagination
          className={styles.pagination}
          defaultCurrent={1} total={pagination.total}
          current={pagination.page}
          pageSize={pageStatus.pageSize}
          onChange={onChangePage}
          showTotal={total => `共 ${total} 个项目`}
        />
      </div>
      <Footer/>
    </div>
  );
}

export default ProjectList;
