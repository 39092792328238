import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../../config';
import { store } from '../../../app/store';
import { Element, AssetSpace, ElementRelation, SpaceRelation } from '../../../interfaces';
import { Point } from 'geojson';

class UnionViewerService {
  private token: string | undefined;
  private client: AxiosInstance | undefined;
  init() {
    const curStore = store.getState();
    this.token = curStore?.auth?.token;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}/`,
      headers: headers,
    });
    return this.client;
  }

  /**
   * 获取资源文件ArrayBuffer，用于前端PDF加载等功能
   * @param assetId
   */
  async getOriginFileArrayBuffer(assetId: number) {
    return this.init().get(`/asset/${assetId}/originFile`, {
      responseType: 'arraybuffer'
    });
  }

  /**
   * 获取空间对应的资源数据，用于可视化底图
   * @param spaceId 空间ID
   */
  async getAssetsBySpaceId(spaceId: number) {
    return this.init().get<{
      assetSpace: AssetSpace,
      spaceRelation: SpaceRelation,
      offset: Point,
    }[]>('/asset/getAssetsBySpaceId', {
      params: {
        spaceId
      },
    });
  }

  /**
   * 获取对应空间的包围盒数据
   * @param spaceId 空间ID
   */
  async getGeomsBySpaceId(spaceId: number) {
    return this.init().get('/geometry', {
      params: {
        spaceId
      },
    });
  }

  /**
   * 获取对应空间的构件数据
   * @param spaceId 空间ID
   */
  async getElementsBySpaceId(spaceId: number): Promise<AxiosResponse<Element[]>> {
    return this.init().get('/element', {
      params: {
        spaceId
      },
    });
  }

  /**
   * 获取构件的全部双向关系
   * @param elementId
   */
  async getElementRelations(elementId: number): Promise<AxiosResponse<ElementRelation[]>> {
    return this.init().get(`/element/${elementId}/bidirectionalRelations`);
  }

  /**
   * 获取在线SNL列表与预设审查列表
   * @param projectId
   */
  async getSNLs() {
    return this.init().get('/snl');
  }
}

export default new UnionViewerService();
