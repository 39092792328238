import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

export interface ModifierState {
  mode: 'none' | 'new' | 'modify'; // 当前的操作模式，none表示不在修改状态，删除操作是直接删除的无需调整
  spaceId: number | null; // 当前操作的空间id
  modifyingGeomId: number | null;
  shapeData: any;
}

const initialState: ModifierState = {
  mode: 'none',
  spaceId: null,
  modifyingGeomId: null,
  shapeData: null,
};

export const modifierSlice = createSlice({
  name: 'modifier',
  initialState,
  reducers: {
    setMode: (state, action: {payload: 'none' | 'new' | 'modify'}) => {
      state.mode = action.payload;
    },
    startNewShape: (state, action: {payload: number}) => {
      state.mode = 'new';
      state.spaceId = action.payload;
    },
    setModifyingGeomId: (state, action) => {
      state.modifyingGeomId = action.payload;
    },
  },
});

export const {
  setMode,
  startNewShape,
  setModifyingGeomId,
} = modifierSlice.actions;

export const selectMode = (state: RootState) => state.modifier.mode;
export const selectModifyingGeomId = (state: RootState) => state.modifier.modifyingGeomId;

export default modifierSlice.reducer;
