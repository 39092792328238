import React from 'react';

function Img(props: any) {
  return (
    <span style={{textAlign: 'center', display: 'inline-block'}}>
      <img {...props} />
      <br/>
      <span style={{fontSize: 16}}>{props?.title}</span>
    </span>
  );
}

const mdxComponents = {
  img: Img,
}
export default mdxComponents;