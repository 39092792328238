import {Element as TuzhiElement} from '../../../../interfaces/index';

const COLOR_PALETTE = [
  0xe6194B, 0x3cb44b, 0xffe119, 0x4363d8, 0xf58231,
  0x911eb4, 0x42d4f4, 0xf032e6, 0xbfef45, 0xfabed4,
  0x469990, 0xdcbeff, 0x9A6324, 0xfffac8
];

const COLOR_PALETTE_SPECIAL = [
  0x808080, 0xffff00, 0xff0000, 0x7cfc00, 0x00ffff, 0x400080,
  0x0000ff, 0x00ff00, 0xff8080, 0xff8000
];

type RenderConfigItem = {
  fill?: number | ((element: TuzhiElement) => number);
  rotation?: number | ((element: TuzhiElement) => number | undefined);
  alpha?: number;
  layerIndex?: number;
  spriteSheet?: string;
  sprite?: string;
};

type RenderConfig = {
  categoryName: string;
  spriteSheet?: string;
  defaultSprite?: string;
  fill?: number | ((element: TuzhiElement) => number);
  rotation?: number | ((element: TuzhiElement) => number);
  alpha?: number;
  layerIndex?: number;
  families: (RenderConfigItem & {
    familyName: string;
  })[];
}[];

const _RENDER_CONFIG: RenderConfig = [
  {
    categoryName: '墙',
    fill: 0x0000ff,
    alpha: 0.3,
    layerIndex: 20,
    families: [
      {
        familyName: '墙',
        fill: 0x0000ff,
        alpha: 0.3,
      },
    ],
  },
  {
    categoryName: '门',
    spriteSheet: 'illumination',
    defaultSprite: 'unknown',
    fill: 0x00ff00,
    alpha: 0.3,
    layerIndex: 30,
    families: [],
  },
  // todo 以下是由于门的CN映射一直以来存在问题，先以错误的方式配置，择时修正
  {
    categoryName: '门-单扇门',
    fill: 0x0000ff,
    alpha: 0.3,
    layerIndex: 20,
    families: [
      {
        familyName: '门-单扇门',
        fill: 0x00ff00,
        alpha: 0.45,
      },
    ],
  },
  {
    categoryName: '门-双扇门',
    fill: 0x0000ff,
    alpha: 0.3,
    layerIndex: 20,
    families: [
      {
        familyName: '门-双扇门',
        fill: 0x00ff00,
        alpha: 0.45,
      },
    ],
  },
  // todo 结束
  {
    categoryName: '窗',
    fill: 0xff0000,
    alpha: 0.3,
    layerIndex: 20,
    families: [
      {
        familyName: '窗',
        fill: 0xff0000,
        alpha: 0.45,
      },
    ],
  },
  {
    categoryName: '房间',
    fill: 0x0000ff,
    alpha: 0.3,
    layerIndex: 9,
    families: [
      {
        familyName: '房间',
        fill: (element: TuzhiElement) => {
          for (const item of element.properties) {
            if (item['key'].endsWith('业态id')) {
              if (typeof item['value'] === 'number') {
                return COLOR_PALETTE_SPECIAL[item['value'] % COLOR_PALETTE_SPECIAL.length];
              } else {
                console.warn('房间业态id不是number类型', item['value']);
              }
            }
          }
          return COLOR_PALETTE[element.elementId % COLOR_PALETTE.length];
        },
      },
    ],
  },
  {
    categoryName: '面积',
    fill: 0x0000ff,
    alpha: 0.3,
    layerIndex: 9,
    families: [
      {
        familyName: '灭火器设置点',
        fill: 0xff0000,
        alpha: 0.1,
      },
    ],
  },
  {
    categoryName: '楼板',
    fill: 0x0000ff,
    alpha: 0.1,
    layerIndex: 9,
    families: [],
  },
  {
    categoryName: '指示灯',
    spriteSheet: 'illumination',
    defaultSprite: 'unknown',
    fill: 0x0000ff,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '方向标志灯',
        sprite: 'arrow',
        rotation: (element: TuzhiElement) => {
          for (const item of element.properties) {
            if (item['key'] === '方向') {
              return ({'上': 90, '下': 270, '左': 180, '右': 0} as {[key: string]: number})[item['value']];
            }
          }
        },
      },
      {
        familyName: '疏散出口标志灯',
        sprite: 'exit',
      },
      {
        familyName: '楼层标志灯',
        sprite: 'floor',
      },
      {
        familyName: '应急照明灯',
        sprite: 'light',
      },
      {
        familyName: '安全出口标志灯',
        sprite: 'safe',
      },
      {
        familyName: '多信息复合标志灯',
        sprite: 'composite',
      },
      {
        familyName: '出口指示-禁止入内标志灯',
        sprite: 'forbid',
      },
    ],
  },
  {
    categoryName: '灭火终端',
    spriteSheet: 'extinguisher',
    defaultSprite: 'unknown',
    fill: 0xff0000,
    alpha: 0.5,
    layerIndex: 50,
    rotation: (element: TuzhiElement) => {
      for (const item of element.properties) {
        if (item['key'] === 'TZ2.Direction' && typeof item['value'] === 'number') {
          return item['value'];
        }
      }
      return 90;
    },
    families: [
      {
        familyName: '手提式ABC类干粉灭火器',
        sprite: 'portable ABC powder',
      },
      {
        familyName: '手提式BC类干粉灭火器',
        sprite: 'portable BC powder',
      },
      {
        familyName: '手提式二氧化碳灭火器',
        sprite: 'portable carbon dioxide',
      },
      {
        familyName: '手提式含添加剂的水灭火器',
        sprite: 'portable water with additive',
      },
      {
        familyName: '手提式卤代烷灭火器',
        sprite: 'portable halon',
      },
      {
        familyName: '手提式泡沫灭火器',
        sprite: 'portable foam',
      },
      {
        familyName: '手提式其它气体灭火器',
        sprite: 'portable other gas',
      },
      {
        familyName: '手提式清水灭火器',
        sprite: 'portable water',
      },
      {
        familyName: '推车式ABC类干粉灭火器',
        sprite: 'wheeled ABC powder',
      },
      {
        familyName: '推车式BC类干粉灭火器',
        sprite: 'wheeled BC powder',
      },
      {
        familyName: '推车式二氧化碳灭火器',
        sprite: 'wheeled carbon dioxide',
      },
      {
        familyName: '推车式含添加剂的水灭火器',
        sprite: 'wheeled water with additive',
      },
      {
        familyName: '推车式卤代烷灭火器',
        sprite: 'wheeled halon',
      },
      {
        familyName: '推车式泡沫灭火器',
        sprite: 'wheeled foam',
      },
      {
        familyName: '推车式其它气体灭火器',
        sprite: 'wheeled other gas',
      },
      {
        familyName: '推车式清水灭火器',
        sprite: 'wheeled water',
      },
      // 给排水图相关族
      {
        familyName: '双口消火栓室内消火栓',
        spriteSheet: 'water',
        sprite: 'fire_hydrant_double',
      },
      {
        familyName: '单口消火栓室内消火栓',
        spriteSheet: 'water',
        sprite: 'fire_hydrant_single',
      },
      {
        familyName: '单口消火栓（系统）室内消火栓',
        spriteSheet: 'water',
        sprite: 'fire_hydrant_single_system',
      },
      {
        familyName: '双口消火栓（系统）室内消火栓',
        spriteSheet: 'water',
        sprite: 'fire_hydrant_single_system',
      },
    ],
  },
  // 给排水相关构件
  {
    categoryName: '管件',
    spriteSheet: 'water',
    defaultSprite: 'unknown',
    fill: 0x0000ff,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '倒流防止器',
        sprite: 'backflow_preventer',
      },
      {
        familyName: '止回阀',
        sprite: 'check_valve',
      },
      {
        familyName: '消防水泵',
        sprite: 'fire_pump',
      },
      {
        familyName: '闸阀',
        sprite: 'gate_valve',
      },
      {
        familyName: '压力表',
        sprite: 'pressure_instrument',
      },
      {
        familyName: '干式报警阀',
        sprite: 'dry_alarm_valve',
      },
      {
        familyName: '湿式报警阀',
        sprite: 'wet_alarm_valve',
      },
      {
        familyName: '预作用报警阀',
        sprite: 'preaction_alarm_valve',
      },
      {
        familyName: '雨淋阀',
        sprite: 'rain_shower_valve',
      },
      {
        familyName: '电信号蝶阀',
        sprite: 'electric_signal_valve',
      },
      {
        familyName: '截止阀',
        sprite: 'stop_valve',
      },
    ],
  },
  {
    categoryName: '火警设备',
    spriteSheet: 'fire-alarm',
    defaultSprite: 'unknown',
    fill: 0xff0000,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '输入输出模块',
        sprite: 'in_output_module',
      },
      {
        familyName: '输入模块',
        sprite: 'input_module',
      },
      {
        familyName: '输出模块',
        sprite: 'output_module',
      },
      {
        familyName: '总线短路隔离器',
        sprite: 'short_circuit_isolator',
      },
      {
        familyName: '火灾声光警报器',
        sprite: 'audible_visual_alarm',
      },
      {
        familyName: '防火卷帘控制器',
        sprite: 'fire_shutter_controller',
      },
      {
        familyName: '扬声器',
        sprite: 'speaker',
      },
      {
        familyName: '信号阀',
        sprite: 'signal_valve',
      },
      {
        familyName: '模块箱',
        sprite: 'module_box',
      },
      {
        familyName: '感烟火灾探测器',
        sprite: 'smoke_fire_detector',
      },
      {
        familyName: '感温火灾探测器',
        sprite: 'thermal_fire_detector',
      },
      {
        familyName: '可燃气体探测器',
        sprite: 'combustible_gas_detector',
      },
      {
        familyName: '手动火灾报警按钮',
        sprite: 'manual_fire_alarm_button',
      },
      {
        familyName: '防火阀',
        sprite: 'fire_damper',
      },
      {
        familyName: '消防专用电话分机',
        sprite: 'outside_telephone',
      },
      {
        familyName: '消火栓按钮',
        sprite: 'fire_hydrant_button',
      },
    ],
  },
  {
    categoryName: '风管',
    spriteSheet: 'smoke',
    defaultSprite: 'unknown',
    fill: 0xff0000,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '风管',
        sprite: 'air_conduit',
      },
    ],
  },
  {
    categoryName: '风管管件',
    spriteSheet: 'smoke',
    defaultSprite: 'unknown',
    fill: 0xff0000,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '防火阀',
        sprite: 'fire_resisting_damper',
      },
      {
        familyName: '电动风阀',
        sprite: 'motorized_air_damper',
      },
      {
        familyName: '风阀',
        sprite: 'air_damper',
      },
    ],
  },
  {
    categoryName: '风道末端',
    spriteSheet: 'smoke',
    defaultSprite: 'unknown',
    fill: 0xff0000,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '单层百叶风口',
        sprite: 'single_layer_louver_tuyere',
      },
      {
        familyName: '双层百叶风口',
        sprite: 'double_layer_louver_tuyere',
      },
      {
        familyName: '轴流风机',
        sprite: 'axial_flow_fan',
      },
      {
        familyName: '离心风机',
        sprite: 'centrifugal_fan',
      },
      {
        familyName: '排烟口',
        sprite: 'smoke_outlet',
      },
    ],
  },
  {
    categoryName: '洞',
    spriteSheet: 'smoke',
    defaultSprite: 'unknown',
    fill: 0xff0000,
    alpha: 0.5,
    layerIndex: 50,
    families: [
      {
        familyName: '洞口',
        sprite: 'wall_hole',
      },
    ],
  },
];

const RENDER_CONFIG = (() => {
  const map: {[key: string]: RenderConfigItem} = {};
  for (const category of _RENDER_CONFIG) {
    // 为每一族增加配置
    for (const family of category.families) {
      map[`/${category.categoryName}/${family.familyName}`] = {
        fill: family.fill || category.fill,
        rotation: family.rotation || category.rotation,
        alpha: family.alpha || category.alpha,
        layerIndex: family.layerIndex || category.layerIndex,
        spriteSheet: family.spriteSheet || category.spriteSheet,
        sprite: family.sprite || category.defaultSprite,
      };
    }
    // 为当前分类下任意其他族增加默认配置
    map[`/${category}`] = {
      fill: category.fill,
      rotation: category.rotation,
      alpha: category.alpha,
      layerIndex: category.layerIndex,
      spriteSheet: category.spriteSheet,
      sprite: category.defaultSprite,
    };
  }
  return map;
})();


export function getRenderProperties(element: TuzhiElement) {
  if (!element) {
    console.warn('element空');
  }
  const path = `/${element.categoryName}/${element.familyName}`;
  const defaultPath = `/${element.categoryName}`;
  return RENDER_CONFIG[path] ? RENDER_CONFIG[path] : RENDER_CONFIG[defaultPath] ? RENDER_CONFIG[defaultPath] : {
    fill: 0xffff00,
    rotation: 90, // 默认向上，是90度
    alpha: 0.3,
    layerIndex: 35,
  };
}