import React from 'react';

import styles from './Abstract.module.less';
import { SNLLibrary } from '../../models/snl/SNLLibrary';
import { Table } from 'antd';

interface AbstractProps {
  snlLibrary: SNLLibrary | null;
}

function Abstract(props: AbstractProps) {
  const {snlLibrary} = props;

  if (!snlLibrary)
    return null;

  return (
    <div className={styles.root}>
      <div>
        <h1>{snlLibrary.name}</h1>
        <Table
          columns={[
            {
              title: '统计指标',
              dataIndex: 'key',
              width: 200,
            },
            {
              title: '统计值',
              dataIndex: 'value',
            },
          ]}
          dataSource={[
            ...[
              {
                key: '条文总数',
                value: Object.values(snlLibrary.rules).length,
              },
              {
                key: 'SNL总条数',
                value: Object.values(snlLibrary.snls).length,
              },
            ],
            ...Object.values(snlLibrary.tags).map((tag) => {
              return {
                key: tag.name,
                value: Object.values(snlLibrary.rules).filter(r => r.tags.includes(tag)).length,
              };
            })
          ]}
          pagination={{
            hideOnSinglePage: true,
          }}
          size="small" />

        <Table
          columns={[
            {
              title: '映射名称',
              dataIndex: 'name',
              width: 200,
            },
            {
              title: '映射值',
              dataIndex: 'mapping',
            },
          ]}
          dataSource={Object.values(snlLibrary.configs)}
          pagination={{
            hideOnSinglePage: true,
          }}
          size="small" />
      </div>
    </div>
  )
}

export default Abstract;
