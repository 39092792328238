import React from 'react';

import styles from './Loading.module.less';

interface LoadingProps {
  message?: string
}

function Loading(props: LoadingProps) {
  const {message} = props;
  return (
    <div className={styles.loading}>
      <div className={styles.text}>{message || 'Loading'}</div>
      <div className={styles.bg}></div>
    </div>

  )
}

export default Loading;
