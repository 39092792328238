import axios from 'axios';
import { API_BASE_URL } from '../../config';
import { store } from '../../app/store';

class ProjectListService {
  init() {
    let curStore = store.getState();
    this.token = curStore?.auth?.token;

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: `${API_BASE_URL}/project`,
      headers: headers,
    });
    return this.client;
  }

  async getProjectList(page, pageSize) {
    return this.init().get('/', {
      params: {
        page,
        page_size: pageSize
      },
    });
  }

  async createProject(name) {
    return this.init().post('/', {
      name
    });
  }

  async deleteProject(projectId) {
    return this.init().delete(`/${projectId}`);
  }
}

export default new ProjectListService();
